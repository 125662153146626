import * as forge from "node-forge";
import React, { memo, useEffect, useMemo } from "react";
import { EnclaveMfaTokenDialog } from "components";
import { clearDecentriqStorage, logInfo } from "utils";
import { useEnclaveTokenStorage } from "wrappers";
import {
  type EnclaveToken,
  type EnclaveTokenContextValue,
  EnclaveTokenProvider,
} from "./EnclaveTokenContext";

function base64UrlDecode(b64Url: string): Uint8Array {
  const unUrl = b64Url.replaceAll(/-/g, "+").replaceAll(/_/g, "/");
  const b64 = unUrl + "=".repeat((4 - (unUrl.length % 4)) % 4);
  return forge.util.binary.base64.decode(b64);
}

const EnclaveEmailMfaWrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { state: enclaveToken, setState: setEnclaveToken } =
    useEnclaveTokenStorage();
  useEffect(() => {
    if (!enclaveToken) {
      return;
    }
    const token: EnclaveToken = JSON.parse(enclaveToken);
    const claimsBase64 = token.token.split(".")[1];
    const claimsBytes = base64UrlDecode(claimsBase64);
    const claimsString = new TextDecoder().decode(claimsBytes);
    const claims = JSON.parse(claimsString);
    if (claims.exp && Number.isInteger(claims.exp)) {
      const expiration = new Date(claims.exp * 1000);
      const now = new Date();
      // The grace period before the expiration where we already trigger a new login, even though the token should still be valid.
      // X + 7 + 7 guarantees a minimum of X days where the token is still valid, but a new login will be triggered on page refresh.
      // See driver enclave authentication code for more details.
      const gracePeriodMs = (2 + 7 + 7) * 24 * 60 * 60 * 1000;
      if (now.getTime() + gracePeriodMs > expiration.getTime()) {
        logInfo("Enclave token expiring, triggering re-login");
        clearDecentriqStorage();
        setEnclaveToken(null);
      }
    } else {
      throw new Error("Enclave token has malformed/missing `exp` claim");
    }
  }, [enclaveToken, setEnclaveToken]);
  const enclaveTokenContextValue = useMemo<EnclaveTokenContextValue>(
    () => ({
      enclaveToken: enclaveToken
        ? (JSON.parse(enclaveToken) as EnclaveToken)
        : { email: "example@example.com", token: "__default__" },
    }),
    [enclaveToken]
  );
  return enclaveToken ? (
    <EnclaveTokenProvider value={enclaveTokenContextValue}>
      {children}
    </EnclaveTokenProvider>
  ) : (
    <EnclaveMfaTokenDialog open={true} setEnclaveToken={setEnclaveToken} />
  );
});
EnclaveEmailMfaWrapper.displayName = "EnclaveEmailMfaWrapper";

export default EnclaveEmailMfaWrapper;
