import {
  type ApolloClient,
  type InMemoryCache,
  type NormalizedCacheObject,
} from "@apollo/client";
import { data_science, type Session } from "@decentriq/core";
import {
  CompletePublishedDataRoomDocument,
  type CompletePublishedDataRoomQuery,
  type PublishedDataRoom,
  PublishedDataRoomEnclaveConnectionParametersDocument,
  type PublishedDataRoomEnclaveConnectionParametersQuery,
  PublishedDataRoomPasswordRequirementsFragment,
  PublishedDataRoomSource,
  type PublishedRawLeafNode,
} from "@decentriq/graphql/dist/types";
import { Mutex } from "async-mutex";
import { type ApiCoreContextValue } from "contexts";
import {
  type DataNodeTypeNames,
  PublishedComputeNodeTypeNames,
  PublishedDataNodeTypeNames,
} from "models";
import { type PublishedTableLeafNode } from "types/__generated";
import {
  retrieveTestPublishedDatasets,
  translateDataScienceDataRoom,
} from "utils/apicore";
import { decodeDataRoomConfiguration } from "utils/dataRoom";
import { type LocalResolverContext } from "../../models";

// Get necessary connection parameters to get the data science data room
// from the enclave, either from parent or by querying the API
function getEnclaveConnectionParams(
  parent: Partial<PublishedDataRoom>,
  apolloCache: InMemoryCache
) {
  if (parent.driverAttestationHash !== undefined) {
    return { driverAttestationHash: parent.driverAttestationHash };
  }
  const connectionParams =
    apolloCache.readQuery<PublishedDataRoomEnclaveConnectionParametersQuery>({
      query: PublishedDataRoomEnclaveConnectionParametersDocument,
      variables: {
        id: parent.id!,
      },
    });
  const driverAttestationHash =
    connectionParams?.publishedDataRoom.driverAttestationHash;
  if (driverAttestationHash === undefined) {
    throw new Error(
      `PublishedDataRoomEnclaveConnectionParametersDocument did not return a driverAttestationHash`
    );
  }
  return { driverAttestationHash };
}

export async function fetchDataScienceDataRoom(
  parent: Partial<PublishedDataRoom>,
  apolloCache: InMemoryCache,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  sessionManager: ApiCoreContextValue["sessionManager"],
  client: ApiCoreContextValue["client"],
  mutexMap: Map<string, Mutex>,
  ignoreCache: boolean = false
): Promise<CompletePublishedDataRoomQuery["publishedDataRoom"]> {
  const withLock = true;
  const { id: dataRoomId } = parent;
  if (dataRoomId === undefined) {
    throw new Error(`DCR id is missing`);
  }
  const run = async () => {
    const { driverAttestationHash } = getEnclaveConnectionParams(
      parent,
      apolloCache
    );
    // Check if we already have the data science data room in the cache
    const cached = apolloCache.readQuery({
      query: CompletePublishedDataRoomDocument,
      returnPartialData: ignoreCache,
      variables: { id: dataRoomId },
    });
    if (cached && !ignoreCache) {
      return cached.publishedDataRoom;
    }
    if (cached && ignoreCache) {
      const passwordRequirementsData =
        apolloCache.readFragment<PublishedDataRoomPasswordRequirementsFragment>(
          {
            fragment: PublishedDataRoomPasswordRequirementsFragment,
            id: apolloCache.identify({
              __typename: "PublishedDataRoom",
              id: dataRoomId,
            }),
          }
        );
      const { requirePassword, password } = passwordRequirementsData || {};
      // Purge given data room
      apolloCache.evict({
        id: apolloCache.identify({
          __typename: "PublishedDataRoom",
          id: dataRoomId,
        }),
      });
      apolloCache.gc();
      // Retain password information
      apolloCache.writeFragment({
        data: {
          __typename: "PublishedDataRoom",
          id: dataRoomId,
          password,
          requirePassword: requirePassword || false,
        },
        fragment: PublishedDataRoomPasswordRequirementsFragment,
        id: apolloCache.identify({
          __typename: "PublishedDataRoom",
          id: dataRoomId,
        }),
      });
    }
    // Get the data science data room from the enclave
    const sdkSession: Session = await sessionManager.get({
      driverAttestationHash,
    });
    const currentUserEmail = sdkSession.metaData.email;
    if (!dataRoomId) {
      throw new Error("dataRoomId is missing");
    }
    const dataRoomResponse = await sdkSession.retrieveDataRoom(dataRoomId);
    const dataScienceDcr =
      await sdkSession.constructVerifiedDataScienceDataRoom(dataRoomResponse);
    let publishedDcr:
      | CompletePublishedDataRoomQuery["publishedDataRoom"]
      | undefined;
    const hasHighLevelRepresentation = dataScienceDcr !== undefined;
    if (hasHighLevelRepresentation) {
      const wrapper = data_science.createDataScienceDataRoomWrapper(
        dataRoomId,
        dataScienceDcr,
        sdkSession
      );
      const publishedDatasets = await wrapper.retrievePublishedDatasets();
      const publishedDatasetByNode = new Map(
        publishedDatasets
          .filter((dataset) => dataset.leafId)
          .map((dataset) => [dataset.leafId!, dataset])
      );
      // Translate the data science data room to the PublishedDataRoom graphql shape
      const translatedDcr = translateDataScienceDataRoom(
        sdkSession.compiler,
        dataScienceDcr,
        driverAttestationHash,
        dataRoomId,
        false, // todo fix
        publishedDatasetByNode
      );
      const testDatasets = await retrieveTestPublishedDatasets({
        client: apolloClient,
        dataRoomId,
        nodes: translatedDcr.publishedNodes
          .filter(
            ({ __typename }) =>
              __typename === PublishedDataNodeTypeNames.PublishedRawLeafNode ||
              __typename === PublishedDataNodeTypeNames.PublishedTableLeafNode
          )
          .map(({ id, __typename }) => ({
            __typename: __typename as DataNodeTypeNames,
            id,
          })),
      });
      if (testDatasets.size) {
        translatedDcr.publishedNodes = translatedDcr.publishedNodes.map(
          ({ id, __typename, ...rest }) => {
            if (
              __typename === PublishedDataNodeTypeNames.PublishedRawLeafNode ||
              __typename === PublishedDataNodeTypeNames.PublishedTableLeafNode
            ) {
              const node = {
                __typename,
                id,
                ...rest,
                testDataset: testDatasets.get(id),
              } as PublishedTableLeafNode | PublishedRawLeafNode;
              return node;
            }
            return {
              __typename,
              id,
              ...rest,
            };
          }
        );
      }
      publishedDcr = {
        ...translatedDcr,
        isOwner: translatedDcr.owner.email === currentUserEmail,
        source: PublishedDataRoomSource.Web,
      };
    } else {
      const dataRoom = dataRoomResponse.dataRoom!;
      publishedDcr = {
        description: dataRoom.description || "",
        driverAttestationHash,
        enableAirlock: false,
        enableAutomergeFeature: false,
        enableDevelopment: true,
        enableInteractivity: false,
        enablePostWorker: false,
        enableServersideWasmValidation: false,
        enableSqliteWorker: false,
        enableTestDatasets: false,
        id: dataRoomId,
        isStopped: parent.isStopped!,
        name: dataRoom.name || "",
        owner: parent.owner!,
        participants: [],
        publishedNodes: [],
        source: PublishedDataRoomSource.Sdk,
      };
    }

    const hasPreviewNodes =
      publishedDcr?.publishedNodes.some(
        ({ __typename }) =>
          __typename === PublishedComputeNodeTypeNames.PublishedPreviewNode
      ) ?? false;

    const airlockQuotas: Map<
      string,
      {
        limit: number;
        used: number;
      }
    > = hasPreviewNodes
      ? await sdkSession.retrieveUsedAirlockQuotas(dataRoomId)
      : new Map();

    const publishedDataRoom = {
      ...publishedDcr,
      __typename: "PublishedDataRoom",
      deactivatedAt: parent.deactivatedAt ?? null,
      id: dataRoomId,
      publishedNodes: publishedDcr!.publishedNodes.map((node) => ({
        ...node,
        ...(node.__typename ===
          PublishedComputeNodeTypeNames.PublishedPreviewNode &&
        airlockQuotas.has(node.id)
          ? {
              remainingQuotaBytes:
                (airlockQuotas.get(node.id)!.limit ?? 0) -
                (airlockQuotas.get(node.id)!.used ?? 0),
              totalQuotaBytes: airlockQuotas.get(node.id)!.limit,
              usedQuotaBytes: airlockQuotas.get(node.id)!.used,
            }
          : {}),
        dataRoomId,
      })),
    };
    // Write the data science data room to the cache
    apolloCache.writeQuery({
      data: { publishedDataRoom },
      query: CompletePublishedDataRoomDocument,
      variables: { id: dataRoomId },
    });
    return publishedDataRoom;
  };
  if (withLock) {
    let mutex = mutexMap.get(dataRoomId);
    if (!mutex) {
      mutex = new Mutex();
      mutexMap.set(dataRoomId, mutex);
    }
    return await mutex.runExclusive(run);
  } else {
    return await run();
  }
}

export const makePublishedDataRoomResolvers = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"],
  mutexMap: Map<string, Mutex>
) => ({
  description: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.description;
  },
  enableAirlock: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableAirlock || false;
  },
  enableAutomergeFeature: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableAutomergeFeature || false;
  },
  enableDevelopment: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableDevelopment;
  },
  enableInteractivity: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableInteractivity;
  },
  enablePostWorker: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enablePostWorker || false;
  },
  enableServersideWasmValidation: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableServersideWasmValidation || false;
  },
  enableSqliteWorker: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableSqliteWorker || false;
  },
  enableTestDatasets: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.enableTestDatasets || false;
  },
  enclaveConfigurationPin: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomId } = parent;
    if (dataRoomId === undefined) {
      throw new Error(`DCR id is missing`);
    }
    const { driverAttestationHash } = getEnclaveConnectionParams(
      parent,
      context.cache
    );
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const response =
      await sdkSession.retrieveCurrentDataRoomConfiguration(dataRoomId);
    return response.historyPin;
  },
  isOwner: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.isOwner;
  },
  lowLevelRepresentation: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomId } = parent;
    if (dataRoomId) {
      const { driverAttestationHash } = getEnclaveConnectionParams(
        parent,
        context.cache
      );
      const sdkSession = await sessionManager.get({
        driverAttestationHash,
      });
      const { dataRoomConfiguration } =
        await sdkSession.retrieveCurrentDataRoomConfiguration(dataRoomId);
      const lowLevelRepresentation = await decodeDataRoomConfiguration(
        dataRoomConfiguration,
        client
      );
      return lowLevelRepresentation;
    } else {
      return null;
    }
  },
  participants: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.participants;
  },
  publishedNodes: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.publishedNodes;
  },
  source: async (
    parent: Partial<PublishedDataRoom>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const publishedDataRoom = await fetchDataScienceDataRoom(
      parent,
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap
    );
    return publishedDataRoom.source;
  },
});
