import {
  type AvailableDataPartnerFragment,
  type AvailablePublisherFragment,
  MatchingColumnFormat,
  type PublisherMarketResolver,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { RawMatchingID } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import {
  faBullhorn,
  faEye,
  faGear,
  faNewspaper,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBinoculars,
  faDiagramVenn,
  faSliders,
  faUsersRays,
} from "@fortawesome/pro-light-svg-icons";
import intersection from "lodash/intersection";
import { type ReactElement } from "react";
import { z } from "zod";
import HandHoldingDocIcon from "../assets/hand-holding-doc.svg";
import PeopleIcon from "../assets/people.svg";
import RemarketingIcon from "../assets/remarketing.svg";

export enum MediaDataRoomOrganizationRole {
  ADVERTISER = "ADVERTISER",
  PUBLISHER = "PUBLISHER",
  DATA_PARTNER = "DATA_PARTNER",
  OBSERVER = "OBSERVER",
  AGENCY = "AGENCY",
  CUSTOM = "CUSTOM",
}

export const mediaDataRoomOrganizationRolePresentationMap: Record<
  MediaDataRoomOrganizationRole,
  string
> = {
  [MediaDataRoomOrganizationRole.ADVERTISER]: "Advertiser",
  [MediaDataRoomOrganizationRole.PUBLISHER]: "Publisher",
  [MediaDataRoomOrganizationRole.DATA_PARTNER]: "Data partner",
  [MediaDataRoomOrganizationRole.OBSERVER]: "Observer",
  [MediaDataRoomOrganizationRole.AGENCY]: "Agency",
  [MediaDataRoomOrganizationRole.CUSTOM]: "Custom",
};

export interface MediaDataRoomOrganizationRoleCardPresentationType {
  icon: IconDefinition | ReactElement;
  title: string;
  description: string;
}

//TODO: Move to root level models
export const mediaDataRoomOrganizationRoleCardPresentation: Record<
  MediaDataRoomOrganizationRole,
  MediaDataRoomOrganizationRoleCardPresentationType
> = {
  [MediaDataRoomOrganizationRole.ADVERTISER]: {
    description:
      "By default, advertisers provide the seed audience, can see insights and create audiences.",
    icon: faBullhorn,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.ADVERTISER
      ],
  },
  [MediaDataRoomOrganizationRole.PUBLISHER]: {
    description:
      "Publishers can be selected from Decentriq's publisher portal. By default, publishers provide the base audience, can see insights and export audiences made available to them.",
    icon: faNewspaper,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.PUBLISHER
      ],
  },
  [MediaDataRoomOrganizationRole.DATA_PARTNER]: {
    description:
      "Data partner can be selected from Decentriq's data partner portal. By default, data partners provide the seed audience.",
    icon: <HandHoldingDocIcon />,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.DATA_PARTNER
      ],
  },
  [MediaDataRoomOrganizationRole.AGENCY]: {
    description:
      "By default, agency users can see insights and create audiences.",
    icon: <PeopleIcon />,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.AGENCY
      ],
  },
  [MediaDataRoomOrganizationRole.OBSERVER]: {
    description:
      "Observer users can't take any actions. By default, they can see insights.",
    icon: faEye,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.OBSERVER
      ],
  },
  [MediaDataRoomOrganizationRole.CUSTOM]: {
    description:
      "Custom users are used for any participant that does not fit in any of the previous roles.",
    icon: faGear,
    title:
      mediaDataRoomOrganizationRolePresentationMap[
        MediaDataRoomOrganizationRole.CUSTOM
      ],
  },
};

export enum MediaDataRoomCollaborationType {
  OVERLAP_ANALYSIS = "OVERLAP_ANALYSIS",
  INSIGHTS = "INSIGHTS",
  REMARKETING_AUDIENCES = "REMARKETING_AUDIENCES",
  RULE_BASED_AUDIENCES = "RULE_BASED_AUDIENCES",
  AI_LOOKALIKE_AUDIENCES = "AI_LOOKALIKE_AUDIENCES",
}

export enum MediaDataRoomExternalCollaborationType {
  MEASUREMENTS = "MEASUREMENTS",
}

export enum MediaDataRoomOrganizationPermission {
  PROVIDE_SEED_AUDIENCE = "PROVIDE_SEED_AUDIENCE",
  PROVIDE_BASE_AUDIENCE = "PROVIDE_BASE_AUDIENCE",
  VIEW_OVERLAP = "VIEW_OVERLAP",
  VIEW_INSIGHTS = "VIEW_INSIGHTS",
  CAN_CREATE_AUDIENCES = "CAN_CREATE_AUDIENCES",
  CAN_EXPORT_AUDIENCES = "CAN_EXPORT_AUDIENCES",
}

export const mediaDataRoomOrganizationPermissionsPresentation: Record<
  MediaDataRoomOrganizationPermission,
  {
    title: string;
    description: string;
  }
> = {
  [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]: {
    description:
      "If enabled, this participant will have to provide the seed audience. This permission is exclusive to one participant at a time.",
    title: "Provide seed audience",
  },
  [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]: {
    description:
      "If enabled, this participant will have to provide the base audience. This permission is exclusive to one participant at a time.",
    title: "Provide base audience",
  },
  [MediaDataRoomOrganizationPermission.VIEW_OVERLAP]: {
    description:
      "If enabled, this participant will have access to the overlap statistics.",
    title: "View overlap",
  },
  [MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]: {
    description:
      "If enabled, this participant will have access to the insights dashboards.",
    title: "View insights",
  },
  [MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES]: {
    description: `If enabled, this participant can create audience and share them with other participants. To export audiences out of the DCR, the "Export audiences" permission is needed in addition.`,
    title: "Create audiences",
  },
  [MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES]: {
    description:
      "If enabled, this participant can export audiences from the DCR. This includes audiences they created, audiences that were shared with them and the pre-computed remarketing audiences.",
    title: "Export audiences",
  },
};

export const MediaDataRoomParticipantSchema = z
  .object({
    emails: z.array(z.string().email()),
    id: z.string().uuid(),
    logo: z.string().optional(),
    name: z.string().min(1),
    permissions: z.array(z.nativeEnum(MediaDataRoomOrganizationPermission)),
    role: z.nativeEnum(MediaDataRoomOrganizationRole),
  })
  .passthrough();

export type MediaDataRoomParticipant = z.infer<
  typeof MediaDataRoomParticipantSchema
> & {
  isCurrentUser?: boolean;
} & (
    | {
        dataPartnerConfig?: AvailableDataPartnerFragment;
        publisherConfig?: never;
      }
    | {
        dataPartnerConfig?: never;
        publisherConfig?: AvailablePublisherFragment;
      }
  );

export const activationCollaborationTypes: MediaDataRoomCollaborationType[] = [
  MediaDataRoomCollaborationType.REMARKETING_AUDIENCES,
  MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES,
  MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES,
];

export interface MediaDataRoomCreationCollaborationTypeItem {
  collaborationType: MediaDataRoomCollaborationType;
  testId: string;
  icon: IconDefinition | ReactElement;
  title: string;
  disabled?: boolean;
  tooltip?: string;
  description: string;
  group: "Insights" | "Activation";
}

export const mediaDataRoomCollaborationTypesCardPresentation: Record<
  MediaDataRoomCollaborationType,
  MediaDataRoomCreationCollaborationTypeItem
> = {
  [MediaDataRoomCollaborationType.OVERLAP_ANALYSIS]: {
    collaborationType: MediaDataRoomCollaborationType.OVERLAP_ANALYSIS,
    description:
      "Calculate the overlap between audiences to understand the potential of a collaboration.",
    disabled: true,
    group: "Insights",
    icon: faDiagramVenn,
    testId: testIds.dataRoomCreation.collaborationTypeSelect.overlapAnalysis,
    title: "Overlap analysis",
    tooltip: "Overlap analysis is always enabled and cannot be disabled.",
  },
  [MediaDataRoomCollaborationType.INSIGHTS]: {
    collaborationType: MediaDataRoomCollaborationType.INSIGHTS,
    description:
      "Gain actionable insights about your customers to improve targeting and messaging.",
    group: "Insights",
    icon: faUsersRays,
    testId: testIds.dataRoomCreation.collaborationTypeSelect.insights,
    title: "Insights",
  },
  [MediaDataRoomCollaborationType.REMARKETING_AUDIENCES]: {
    collaborationType: MediaDataRoomCollaborationType.REMARKETING_AUDIENCES,
    description:
      "Re-engage users who have already interacted with your brand to drive conversions or increase loyalty.",
    group: "Activation",
    icon: <RemarketingIcon />,
    testId: testIds.dataRoomCreation.collaborationTypeSelect.remarketing,
    title: "Remarketing audiences",
  },
  [MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES]: {
    collaborationType: MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES,
    description:
      "Create target audiences by combining existing audiences and applying filtering rules.",
    group: "Activation",
    icon: faSliders,
    testId: testIds.dataRoomCreation.collaborationTypeSelect.ruleBased,
    title: "Rule-based audiences",
  },
  [MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES]: {
    collaborationType: MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES,
    description:
      "Leverage machine learning to find the target audience which is most similar to your customers.",
    group: "Activation",
    icon: faBinoculars,
    testId: testIds.dataRoomCreation.collaborationTypeSelect.lookalike,
    title: "AI lookalike audiences",
  },
};

export type MediaDataRoomParticipantPermissionConfig =
  | {
      prohibited: true;
      enabled: false;
      defaultValue?: never;
    }
  | {
      prohibited?: never;
      enabled: boolean;
      defaultValue: boolean;
    };

export type AvailableMarket = PublisherMarketResolver & { selected: boolean };

const mapPermissionCheckToPermissionConfig = ({
  prohibited,
  enabled,
  defaultValue,
}: {
  prohibited: boolean;
  enabled: boolean;
  defaultValue: boolean;
}): MediaDataRoomParticipantPermissionConfig => {
  if (prohibited) {
    return { enabled: false, prohibited };
  }
  return { defaultValue: enabled && defaultValue, enabled };
};

export const mediaDataRoomParticipantPermissionConfigsCreator = (
  role: MediaDataRoomOrganizationRole,
  collaborationTypes: MediaDataRoomCollaborationType[]
): Record<
  MediaDataRoomOrganizationPermission,
  MediaDataRoomParticipantPermissionConfig
> => ({
  [MediaDataRoomOrganizationPermission.VIEW_OVERLAP]:
    mapPermissionCheckToPermissionConfig({
      defaultValue: role !== MediaDataRoomOrganizationRole.CUSTOM,
      enabled: collaborationTypes.includes(
        MediaDataRoomCollaborationType.OVERLAP_ANALYSIS
      ),
      prohibited: false,
    }),
  [MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]:
    mapPermissionCheckToPermissionConfig({
      defaultValue:
        role !== MediaDataRoomOrganizationRole.CUSTOM &&
        role !== MediaDataRoomOrganizationRole.DATA_PARTNER,
      enabled: collaborationTypes.includes(
        MediaDataRoomCollaborationType.INSIGHTS
      ),
      prohibited: false,
    }),
  [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
    mapPermissionCheckToPermissionConfig({
      defaultValue:
        role === MediaDataRoomOrganizationRole.DATA_PARTNER ||
        role === MediaDataRoomOrganizationRole.ADVERTISER,
      enabled: true,
      prohibited: role === MediaDataRoomOrganizationRole.OBSERVER,
    }),
  [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
    mapPermissionCheckToPermissionConfig({
      defaultValue: role === MediaDataRoomOrganizationRole.PUBLISHER,
      enabled: true,
      prohibited: role === MediaDataRoomOrganizationRole.OBSERVER,
    }),
  [MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES]:
    mapPermissionCheckToPermissionConfig({
      defaultValue:
        role === MediaDataRoomOrganizationRole.ADVERTISER ||
        role === MediaDataRoomOrganizationRole.AGENCY,
      enabled: activationCollaborationTypes.some((type) =>
        collaborationTypes.includes(type)
      ),
      prohibited: role === MediaDataRoomOrganizationRole.OBSERVER,
    }),
  [MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES]:
    mapPermissionCheckToPermissionConfig({
      defaultValue: role === MediaDataRoomOrganizationRole.PUBLISHER,
      enabled: activationCollaborationTypes.some((type) =>
        collaborationTypes.includes(type)
      ),
      prohibited: role === MediaDataRoomOrganizationRole.OBSERVER,
    }),
});

export const MediaDataRoomCreationCollaborationTypesAndPermissionsStepSchema = z
  .object({
    collaborationTypes: z
      .object({
        [MediaDataRoomCollaborationType.OVERLAP_ANALYSIS]: z
          .boolean()
          .default(true),
        [MediaDataRoomCollaborationType.INSIGHTS]: z.boolean(),
        [MediaDataRoomCollaborationType.REMARKETING_AUDIENCES]: z.boolean(),
        [MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES]: z.boolean(),
        [MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES]: z.boolean(),
      })
      .refine(
        (collaborationTypes) => Object.values(collaborationTypes).some(Boolean),
        {
          message: "At least one collaboration type is required.",
        }
      ),
    [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]: z.string(),
    [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]: z.string(),
    participants: z.array(
      z.object({
        id: z.string(),
        matchingId: z.nativeEnum(MatchingColumnFormat).optional(),
        matchingIdHashingAlgorithm: z
          .nativeEnum(TableColumnHashingAlgorithm)
          .optional(),
        name: z.string(),
        permissions: z.object({
          [MediaDataRoomOrganizationPermission.VIEW_OVERLAP]: z.boolean(),
          [MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]: z.boolean(),
          [MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES]:
            z.boolean(),
          [MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES]:
            z.boolean(),
        }),
        role: z.nativeEnum(MediaDataRoomOrganizationRole),
        supportedCollaborationTypes: z
          .array(z.nativeEnum(MediaDataRoomCollaborationType))
          .optional(),
      })
    ),
  })
  .refine(
    ({ participants, collaborationTypes }) =>
      !collaborationTypes[MediaDataRoomCollaborationType.INSIGHTS] ||
      participants.some(
        (participant) =>
          participant.permissions[
            MediaDataRoomOrganizationPermission.VIEW_INSIGHTS
          ]
      ),
    {
      message: "At least one user must have “View insights” permission.",
      path: ["participants"],
    }
  )
  .superRefine(({ participants, collaborationTypes }, ctx) => {
    const forcedCollaborationType = participants
      .map(
        ({ supportedCollaborationTypes }) => supportedCollaborationTypes || []
      )
      .filter((types) => types.length);
    if (!forcedCollaborationType.length) {
      return;
    }
    const selectedCollaborationTypes = Object.entries(collaborationTypes)
      .filter(([_, selected]) => selected)
      .map(([collaborationType]) => collaborationType);
    const commonCollaborationTypes = intersection(...forcedCollaborationType);
    const valid =
      intersection(commonCollaborationTypes, selectedCollaborationTypes)
        .length === selectedCollaborationTypes.length;
    if (!valid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Only such collaboration types are supported by all participants: ${commonCollaborationTypes.map((type) => mediaDataRoomCollaborationTypesCardPresentation[type].title).join(", ")}.`,
        path: ["collaborationTypes"],
      });
    }
  })
  .refine(
    ({ participants, collaborationTypes }) =>
      !activationCollaborationTypes.some((type) => collaborationTypes[type]) ||
      participants.some(
        (participant) =>
          participant.permissions[
            MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES
          ]
      ),
    {
      message: "At least one user must have “Export audience” permission.",
      path: ["participants"],
    }
  )
  .refine(
    ({ participants, collaborationTypes }) =>
      !activationCollaborationTypes.some((type) => collaborationTypes[type]) ||
      participants.some(
        (participant) =>
          participant.permissions[
            MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES
          ]
      ),
    {
      message: "At least one user must have “Create audience” permission.",
      path: ["participants"],
    }
  )
  .refine(
    ({
      participants,
      [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
        provideBaseAudienceParticipantId,
    }) =>
      participants.some(({ id }) => id === provideBaseAudienceParticipantId),
    {
      message:
        "At least one organisation should have “Provide base audience” permission.",
      path: ["participants"],
    }
  )
  .refine(
    ({
      participants,
      [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
        provideSeedAudienceParticipantId,
    }) =>
      participants.some(({ id }) => id === provideSeedAudienceParticipantId),
    {
      message:
        "At least one organisation should have “Provide seed audience” permission.",
      path: ["participants"],
    }
  )
  .refine(
    ({
      participants,
      [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
        provideBaseAudienceParticipantId,
      [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
        provideSeedAudienceParticipantId,
    }) =>
      !provideBaseAudienceParticipantId ||
      !provideSeedAudienceParticipantId ||
      [...new Set(participants.map(({ id }) => id))].filter(
        (id) =>
          id === provideBaseAudienceParticipantId ||
          id === provideSeedAudienceParticipantId
      ).length === 2,
    {
      message:
        "Only one organisation should have “Provide seed audience” permission.",
      path: ["participants"],
    }
  )
  .superRefine(
    (
      {
        participants,
        [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
          provideBaseAudienceParticipantId,
        [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
          provideSeedAudienceParticipantId,
      },
      ctx
    ) => {
      const provideBaseAudienceParticipant = participants.find(
        ({ id }) => id === provideBaseAudienceParticipantId
      );
      const provideSeedAudienceParticipant = participants.find(
        ({ id }) => id === provideSeedAudienceParticipantId
      );
      if (
        !provideBaseAudienceParticipant ||
        !provideSeedAudienceParticipant ||
        !provideBaseAudienceParticipant.matchingId ||
        !provideSeedAudienceParticipant.matchingId
      ) {
        return;
      }
      const valid =
        provideBaseAudienceParticipant.matchingId ===
          provideSeedAudienceParticipant.matchingId &&
        provideBaseAudienceParticipant.matchingIdHashingAlgorithm ===
          provideSeedAudienceParticipant.matchingIdHashingAlgorithm;
      if (!valid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Matching ID should be the same for both participants with “Provide base audience” and “Provide seed audience” permissions.`,
          path: ["participants"],
        });
      }
    }
  );

export type MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues =
  z.infer<
    typeof MediaDataRoomCreationCollaborationTypesAndPermissionsStepSchema
  >;

export const getAvailableCollaborationTypes = (
  data:
    | Partial<AvailablePublisherFragment>
    | Partial<AvailableDataPartnerFragment>
): (
  | MediaDataRoomCollaborationType
  | MediaDataRoomExternalCollaborationType
)[] => {
  const {
    allowInsights,
    allowLookalike,
    allowRetargeting,
    allowRuleBasedAudiences,
  } = data;
  const allowMeasurements =
    "allowMeasurements" in data && data.allowMeasurements;
  return [
    ...(allowInsights ? [MediaDataRoomCollaborationType.INSIGHTS] : []),
    ...(allowLookalike
      ? [MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES]
      : []),
    ...(allowMeasurements
      ? [MediaDataRoomExternalCollaborationType.MEASUREMENTS]
      : []),
    ...(allowRetargeting
      ? [MediaDataRoomCollaborationType.REMARKETING_AUDIENCES]
      : []),
    ...(allowRuleBasedAudiences
      ? [MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES]
      : []),
  ];
};

export const getRawMatchingIdForFormatAndHashingAlgorithm = (
  matchingIdFormat: MatchingColumnFormat,
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | undefined | null
): RawMatchingID => {
  const hashed = Boolean(matchingIdHashingAlgorithm);
  switch (matchingIdFormat) {
    case MatchingColumnFormat.String: {
      return RawMatchingID.STRING;
    }
    case MatchingColumnFormat.Email: {
      return hashed ? RawMatchingID.HASHED_EMAIL : RawMatchingID.EMAIL;
    }
    case MatchingColumnFormat.PhoneNumberE164: {
      return hashed
        ? RawMatchingID.HASHED_PHONE_NUMBER
        : RawMatchingID.PHONE_NUMBER;
    }
    default: {
      throw new Error("Unknown matching ID format");
    }
  }
};

export const rawMatchingIdPresentation: Record<RawMatchingID, string> = {
  [RawMatchingID.EMAIL]: "Email",
  [RawMatchingID.HASHED_EMAIL]: "SHA256 Hashed Email",
  [RawMatchingID.HASHED_PHONE_NUMBER]: "SHA256 Hashed Phone (E.164)",
  [RawMatchingID.PHONE_NUMBER]: "Phone number (E.164)",
  [RawMatchingID.STRING]: "String",
};

export const MediaDataRoomCreationConfigurationFormSchema = z.object({
  matchingId: z.nativeEnum(RawMatchingID, {
    message: "Matching ID is required.",
  }),
  name: z
    .string({ message: "Media DCR name is required." })
    .trim()
    .min(1, { message: "Media DCR name is required." }),
  showAbsoluteAudienceSizes: z.boolean(),
});

export type MediaDataRoomCreationConfigurationFormValues = z.infer<
  typeof MediaDataRoomCreationConfigurationFormSchema
>;

export const defaultMediaDataRoomCreationConfigurationFormValues: Partial<MediaDataRoomCreationConfigurationFormValues> =
  {
    matchingId: undefined,
    name: "",
    showAbsoluteAudienceSizes: false,
  };
