// TODO: Fix Joy migration
import { faCheck, faClock, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/joy";
import { useCallback, useState } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import DataLabConnectDialog from "../DataLabConnectDialog";
import PublisherDataNodeDeprovisionButton from "./PublisherDataNodeDeprovisionButton";

interface PublisherDataNodeActionsProps {
  isProvisioned: boolean;
}

const PublisherDataNodeActions: React.FC<PublisherDataNodeActionsProps> = ({
  isProvisioned,
}) => {
  const {
    supportedFeatures: { canProvideBaseAudience },
    isDeactivated,
  } = useMediaDataRoom();
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const toggleOpenConnectDialog = useCallback(
    () => setOpenConnectDialog((state) => !state),
    [setOpenConnectDialog]
  );
  const canProvision =
    canProvideBaseAudience && !isProvisioned && !isDeactivated;
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      sx={{ mr: 1 }}
    >
      {canProvision ? (
        <Button
          color="primary"
          onClick={toggleOpenConnectDialog}
          startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faUpload} />}
          sx={{ maxWidth: "260px", ml: 2 }}
          variant="solid"
        >
          Provision datalab
        </Button>
      ) : (
        <Typography
          component="div"
          fontWeight="bold"
          level="body-sm"
          sx={{ color: "var(--joy-palette-neutral-500)" }}
        >
          {isProvisioned ? (
            canProvideBaseAudience ? (
              <PublisherDataNodeDeprovisionButton />
            ) : (
              <>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faCheck}
                  style={{ marginRight: "4px" }}
                />
                Data provisioned
              </>
            )
          ) : (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faClock}
                style={{ marginRight: "4px" }}
              />
              Waiting for data
            </>
          )}
        </Typography>
      )}
      <DataLabConnectDialog
        onCancel={toggleOpenConnectDialog}
        open={openConnectDialog}
      />
    </Box>
  );
};

export default PublisherDataNodeActions;
