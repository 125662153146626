import {
  DqCard,
  DqSidePanel,
  DqSidePanelActions,
  DqSidePanelContent,
  DqSidePanelTitle,
} from "@decentriq/components";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Stack, Typography } from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback, useMemo } from "react";
import {
  mediaDataRoomOrganizationRoleCardPresentation,
  type MediaDataRoomParticipant,
} from "features/dataRoomCreation/models";
import { MediaDataRoomCreationParticipantEmailsDialog } from "../../";

interface ParticipantDrawerProps {
  participant: MediaDataRoomParticipant | null;
  onClose: () => void;
  onParticipantEdit: (updatedParticipant: MediaDataRoomParticipant) => void;
  otherParticipantsEmails: string[];
}

const ParticipantDrawer = memo<ParticipantDrawerProps>(
  ({ participant, onClose, onParticipantEdit, otherParticipantsEmails }) => {
    const [
      isEditDialogOpen,
      { setTrue: openEditDialog, setFalse: closeEditDialog },
    ] = useBoolean(false);

    const participantRoleLabel = useMemo(
      () =>
        participant
          ? `${mediaDataRoomOrganizationRoleCardPresentation[participant.role].title}${participant?.isCurrentUser ? " (You)" : ""}`
          : null,
      [participant]
    );

    const handleParticipantEdit = useCallback(
      (updatedEmails: string[]) => {
        onParticipantEdit?.({
          ...participant!,
          emails: updatedEmails,
        });
        closeEditDialog();
      },
      [closeEditDialog, onParticipantEdit, participant]
    );

    return (
      <Fragment>
        <DqSidePanel onClose={onClose} open={Boolean(participant)}>
          <DqSidePanelTitle onClose={onClose} separated={true}>
            {participant?.name}
          </DqSidePanelTitle>
          <DqSidePanelContent>
            <Stack>
              <DqCard>
                <Typography
                  gutterBottom={true}
                  level="body-sm"
                  textColor="inherit"
                >
                  <strong>Role</strong>
                </Typography>
                <Chip>{participantRoleLabel}</Chip>
              </DqCard>
              <DqCard>
                <Typography
                  gutterBottom={true}
                  level="body-sm"
                  textColor="inherit"
                >
                  <strong>Emails</strong>
                </Typography>
                <Stack>
                  {participant?.emails.map((email) => (
                    <Typography
                      key={email}
                      level="body-sm"
                      startDecorator={
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faUser}
                          size="lg"
                        />
                      }
                      textColor="inherit"
                    >
                      {email}
                    </Typography>
                  ))}
                </Stack>
              </DqCard>
            </Stack>
          </DqSidePanelContent>
          <DqSidePanelActions>
            <Button color="primary" onClick={openEditDialog} variant="solid">
              Edit participant
            </Button>
          </DqSidePanelActions>
        </DqSidePanel>
        {participant && (
          <MediaDataRoomCreationParticipantEmailsDialog
            canDeleteDefaultEmails={
              !participant.dataPartnerConfig && !participant.publisherConfig
            }
            defaultEmails={participant.emails}
            onCancel={closeEditDialog}
            onSubmit={handleParticipantEdit}
            open={isEditDialogOpen}
            otherParticipantsEmails={otherParticipantsEmails}
            participantName={participant.name}
            participantRole={participant.role}
          />
        )}
      </Fragment>
    );
  }
);

ParticipantDrawer.displayName = "ParticipantDrawer";

export default ParticipantDrawer;
