import { logWarning } from "utils";

export { makeDataConnectorJobResolvers } from "./DataConnectorJob";
export { makeMutationResolvers } from "./Mutation";
export { makePublishedComputationNodeResolvers } from "./PublishedComputationNode";
export { makePublishedDataRoomResolvers } from "./PublishedDataRoom";
export { makePublishedLeafNodeResolvers } from "./PublishedLeafNode";
export { makePublishedMediaDataRoomResolvers } from "./PublishedMediaDataRoom";
export { makeQueryResolvers } from "./Query";
export { makeSubmittedDataRoomRequestResolvers } from "./SubmittedDataRoomRequest";

// Returns null if there is no undefined
function findUndefinedPath(
  value: any | undefined,
  path: string
): string | null {
  if (value === undefined) {
    return path;
  }
  switch (typeof value) {
    case "boolean": {
      return null;
    }
    case "number": {
      return null;
    }
    case "string": {
      return null;
    }
    case "object": {
      for (const field in value) {
        const pathNested = findUndefinedPath(value[field], path + "." + field);
        if (pathNested != null) {
          return pathNested;
        }
      }
      return null;
    }
  }

  throw new Error(`Unexpected type ${typeof value}`);
}

export function sanitizeResolver<A, B>(
  f: (obj: any, input: A, context: any, info?: any) => Promise<B>
): (obj: any, input: A, context: any, info?: any) => Promise<B | undefined> {
  return async (obj: any, input: A, context: any, info?: any) => {
    const path = findUndefinedPath(input, "input");
    if (path !== null) {
      logWarning(
        `Warning: input sanitization failed, ${path} is undefined. Returning undefined from resolver.`
      );
      throw new Error("This input bad");
    }
    return await f(obj, input, context, info);
  };
}
