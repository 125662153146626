import { Checkbox, FormControl, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";

const DmpFeatures = memo(() => {
  const { enableDmp, isEditorDisabled, updateOrganizationFlags } =
    useOrganizationAdminConfiguration();
  return (
    <Stack>
      <Typography level="title-md">DMP</Typography>
      <FormControl>
        <Stack>
          <Checkbox
            checked={enableDmp}
            disabled={isEditorDisabled}
            label="Enable DMP (BETA)"
            name="enableDmp"
            onChange={updateOrganizationFlags}
          />
        </Stack>
      </FormControl>
    </Stack>
  );
});
DmpFeatures.displayName = "DmpFeatures";

export default DmpFeatures;
