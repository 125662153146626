import { Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import { Datalabs, DatalabWithIdFromParams } from "features/dataLabs";

const DatalabsPage = memo(() => {
  const element = useRoutes([
    {
      children: [
        {
          element: (
            <Fragment>
              <Typography level="h4" minHeight={68} p={2}>
                Datalabs
              </Typography>
              <Datalabs />
            </Fragment>
          ),
          path: "",
        },
        {
          element: <DatalabWithIdFromParams />,
          path: ":datalabId",
        },
      ],
      element: <Outlet />,
      path: `*`,
    },
  ]);
  return element;
});
DatalabsPage.displayName = "DatalabsPage";

export default DatalabsPage;
