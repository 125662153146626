import { DqCard, DqCardContent } from "@decentriq/components";
import {
  type AvailableDataPartnerFragment,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography, useTheme } from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback, useState } from "react";
import { useMediaDataRoomCreationAvailableParticipants } from "features/dataRoomCreation/contexts";
import {
  MediaDataRoomOrganizationRole,
  type MediaDataRoomParticipant,
} from "features/dataRoomCreation/models";
import {
  MediaDataRoomCreationParticipantEmailsDialog,
  MediaDataRoomCreationParticipantRoleDialog,
  MediaDataRoomCreationParticipantSelectionDialog,
} from "../..";

interface AddParticipantCardProps {
  onStart?: () => void;
  onSubmit: (participant: MediaDataRoomParticipant) => void;
  onCancel?: () => void;
  participantsEmails: string[];
}

const AddParticipantCard = memo<AddParticipantCardProps>(
  ({ onStart, onSubmit, onCancel, participantsEmails }) => {
    const {
      availableDataPartners,
      availablePublishers,
      availableMarkets,
      onSelectedMarketsChange,
      isLoading: isAvailableParticipantsLoading,
    } = useMediaDataRoomCreationAvailableParticipants();
    const { palette } = useTheme();
    const [draftParticipant, setDraftParticipant] =
      useState<Partial<MediaDataRoomParticipant> | null>(null);
    // Update draftParticipant step on each dialog submit
    const handleStepSubmit = useCallback(
      (participantData: Partial<MediaDataRoomParticipant>) => {
        setDraftParticipant(
          (existingData) =>
            ({
              ...existingData,
              ...participantData,
            }) as Partial<MediaDataRoomParticipant>
        );
      },
      []
    );
    const handleAddParticipantCancel = useCallback(() => {
      setDraftParticipant(null);
      onCancel?.();
    }, [onCancel]);
    // Emails step
    const [
      isParticipantEmailsDialogOpen,
      {
        setFalse: closeParticipantEmailsDialog,
        setTrue: openParticipantEmailsDialog,
      },
    ] = useBoolean(false);
    const handleParticipantEmailsDialogClose = useCallback(() => {
      closeParticipantEmailsDialog();
      handleAddParticipantCancel();
    }, [closeParticipantEmailsDialog, handleAddParticipantCancel]);
    const handleParticipantEmailsStepSubmit = useCallback(
      (participantEmails: string[]) => {
        onSubmit({
          ...draftParticipant!,
          emails: participantEmails,
          id: crypto.randomUUID(),
          logo: undefined,
          name: `<${participantEmails[0]}>`,
          permissions: [],
        } as MediaDataRoomParticipant);
        setDraftParticipant(null);
        closeParticipantEmailsDialog();
      },
      [closeParticipantEmailsDialog, draftParticipant, onSubmit]
    );
    // Participant selection step (for Data Partner and Publisher roles)
    const [
      isParticipantSelectionDialogOpen,
      {
        setFalse: closeParticipantSelectionDialog,
        setTrue: openParticipantSelectionDialog,
      },
    ] = useBoolean(false);
    const handleParticipantSelectionDialogClose = useCallback(() => {
      closeParticipantSelectionDialog();
      handleAddParticipantCancel();
    }, [closeParticipantSelectionDialog, handleAddParticipantCancel]);
    const handleParticipantSelectionStepSubmit = useCallback(
      (
        participantData:
          | AvailablePublisherFragment
          | AvailableDataPartnerFragment
      ) => {
        const isPublisher = "publisherId" in participantData;
        const participantLogo = isPublisher
          ? participantData.publisherLogo
          : participantData.dataPartnerLogo;
        const participantName = isPublisher
          ? participantData.publisherName
          : participantData.dataPartnerName;
        const participantsEmails = isPublisher
          ? participantData.publisherParticipants
          : participantData.participants;
        onSubmit({
          ...draftParticipant,
          emails: participantsEmails || [],
          id: crypto.randomUUID(),
          logo: participantLogo ?? undefined,
          name: participantName,
          permissions: [],
          ...(draftParticipant?.role ===
          MediaDataRoomOrganizationRole.DATA_PARTNER
            ? {
                dataPartnerConfig:
                  participantData as AvailableDataPartnerFragment,
              }
            : {
                publisherConfig: participantData as AvailablePublisherFragment,
              }),
        } as MediaDataRoomParticipant);
        setDraftParticipant(null);
        handleParticipantSelectionDialogClose();
      },
      [draftParticipant, handleParticipantSelectionDialogClose, onSubmit]
    );
    // Role step
    const [
      isParticipantRoleDialogOpen,
      {
        setFalse: closeParticipantRoleDialog,
        setTrue: openParticipantRoleDialog,
      },
    ] = useBoolean(false);
    const handleParticipantRoleDialogOpen = useCallback(() => {
      openParticipantRoleDialog();
      onStart?.();
    }, [onStart, openParticipantRoleDialog]);
    const handleParticipantRoleDialogClose = useCallback(() => {
      closeParticipantRoleDialog();
      onCancel?.();
    }, [closeParticipantRoleDialog, onCancel]);
    const handleParticipantRoleStepSubmit = useCallback(
      (participantRole: MediaDataRoomOrganizationRole) => {
        handleStepSubmit({ role: participantRole });
        closeParticipantRoleDialog();
        if (
          [
            MediaDataRoomOrganizationRole.DATA_PARTNER,
            MediaDataRoomOrganizationRole.PUBLISHER,
          ].includes(participantRole)
        ) {
          openParticipantSelectionDialog();
        } else {
          openParticipantEmailsDialog();
        }
      },
      [
        closeParticipantRoleDialog,
        handleStepSubmit,
        openParticipantEmailsDialog,
        openParticipantSelectionDialog,
      ]
    );
    const participantSelectionDialogProps:
      | {
          role: MediaDataRoomOrganizationRole.DATA_PARTNER;
          availableParticipants: AvailableDataPartnerFragment[];
        }
      | {
          role: MediaDataRoomOrganizationRole.PUBLISHER;
          availableParticipants: AvailablePublisherFragment[];
        } =
      draftParticipant?.role === MediaDataRoomOrganizationRole.PUBLISHER
        ? {
            availableParticipants: availablePublishers,
            role: MediaDataRoomOrganizationRole.PUBLISHER,
          }
        : {
            availableParticipants: availableDataPartners,
            role: MediaDataRoomOrganizationRole.DATA_PARTNER,
          };
    return (
      <Fragment>
        <DqCard
          color="primary"
          onClick={handleParticipantRoleDialogOpen}
          variant="soft"
        >
          <DqCardContent>
            <Stack
              alignItems="center"
              justifyContent="center"
              minHeight="182px"
            >
              <FontAwesomeIcon
                color={palette.primary[400]}
                icon={faPlus}
                size="2x"
              />
              <Typography
                color="primary"
                fontWeight={500}
                level="title-md"
                textAlign="center"
              >
                Add a new participant
              </Typography>
              <Typography color="neutral" level="body-xs" textAlign="center">
                Add another participant that <br /> will be part of this Media
                DCR
              </Typography>
            </Stack>
          </DqCardContent>
        </DqCard>
        <MediaDataRoomCreationParticipantRoleDialog
          onCancel={handleParticipantRoleDialogClose}
          onSubmit={handleParticipantRoleStepSubmit}
          open={isParticipantRoleDialogOpen}
        />
        <MediaDataRoomCreationParticipantEmailsDialog
          onCancel={handleParticipantEmailsDialogClose}
          onSubmit={handleParticipantEmailsStepSubmit}
          open={isParticipantEmailsDialogOpen}
          otherParticipantsEmails={participantsEmails}
          participantName={draftParticipant?.name ?? ""}
          participantRole={
            draftParticipant?.role ?? MediaDataRoomOrganizationRole.ADVERTISER
          }
        />
        <MediaDataRoomCreationParticipantSelectionDialog
          availableMarkets={
            participantSelectionDialogProps.role ===
            MediaDataRoomOrganizationRole.PUBLISHER
              ? availableMarkets.publisher
              : availableMarkets.dataPartner
          }
          isLoading={isAvailableParticipantsLoading}
          onCancel={handleParticipantSelectionDialogClose}
          onInvite={() => {
            closeParticipantSelectionDialog();
            openParticipantEmailsDialog();
          }}
          onSelectedMarketsChange={onSelectedMarketsChange}
          onSubmit={handleParticipantSelectionStepSubmit}
          open={isParticipantSelectionDialogOpen}
          participantEmails={participantsEmails}
          {...participantSelectionDialogProps}
        />
      </Fragment>
    );
  }
);

AddParticipantCard.displayName = "AddParticipantCard";

export default AddParticipantCard;
