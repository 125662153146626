import { createContext, memo } from "react";
import { useSafeContext } from "hooks";
import {
  type MediaDataRoomAvailableParticipantsHookResult,
  useMediaDataRoomAvailableParticipants,
} from "./useMediaDataRoomAvailableParticipants";

export type MediaDataRoomCreationAvailableParticipantsContextValue =
  MediaDataRoomAvailableParticipantsHookResult;

const MediaDataRoomCreationAvailableParticipantsContext =
  createContext<MediaDataRoomCreationAvailableParticipantsContextValue | null>(
    null
  );
MediaDataRoomCreationAvailableParticipantsContext.displayName =
  "MediaDataRoomCreationAvailableParticipantsContext";

export const MediaDataRoomCreationAvailableParticipantsContextProvider =
  MediaDataRoomCreationAvailableParticipantsContext.Provider;

export const useMediaDataRoomCreationAvailableParticipants = () =>
  useSafeContext(MediaDataRoomCreationAvailableParticipantsContext);

export const MediaDataRoomCreationAvailableParticipantsWrapper =
  memo<React.PropsWithChildren>(({ children }) => {
    const contextValue = useMediaDataRoomAvailableParticipants();
    return (
      <MediaDataRoomCreationAvailableParticipantsContext.Provider
        value={contextValue}
      >
        {children}
      </MediaDataRoomCreationAvailableParticipantsContext.Provider>
    );
  });
MediaDataRoomCreationAvailableParticipantsWrapper.displayName =
  "MediaDataRoomCreationAvailableParticipantsWrapper";
