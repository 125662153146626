import { z } from "zod";
import { MediaDataRoomParticipantSchema } from "features/dataRoomCreation/models";

export const MediaDataRoomCreationParticipantsStepSchema = z.object({
  participants: z
    .array(MediaDataRoomParticipantSchema)
    .refine((participants) => participants.length >= 2, {
      message: "At least two participants should be added.",
    }),
});

export type MediaDataRoomCreationParticipantsStepFormValues = z.infer<
  typeof MediaDataRoomCreationParticipantsStepSchema
>;
