import { Box, styled } from "@mui/joy";

const CreateDataRoomStepActionsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "var(--joy-palette-background-surface)",
  bottom: 0,
  display: "flex",
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  left: 0,
  padding: theme.spacing(1, 2),
  position: "fixed",
  right: 0,
  zIndex: 5,
}));

export default CreateDataRoomStepActionsContainer;
