import { DqLoader } from "@decentriq/components";
import { Stack, styled } from "@mui/joy";
import { memo, useCallback } from "react";
import { useWizard, Wizard } from "react-use-wizard";
import {
  DataRoomCreationDataRoomTypeStep,
  DataScienceDataRoomCreateDialog,
  MeasurementDcrCreateDialog,
} from "features/dataRoomCreation/components";
import {
  DataRoomCreationWrapper,
  MediaDataRoomCreationWrapper,
  useDataRoomCreation,
} from "features/dataRoomCreation/contexts";
import {
  useCreateAdvanceAnalyticsDataRoom,
  useSubmitCreateMeasurementDcrForm,
} from "features/dataRoomCreation/hooks";
import { DataRoomType } from "models";
import DataRoomCreationWizardStep from "./DataRoomCreationWizardStep";
import MediaDataRoomCreationWizard from "./MediaDataRoomCreationWizard";

const DataRoomCreationWizardContainer = styled(Stack)(({ theme }) => ({
  height: `calc(100% - ${theme.spacing(7)})`,
  overflowY: "auto",
  position: "relative",
}));

const AnalyticsDataRoomCreationStep = memo(() => {
  const { currentUserEmail, cancelCreation } = useDataRoomCreation();
  const { isFirstStep, previousStep } = useWizard();
  const handleBack = useCallback(() => {
    if (isFirstStep) {
      cancelCreation();
      return;
    }
    previousStep();
  }, [previousStep, isFirstStep, cancelCreation]);
  const {
    createDataRoom: createAdvanceAnalyticsDataRoom,
    isCreateDataRoomLoading: isAdvanceAnalyticsDataRoomCreating,
  } = useCreateAdvanceAnalyticsDataRoom(currentUserEmail, {
    onCompleted: handleBack,
    onError: handleBack,
  });
  return (
    <DataScienceDataRoomCreateDialog
      loading={isAdvanceAnalyticsDataRoomCreating}
      onCancel={handleBack}
      onConfirm={createAdvanceAnalyticsDataRoom}
      open={true}
      restartCreation={handleBack}
    />
  );
});
AnalyticsDataRoomCreationStep.displayName = "AnalyticsDataRoomCreationStep";

const MeasurementDataRoomCreationStep = memo(() => {
  const { cancelCreation } = useDataRoomCreation();
  const { isFirstStep, previousStep } = useWizard();
  const handleBack = useCallback(() => {
    if (isFirstStep) {
      cancelCreation();
      return;
    }
    previousStep();
  }, [previousStep, isFirstStep, cancelCreation]);
  const { submit: submitCreateMeasurementDcrForm } =
    useSubmitCreateMeasurementDcrForm({
      onCompleted: handleBack,
      onError: handleBack,
    });
  return (
    <MeasurementDcrCreateDialog
      onClose={handleBack}
      onSubmit={submitCreateMeasurementDcrForm}
      open={true}
      restartCreation={handleBack}
    />
  );
});
MeasurementDataRoomCreationStep.displayName = "MeasurementDataRoomCreationStep";

export const DataRoomCreationWizard = memo(() => {
  const {
    dataRoomType,
    hasDataRoomTypeStep,
    setDataRoomType,
    cancelCreation,
    isLoading,
    canCreateAnalyticsDataRoom,
    canCreateMeasurementDataRoom,
    canCreateMediaDataRoom,
  } = useDataRoomCreation();
  if (isLoading) {
    <DataRoomCreationWizardContainer>
      <DqLoader
        sx={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      />
    </DataRoomCreationWizardContainer>;
  }
  return (
    <DataRoomCreationWizardContainer>
      <Wizard>
        {hasDataRoomTypeStep && (
          <DataRoomCreationWizardStep
            render={({ nextStep }) => (
              <Stack alignItems="center" flex={1} gap={0}>
                <DataRoomCreationDataRoomTypeStep
                  canCreateAnalyticsDataRoom={canCreateAnalyticsDataRoom}
                  canCreateMeasurementDataRoom={canCreateMeasurementDataRoom}
                  canCreateMediaDataRoom={canCreateMediaDataRoom}
                  defaultDataRoomType={dataRoomType ?? undefined}
                  onCancel={cancelCreation}
                  onSubmit={(dataRoomType) => {
                    setDataRoomType(dataRoomType);
                    void nextStep();
                  }}
                />
              </Stack>
            )}
          />
        )}
        <Stack alignItems="center" flex={1} gap={0}>
          {dataRoomType === DataRoomType.MediaInsights && (
            <MediaDataRoomCreationWizard />
          )}
          {dataRoomType === DataRoomType.DataScience && (
            <AnalyticsDataRoomCreationStep />
          )}
          {dataRoomType === DataRoomType.Measurement && (
            <MeasurementDataRoomCreationStep />
          )}
        </Stack>
      </Wizard>
    </DataRoomCreationWizardContainer>
  );
});
DataRoomCreationWizard.displayName = "DataRoomCreationWizard";

const DataRoomCreationWizardWithWrapper = memo(() => (
  <DataRoomCreationWrapper>
    <MediaDataRoomCreationWrapper>
      <DataRoomCreationWizard />
    </MediaDataRoomCreationWrapper>
  </DataRoomCreationWrapper>
));
DataRoomCreationWizardWithWrapper.displayName =
  "DataRoomCreationWizardWithWrapper";

export default DataRoomCreationWizardWithWrapper;
