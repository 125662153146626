import { DqCard, DqEmptyData, DqLoader } from "@decentriq/components";
import {
  type AvailableDataPartnerFragment,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  Fragment,
  memo,
  type MouseEventHandler,
  useCallback,
  useMemo,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type AvailableMarket,
  getAvailableCollaborationTypes,
  getRawMatchingIdForFormatAndHashingAlgorithm,
  mediaDataRoomCollaborationTypesCardPresentation,
  MediaDataRoomExternalCollaborationType,
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRolePresentationMap,
  rawMatchingIdPresentation,
} from "features/dataRoomCreation/models";
import { type RawMatchingID } from "models";
import SelectMarketFilter from "./components/SelectMarketFilter";
import SelectParticipantForm from "./components/SelectParticipantForm";
import {
  getMediaDataRoomCreationParticipantSelectionSchema,
  type MediaDataRoomCreationParticipantSelectionFormValues,
} from "./model";

export type MediaDataRoomCreationParticipantSelectionDialogProps = {
  open: boolean;
  isLoading?: boolean;
  onSubmit: (
    data: AvailablePublisherFragment | AvailableDataPartnerFragment
  ) => void;
  onCancel?: () => void;
  onInvite: () => void;
  participantEmails: string[];
  availableMarkets: AvailableMarket[];
  onSelectedMarketsChange: (selectedMarkets: string[]) => void;
} & (
  | {
      role: MediaDataRoomOrganizationRole.PUBLISHER;
      availableParticipants: AvailablePublisherFragment[];
    }
  | {
      role: MediaDataRoomOrganizationRole.DATA_PARTNER;
      availableParticipants: AvailableDataPartnerFragment[];
    }
);

const MediaDataRoomCreationParticipantSelectionDialog =
  memo<MediaDataRoomCreationParticipantSelectionDialogProps>(
    ({
      open,
      isLoading,
      onSubmit,
      participantEmails,
      onCancel,
      role,
      availableParticipants,
      onInvite,
      availableMarkets,
      onSelectedMarketsChange,
    }) => {
      const { breakpoints } = useTheme();
      const form = useForm<MediaDataRoomCreationParticipantSelectionFormValues>(
        {
          defaultValues: {
            selectedParticipantId: undefined,
          },
          mode: "onSubmit",
          resolver: zodResolver(
            getMediaDataRoomCreationParticipantSelectionSchema(
              participantEmails,
              availableParticipants,
              role
            )
          ),
        }
      );
      const handleSubmit = useCallback<
        MouseEventHandler<HTMLButtonElement>
      >(() => {
        void form.handleSubmit((data) => {
          const selectedPublisherOrDataPartner = availableParticipants?.find(
            (participant) => participant.id === data.selectedParticipantId
          );
          if (selectedPublisherOrDataPartner) {
            onSubmit(selectedPublisherOrDataPartner);
            form.reset();
          }
        })();
      }, [form, availableParticipants, onSubmit]);
      const handleCancel = useCallback(() => {
        onCancel?.();
        form.reset();
      }, [onCancel, form]);

      const selectedParticipantId = form.watch("selectedParticipantId");
      const selectedParticipant = useMemo(() => {
        return availableParticipants?.find(
          (dataPartner) => dataPartner.id === selectedParticipantId
        );
      }, [availableParticipants, selectedParticipantId]);

      const collaborationTypes = useMemo(() => {
        const collaborationTypes = getAvailableCollaborationTypes({
          allowInsights: selectedParticipant?.allowInsights ?? false,
          allowLookalike: selectedParticipant?.allowLookalike ?? false,
          allowMeasurements:
            role === MediaDataRoomOrganizationRole.PUBLISHER
              ? ((selectedParticipant as AvailablePublisherFragment)
                  ?.allowMeasurements ?? false)
              : false,
          allowRetargeting: selectedParticipant?.allowRetargeting ?? false,
          allowRuleBasedAudiences:
            selectedParticipant?.allowRuleBasedAudiences ?? false,
        });
        return collaborationTypes.map((collaborationType) => (
          <Chip key={collaborationType}>
            {collaborationType ===
            MediaDataRoomExternalCollaborationType.MEASUREMENTS
              ? "Measurements"
              : mediaDataRoomCollaborationTypesCardPresentation[
                  collaborationType
                ].title}
          </Chip>
        ));
      }, [selectedParticipant, role]);

      const matchingId = useMemo<RawMatchingID | null>(
        () =>
          selectedParticipant && selectedParticipant.matchingIdFormat
            ? getRawMatchingIdForFormatAndHashingAlgorithm(
                selectedParticipant.matchingIdFormat,
                selectedParticipant.matchingIdHashingAlgorithm
              )
            : null,
        [selectedParticipant]
      );

      return (
        <Modal open={open}>
          <ModalDialog
            maxWidth={breakpoints.values.md}
            minWidth={breakpoints.values.md}
          >
            <FormProvider {...form}>
              <DialogTitle>
                Select {mediaDataRoomOrganizationRolePresentationMap[role]}
              </DialogTitle>
              <DialogContent>
                {!isLoading && !availableParticipants.length ? (
                  <DqEmptyData
                    description="Please try again later or contact support if the problem persists."
                    title={`Failed to load ${mediaDataRoomOrganizationRolePresentationMap[role]}s`}
                  />
                ) : (
                  <Fragment>
                    {availableParticipants.length ? (
                      <SelectMarketFilter
                        availableMarkets={availableMarkets}
                        onChange={onSelectedMarketsChange}
                      />
                    ) : null}
                    <DqCard>
                      {isLoading ? (
                        <Stack
                          alignItems="center"
                          height="100%"
                          justifyContent="center"
                          minHeight="200px"
                        >
                          <DqLoader />
                        </Stack>
                      ) : (
                        <SelectParticipantForm
                          data={availableParticipants}
                          onInvite={onInvite}
                          role={role}
                        />
                      )}
                    </DqCard>
                    {selectedParticipant && (
                      <Stack sx={{ my: 2 }}>
                        <Typography fontWeight={500}>Description</Typography>
                        <Typography>
                          {role === MediaDataRoomOrganizationRole.PUBLISHER
                            ? (
                                selectedParticipant as AvailablePublisherFragment
                              ).publisherDescription
                            : role ===
                                MediaDataRoomOrganizationRole.DATA_PARTNER
                              ? ((
                                  selectedParticipant as AvailableDataPartnerFragment
                                ).dataPartnerDescription ?? "")
                              : ""}
                        </Typography>
                        <Typography
                          endDecorator={
                            <Tooltip title="Matching ID">
                              <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                            </Tooltip>
                          }
                          fontWeight={500}
                        >
                          Matching ID
                        </Typography>
                        {matchingId ? (
                          <Chip>{rawMatchingIdPresentation[matchingId]}</Chip>
                        ) : null}
                        <Typography
                          endDecorator={
                            <Tooltip title="Collaboration types">
                              <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                            </Tooltip>
                          }
                          fontWeight={500}
                        >
                          Collaboration types
                        </Typography>
                        <Stack direction="row" flexWrap="wrap">
                          {collaborationTypes}
                        </Stack>
                      </Stack>
                    )}
                  </Fragment>
                )}
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} variant="solid">
                  Continue
                </Button>
              </DialogActions>
            </FormProvider>
          </ModalDialog>
        </Modal>
      );
    }
  );
MediaDataRoomCreationParticipantSelectionDialog.displayName =
  "MediaDataRoomCreationParticipantSelectionDialog";

export default MediaDataRoomCreationParticipantSelectionDialog;
