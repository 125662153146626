import { Alert, Box, Link, Typography } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import AccessTokensConstructor from "./AccessTokensConstructor/AccessTokensConstructor";

const AccessTokens: React.FC = () => {
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography level="title-md" marginBottom={1}>
        API tokens (old)
      </Typography>
      <Alert color="warning" sx={{ mb: 2 }}>
        <Typography color="warning" level="body-sm">
          The tokens listed below were generated using the previous
          authentication system. Please be aware that this page will soon be
          deprecated.{" "}
          <Link
            color="warning"
            component={RouterLink}
            to="/tokens"
            underline="always"
          >
            Check here the new tokens
          </Link>
          .
        </Typography>
      </Alert>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
