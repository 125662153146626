import { DqCard, DqCardContent } from "@decentriq/components";
import {
  DataRoomType,
  type DataRoomTypePresentation,
  dataRoomTypePresentationMap,
} from "@decentriq/models";
import { testIds } from "@decentriq/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  ModalDialog,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { memo, useEffect, useMemo, useState } from "react";
import { useOrganizationPreferences } from "hooks";

interface DataRoomTypeSelectDialogProps {
  open: boolean;
  onConfirm: (type: DataRoomType) => void;
  onCancel: () => void;
}

const DataRoomTypeSelectDialog: React.FC<DataRoomTypeSelectDialogProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const {
    hasAnalyticsFeatures,
    hasAdvertiserFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
    canViewMeasurements,
  } = useOrganizationPreferences();
  const dataRoomTypes: (DataRoomTypePresentation & {
    dataRoomType: DataRoomType;
    enabled: boolean;
    testId: string;
  })[] = useMemo(
    () =>
      [
        {
          dataRoomType: DataRoomType.MediaInsights,
          enabled:
            hasAdvertiserFeatures ||
            hasPublisherFeatures ||
            hasDataPartnerFeatures,
          testId:
            testIds.dataroom.dataRoomTypeSelectDialog.helper +
            DataRoomType.MediaInsights,
          ...dataRoomTypePresentationMap[DataRoomType.MediaInsights],
        },
        {
          dataRoomType: DataRoomType.Measurement,
          enabled: canViewMeasurements,
          testId:
            testIds.dataroom.dataRoomTypeSelectDialog.helper +
            DataRoomType.Measurement,
          ...dataRoomTypePresentationMap[DataRoomType.Measurement],
        },
        {
          dataRoomType: DataRoomType.DataScience,
          enabled: hasAnalyticsFeatures,
          testId:
            testIds.dataroom.dataRoomTypeSelectDialog.helper +
            DataRoomType.DataScience,
          ...dataRoomTypePresentationMap[DataRoomType.DataScience],
        },
      ].filter(({ enabled }) => enabled),
    [
      canViewMeasurements,
      hasAdvertiserFeatures,
      hasAnalyticsFeatures,
      hasDataPartnerFeatures,
      hasPublisherFeatures,
    ]
  );
  const [selectedDataRoomType, setDataRoomType] = useState<DataRoomType | null>(
    null
  );
  useEffect(() => {
    if (!open) {
      setDataRoomType(null);
    }
  }, [open, setDataRoomType]);
  return (
    <Modal onClose={() => onCancel()} open={open}>
      <ModalDialog
        sx={{
          "--ModalDialog-maxWidth": "800px",
          "--ModalDialog-minWidth": "600px",
        }}
      >
        <DialogTitle>Choose the type of data clean room</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack>
            <Typography level="title-md">Clean room type</Typography>
            <Typography level="body-sm">
              Select the clean room type for your use-case
            </Typography>
            <RadioGroup
              name="dataRoomType"
              onChange={(event) =>
                setDataRoomType(event.target.value as DataRoomType)
              }
              value={selectedDataRoomType}
            >
              <Grid columnSpacing={1} container={true} xs={12}>
                {dataRoomTypes.map(
                  ({ iconThin, title, dataRoomType, testId }) => (
                    <Grid key={dataRoomType} xs={12 / dataRoomTypes.length}>
                      <DqCard
                        data-testid={testId}
                        selectable={{
                          component: "radio",
                          value: dataRoomType,
                        }}
                      >
                        <DqCardContent gap={1}>
                          <FontAwesomeIcon icon={iconThin} size="3x" />
                          <Typography level="title-md" textAlign="center">
                            {title}
                          </Typography>
                        </DqCardContent>
                      </DqCard>
                    </Grid>
                  )
                )}
              </Grid>
            </RadioGroup>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            color="primary"
            data-testid={testIds.dataroom.dataRoomTypeSelectDialog.continue}
            disabled={!selectedDataRoomType}
            onClick={() => {
              if (!selectedDataRoomType) {
                return;
              }
              onConfirm(selectedDataRoomType);
            }}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
DataRoomTypeSelectDialog.displayName = "DataRoomTypeSelectDialog";

export default memo(DataRoomTypeSelectDialog);
