import { DqCard, DqCardContent, DqIcon } from "@decentriq/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalDialog,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { type MediaDataRoomOrganizationRole } from "features/dataRoomCreation/models";
import {
  availableRoles,
  type MediaDataRoomCreationParticipantRoleFormValues,
  MediaDataRoomCreationParticipantRoleSchema,
  mediaDataRoomParticipantRoleCardPresentation,
} from "./models";

export interface MediaDataRoomCreationParticipantRoleDialogProps {
  open: boolean;
  onSubmit?: (participantRole: MediaDataRoomOrganizationRole) => void;
  onCancel?: () => void;
}

const MediaDataRoomCreationParticipantRoleDialog =
  memo<MediaDataRoomCreationParticipantRoleDialogProps>(
    ({ open, onSubmit, onCancel }) => {
      const participantRoleForm =
        useForm<MediaDataRoomCreationParticipantRoleFormValues>({
          defaultValues: {
            participantRole: undefined,
          },
          mode: "onSubmit",
          resolver: zodResolver(MediaDataRoomCreationParticipantRoleSchema),
        });

      const { reset: resetForm } = participantRoleForm;
      const { enqueueSnackbar } = useSnackbar();
      const { breakpoints } = useTheme();

      const handleDialogClose = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);

      const handleSubmit = useCallback(() => {
        void participantRoleForm.handleSubmit(
          ({ participantRole }) => {
            onSubmit?.(participantRole);
            resetForm();
          },
          (errors) => {
            if (errors.participantRole) {
              enqueueSnackbar(errors.participantRole.message);
            }
          }
        )();
      }, [participantRoleForm, onSubmit, resetForm, enqueueSnackbar]);

      return (
        <Modal open={open}>
          <ModalDialog
            maxWidth={breakpoints.values.md}
            minWidth={breakpoints.values.md}
          >
            <FormProvider {...participantRoleForm}>
              <DialogTitle>
                Select the role for the participant in this MDCR
              </DialogTitle>
              <DialogContent>
                <Stack>
                  <Controller<
                    MediaDataRoomCreationParticipantRoleFormValues,
                    "participantRole"
                  >
                    name="participantRole"
                    render={({ field }) => (
                      <Fragment>
                        <FormControl>
                          <FormLabel>Select a role</FormLabel>
                          <RadioGroup {...field} value={field.value ?? null}>
                            <Grid
                              columnSpacing={2}
                              container={true}
                              rowSpacing={2}
                            >
                              {availableRoles.map((role) => {
                                const { icon, title, testId } =
                                  mediaDataRoomParticipantRoleCardPresentation[
                                    role
                                  ];
                                return (
                                  <Grid
                                    display="flex"
                                    key={role}
                                    md={2}
                                    sm={6}
                                    xs={12}
                                  >
                                    <DqCard
                                      compact={true}
                                      data-testid={testId}
                                      selectable={{
                                        component: "radio",
                                        value: role,
                                      }}
                                    >
                                      <DqCardContent>
                                        <Stack
                                          alignItems="center"
                                          gap={1}
                                          justifyContent="center"
                                          minHeight="75px"
                                        >
                                          <DqIcon
                                            icon={icon}
                                            iconProps={{ size: "2x" }}
                                          />
                                          <Typography
                                            level="title-md"
                                            textAlign="center"
                                          >
                                            {title}
                                          </Typography>
                                        </Stack>
                                      </DqCardContent>
                                    </DqCard>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        {field.value ? (
                          <Alert sx={{ mt: 1 }}>
                            {
                              mediaDataRoomParticipantRoleCardPresentation[
                                field.value
                              ]?.description
                            }
                          </Alert>
                        ) : null}
                      </Fragment>
                    )}
                  />
                </Stack>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} variant="solid">
                  Continue
                </Button>
              </DialogActions>
            </FormProvider>
          </ModalDialog>
        </Modal>
      );
    }
  );

MediaDataRoomCreationParticipantRoleDialog.displayName =
  "MediaDataRoomCreationParticipantRoleDialog";

export default MediaDataRoomCreationParticipantRoleDialog;
