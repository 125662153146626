import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import {
  type MediaDataRoomCreationConfigurationFormValues,
  rawMatchingIdPresentation,
} from "features/dataRoomCreation/models";
import { matchingIdOptions } from "./models";

export interface MediaDataRoomCreationConfigurationFormProps {
  hasDefaultMatchingId: boolean;
}

const MediaDataRoomCreationConfigurationForm: React.FC<
  MediaDataRoomCreationConfigurationFormProps
> = ({ hasDefaultMatchingId }) => {
  return (
    <Fragment>
      <Controller<MediaDataRoomCreationConfigurationFormValues, "name">
        name="name"
        render={({ field, fieldState }) => (
          <FormControl error={!!fieldState.error}>
            <FormLabel>Add a name to this Media DCR</FormLabel>
            <Input
              {...field}
              placeholder='e.g. "My Media DCR"'
              value={field.value ?? ""}
            />
            <FormHelperText>
              {fieldState.error && fieldState.error.message}
            </FormHelperText>
          </FormControl>
        )}
      />
      <Controller<MediaDataRoomCreationConfigurationFormValues, "matchingId">
        name="matchingId"
        render={({ field, fieldState }) => (
          <FormControl error={!!fieldState.error}>
            <FormLabel>Select a Matching ID</FormLabel>
            <Select
              {...field}
              disabled={hasDefaultMatchingId}
              onChange={(event, newValue) => field.onChange(newValue)}
              placeholder='e.g. "String"'
              value={field.value ?? null}
            >
              {matchingIdOptions.map((value) => (
                <Option key={value} value={value}>
                  {rawMatchingIdPresentation[value]}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              <Stack gap={0}>
                {fieldState.error && fieldState.error.message}
                <Typography level="body-sm">
                  {hasDefaultMatchingId
                    ? "The matching ID type was selected based on the base audience provider's configuration."
                    : "Select the matching ID to link records across datasets, such as a hashed email, customer ID, or device ID. Ensure it matches your data format."}
                </Typography>
              </Stack>
            </FormHelperText>
          </FormControl>
        )}
      />
      <FormControl>
        <FormLabel>Options</FormLabel>
        <Controller<
          MediaDataRoomCreationConfigurationFormValues,
          "showAbsoluteAudienceSizes"
        >
          name="showAbsoluteAudienceSizes"
          render={({ field: { value, onChange } }) => (
            <Stack alignItems="center" direction="row" gap={1}>
              <Switch
                checked={value ?? false}
                onChange={onChange}
                variant="soft"
              />
              <Typography>Show absolute audience sizes</Typography>
              <Tooltip title="If enabled, absolute audience sizes will be shown (overlap statistics, insights statistics, audiences tab).">
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </Stack>
          )}
        />
      </FormControl>
    </Fragment>
  );
};

export default MediaDataRoomCreationConfigurationForm;
