import {
  useDataConnectorJobsQuery,
  usePollDatasetExportMutation,
  usePollDatasetImportMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
  DatasetsDocument,
} from "@decentriq/graphql/dist/types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getEffectiveErrorMessage } from "utils/Error";

/**
 * This hook's job is to poll the result of all pending dataset imports.
 * For each import we'll trigger the process of polling the enclave for the import
 * result (the manifest hash of the new dataset), so that we can update the keychain
 * with the new key->manifestHash mapping.
 * This is necessary, as the user might have closed the browser window
 * in an earlier dataset import attempt, without having waited for the import
 * to finish.
 */
export default function useDatasetImportExportWatcher() {
  const setCurrentlyRunningImportIds = useState<string[]>([])[1];
  const setCurrentlyRunningExportIds = useState<string[]>([])[1];
  const { enqueueSnackbar } = useSnackbar();
  // When a new dataset import data connector job is created,
  // this component's useEffect callback should run again
  // to trigger the import polling procedure.
  const allDataConnectorJobs = useDataConnectorJobsQuery({
    variables: {
      filter: null,
    },
  });
  const [pollDatasetImportMutation] = usePollDatasetImportMutation({
    onError: (error) => {
      enqueueSnackbar(getEffectiveErrorMessage(error), {
        persist: true,
        variant: "error",
      });
    },
    refetchQueries: [{ query: DatasetsDocument }],
  });
  const [pollDatasetExportMutation] = usePollDatasetExportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });

  useEffect(
    () =>
      setCurrentlyRunningImportIds((currentState) => {
        const pendingImports = (
          allDataConnectorJobs?.data?.dataConnectorJobs?.nodes || []
        ).filter(
          ({ status, type }: DataConnectorJob) =>
            type === DataConnectorJobType.ImportDataset &&
            status === DataImportExportStatus.Pending
        );
        if (currentState.length === 0 && pendingImports.length === 0) {
          return currentState;
        }
        const pendingImportIds = pendingImports.map((item) => item.id);
        const importsToStart = pendingImportIds.filter(
          (id) => !currentState.includes(id)
        );
        if (importsToStart.length === 0) {
          return currentState;
        }
        void Promise.all(
          importsToStart.map((id) =>
            pollDatasetImportMutation({ variables: { id } })
          )
        );
        return [...new Set([...currentState, ...pendingImportIds])];
      }),
    [
      setCurrentlyRunningImportIds,
      pollDatasetImportMutation,
      allDataConnectorJobs?.data?.dataConnectorJobs?.nodes,
    ]
  );
  useEffect(
    () =>
      setCurrentlyRunningExportIds((currentState) => {
        const pendingExports = (
          allDataConnectorJobs?.data?.dataConnectorJobs?.nodes || []
        ).filter(
          ({ status, type }: DataConnectorJob) =>
            type === DataConnectorJobType.ExportDataset &&
            status === DataImportExportStatus.Pending
        );
        if (currentState.length === 0 && pendingExports.length === 0) {
          return currentState;
        }
        const pendingExportIds = pendingExports.map((item) => item.id);
        const exportsToStart = pendingExportIds.filter(
          (id) => !currentState.includes(id)
        );
        if (exportsToStart.length === 0) {
          return currentState;
        }
        void Promise.all(
          exportsToStart.map((id) =>
            pollDatasetExportMutation({ variables: { id } })
          )
        );
        return [...new Set([...currentState, ...exportsToStart])];
      }),
    [
      allDataConnectorJobs?.data?.dataConnectorJobs?.nodes,
      setCurrentlyRunningExportIds,
      pollDatasetExportMutation,
    ]
  );
}
