import {
  createErrorMessagesAndAutofixes,
  DqParticipantsEditor,
  type DqParticipantsEditorProps,
} from "@decentriq/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, useCallback, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { type MediaDataRoomOrganizationRole } from "features/dataRoomCreation/models";
import {
  dialogParticipantRoleTitle,
  getMediaDataRoomCreationParticipantDialogFormSchema,
  type MediaDataRoomCreationParticipantDialogFormValues,
} from "./models";

export interface MediaDataRoomCreationParticipantEmailsDialogProps {
  defaultEmails?: string[] | undefined;
  open: boolean;
  onCancel: () => void;
  onSubmit?: (emails: string[]) => void;
  participantName: string;
  participantRole: MediaDataRoomOrganizationRole;
  canDeleteDefaultEmails?: boolean;
  otherParticipantsEmails: string[];
}

const MediaDataRoomCreationParticipantEmailsDialog =
  memo<MediaDataRoomCreationParticipantEmailsDialogProps>(
    ({
      open,
      onCancel,
      onSubmit,
      participantRole,
      defaultEmails: participantDefaultEmails = [],
      participantName,
      canDeleteDefaultEmails,
      otherParticipantsEmails,
    }) => {
      const participantEmailsForm =
        useForm<MediaDataRoomCreationParticipantDialogFormValues>({
          defaultValues: {
            emails: participantDefaultEmails,
          },
          mode: "onChange",
          resolver: zodResolver(
            getMediaDataRoomCreationParticipantDialogFormSchema(
              otherParticipantsEmails
            )
          ),
        });
      const {
        handleSubmit,
        watch,
        control,
        reset: resetForm,
      } = participantEmailsForm;

      const emails = watch("emails");
      const isEditMode: boolean = (participantDefaultEmails || []).length > 0;

      const { breakpoints } = useTheme();
      const { enqueueSnackbar } = useSnackbar();

      const handleDialogClose = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);

      const handleEmailsFormSubmit = useCallback(() => {
        void handleSubmit(
          ({ emails }) => {
            onSubmit?.(emails);
            resetForm();
          },
          (errors) => {
            if (errors.emails) {
              enqueueSnackbar(errors.emails.message);
            }
          }
        )();
      }, [handleSubmit, onSubmit, resetForm, enqueueSnackbar]);

      const lockedEmailValues = useMemo<
        NonNullable<DqParticipantsEditorProps["valueLocks"]>
      >(() => {
        const defaultLock: NonNullable<
          DqParticipantsEditorProps["valueLocks"]
        >[number] = {
          message: "This email cannot be removed",
          reason: "custom",
          value: "",
        };
        if (!canDeleteDefaultEmails) {
          return participantDefaultEmails.map((email) => ({
            ...defaultLock,
            value: email,
          }));
        }
        if (isEditMode && emails?.length === 1) {
          return [
            {
              ...defaultLock,
              value: emails[0],
            },
          ];
        }
        return [];
      }, [
        canDeleteDefaultEmails,
        isEditMode,
        emails,
        participantDefaultEmails,
      ]);

      const dialogTitle = useMemo<string>(() => {
        const dialogTitlePrefix: string = isEditMode ? "Edit" : "Add";
        return `${dialogTitlePrefix} ${isEditMode ? participantName : dialogParticipantRoleTitle[participantRole]}`;
      }, [isEditMode, participantName, participantRole]);

      return (
        <Modal open={open}>
          <ModalDialog
            maxWidth={breakpoints.values.md}
            minWidth={breakpoints.values.md}
          >
            <FormProvider {...participantEmailsForm}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent>
                <Stack>
                  <Controller
                    control={control}
                    name="emails"
                    render={({ field, fieldState }) => {
                      const { error } = fieldState;
                      return (
                        <FormControl
                          disabled={field.disabled}
                          error={Boolean(error)}
                          required={true}
                        >
                          <FormLabel>
                            <Typography level="inherit">Add emails</Typography>
                          </FormLabel>
                          <DqParticipantsEditor
                            error={error}
                            onChange={(event, newValue) =>
                              field.onChange(newValue)
                            }
                            options={[]}
                            placeholder={`e.g. "user@domain.com"`}
                            slotProps={{
                              clearIndicator: { component: () => null },
                            }}
                            value={field.value}
                            valueLocks={lockedEmailValues}
                          />
                          <FormHelperText>
                            {error
                              ? createErrorMessagesAndAutofixes(field, error)
                              : "Type in user email and press Enter"}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                  <Alert color="info">
                    An email will be sent to{" "}
                    {dialogParticipantRoleTitle[participantRole]} after the
                    Media DCR will be published.
                  </Alert>
                </Stack>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button
                  color="primary"
                  onClick={handleEmailsFormSubmit}
                  variant="solid"
                >
                  Save
                </Button>
              </DialogActions>
            </FormProvider>
          </ModalDialog>
        </Modal>
      );
    }
  );

MediaDataRoomCreationParticipantEmailsDialog.displayName =
  "MediaDataRoomCreationParticipantEmailsDialog";

export default MediaDataRoomCreationParticipantEmailsDialog;
