import { DqCard, DqCardContent, DqIcon } from "@decentriq/components";
import { Box, Stack, Typography, useTheme } from "@mui/joy";
import { memo, useMemo } from "react";
import {
  mediaDataRoomOrganizationRoleCardPresentation,
  type MediaDataRoomParticipant,
} from "features/dataRoomCreation/models";
import ParticipantCardActions from "./ParticipantCardActions";

interface ParticipantCardProps {
  participant: MediaDataRoomParticipant;
  onDelete: () => void;
  onEdit: (updatedParticipant: MediaDataRoomParticipant) => void;
  onClick: () => void;
  otherParticipantsEmails: string[];
}

const ParticipantCard = memo<ParticipantCardProps>(
  ({
    participant: { role, name, logo, isCurrentUser },
    participant,
    onDelete,
    onEdit,
    onClick,
    otherParticipantsEmails,
  }) => {
    const { palette } = useTheme();

    const participantRoleLabel = useMemo(
      () =>
        `${mediaDataRoomOrganizationRoleCardPresentation[role].title}${isCurrentUser ? " (You)" : ""}`,
      [isCurrentUser, role]
    );

    return (
      <DqCard
        Actions={
          <ParticipantCardActions
            onDelete={onDelete}
            onEdit={onEdit}
            otherParticipantsEmails={otherParticipantsEmails}
            participant={participant}
          />
        }
        color="primary"
        label={participantRoleLabel}
        onClick={onClick}
      >
        <DqCardContent gap={1}>
          <Stack alignItems="center" justifyContent="center" minHeight="140px">
            <DqIcon
              icon={mediaDataRoomOrganizationRoleCardPresentation[role].icon}
              iconProps={{ color: palette.primary[400], size: "2x" }}
            />
            <Typography
              fontWeight={600}
              level="title-md"
              sx={{ color: "initial" }}
              textAlign="center"
            >
              {name}
            </Typography>
            <Box height="40px">
              {logo && <DqIcon icon={logo} iconProps={{ height: "100%" }} />}
            </Box>
          </Stack>
        </DqCardContent>
      </DqCard>
    );
  }
);

ParticipantCard.displayName = "ParticipantCard";

export default ParticipantCard;
