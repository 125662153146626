import { Chip, Stack, styled, Typography } from "@mui/joy";
import { memo } from "react";
import {
  type MediaDataRoomCollaborationType,
  mediaDataRoomCollaborationTypesCardPresentation,
} from "features/dataRoomCreation/models";
import { type DataRoomType, dataRoomTypePresentationMap } from "models";

const CreateDataRoomStepHeaderContainer = styled(Stack)(
  ({ theme: { palette, spacing } }) => ({
    borderBottom: `1px solid ${palette.divider}`,
    flexDirection: "row",
    padding: spacing(2),
    width: "100%",
  })
);

interface CreateDataRoomStepHeaderProps {
  dataRoomType: DataRoomType | null | undefined;
  collaborationTypes?: MediaDataRoomCollaborationType[];
}

const CreateDataRoomStepHeader = memo<CreateDataRoomStepHeaderProps>(
  ({ dataRoomType, collaborationTypes = [] }) => {
    return (
      <CreateDataRoomStepHeaderContainer>
        <Typography level="title-lg">New DCR Creation</Typography>
        {dataRoomType && (
          <Chip>{dataRoomTypePresentationMap[dataRoomType]}</Chip>
        )}
        {collaborationTypes.length > 0 ? (
          <Chip>
            {collaborationTypes
              .map(
                (type) =>
                  mediaDataRoomCollaborationTypesCardPresentation[type].title
              )
              .join(", ")}
          </Chip>
        ) : null}
      </CreateDataRoomStepHeaderContainer>
    );
  }
);

CreateDataRoomStepHeader.displayName = "CreateDataRoomStepHeader";

export default CreateDataRoomStepHeader;
