import { DqLoader } from "@decentriq/components";
import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminRoute } from "components";
import { useOrganizationPreferences, useUserRole } from "hooks";
import { Main } from "layouts";
import {
  AccessTokensPage,
  AdminPortalPage,
  DatalabsPage,
  DataPartnerPortalPage,
  DataRoomsPage,
  DatasetsPage,
  DraftDataRoomPage,
  FourOFourPage,
  HomePage,
  LegacyAccessTokensPage,
  MediaDataRoomPage,
  PublishedDataRoomPage,
  PublisherPortalPage,
} from "pages";

const PagesWrapper = memo(() => {
  const {
    isOrganizationAdmin,
    isSuperAdmin,
    isSuperAdminReadOnly,
    organizationId: userOrganizationId,
    isLoading: isUserRolesLoading,
  } = useUserRole();
  const {
    hasDatalabFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
    isLoading: isOrganizationPreferencesLoading,
  } = useOrganizationPreferences();
  if (isUserRolesLoading || isOrganizationPreferencesLoading) {
    return (
      <DqLoader
        size="sm"
        sx={{ height: "100%", justifyContent: "center", width: "100%" }}
      />
    );
  }
  return (
    <Main>
      <Routes>
        <Route element={<HomePage />} path="/" />
        <Route element={<DatasetsPage />} path="/datasets/*" />
        {/* TODO: Enable this route after MDCR v3 is finished */}
        {/* <Route
          element={<PrivateRoute component={CreateDataRoomPage} />}
          path="/datarooms/create/*"
        /> */}
        <Route
          element={<DraftDataRoomPage />}
          path="/datarooms/d/:dataRoomId/*"
        />
        <Route
          element={<PublishedDataRoomPage />}
          path="/datarooms/p/:dataRoomId/*"
        />
        <Route
          element={<MediaDataRoomPage />}
          path="/datarooms/mi/:dataRoomId/*"
        />
        <Route element={<DataRoomsPage />} path="/datarooms/:dataRoomId?" />
        <Route element={<AccessTokensPage />} path="/tokens" />
        <Route element={<LegacyAccessTokensPage />} path="/legacy-tokens" />
        {hasDatalabFeatures &&
          !(hasPublisherFeatures || hasDataPartnerFeatures) && (
            <Route element={<DatalabsPage />} path="/datalabs/*" />
          )}
        {hasPublisherFeatures && (
          <Route element={<PublisherPortalPage />} path="/publisherportal/*" />
        )}
        {hasDataPartnerFeatures && (
          <Route
            element={<DataPartnerPortalPage />}
            path="/data-partner-portal/*"
          />
        )}
        <Route
          element={<AdminRoute component={AdminPortalPage} />}
          path="/admin/*"
        />
        {/* Redirecting to organizations page if user is super admin and organization page if organization admin */}
        <Route
          element={
            <Navigate
              replace={true}
              to={{
                pathname:
                  isSuperAdmin || isSuperAdminReadOnly
                    ? "/admin/organizations"
                    : isOrganizationAdmin
                      ? `/admin/organizations/${userOrganizationId}`
                      : "/",
              }}
            />
          }
          path="/admin"
        />
        <Route element={<Navigate replace={true} to="/" />} path="/signup" />
        <Route element={<FourOFourPage />} path="*" />
      </Routes>
    </Main>
  );
});

export default PagesWrapper;
