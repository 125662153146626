import { type FieldErrors } from "react-hook-form";
import {
  MediaDataRoomCollaborationType,
  type MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
  MediaDataRoomOrganizationPermission,
  MediaDataRoomOrganizationRole,
  type MediaDataRoomParticipant,
  type MediaDataRoomParticipantPermissionConfig,
  mediaDataRoomParticipantPermissionConfigsCreator,
} from "features/dataRoomCreation/models";

export const mapCollaborationTypesAndParticipantsToFormValues = (
  collaborationTypes: MediaDataRoomCollaborationType[],
  participants: MediaDataRoomParticipant[]
): MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues => ({
  collaborationTypes: Object.values(MediaDataRoomCollaborationType).reduce(
    (collaborationTypesReducer, collaborationType) => ({
      ...collaborationTypesReducer,
      [collaborationType]: collaborationTypes.includes(collaborationType),
    }),
    {} as MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["collaborationTypes"]
  ),
  [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
    participants.find((participant) =>
      participant.permissions.includes(
        MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE
      )
    )?.id ?? "",
  [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
    participants.find((participant) =>
      participant.permissions.includes(
        MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE
      )
    )?.id ?? "",
  participants: participants.map(
    ({ name, role, dataPartnerConfig, publisherConfig, permissions, id }) => ({
      id,
      matchingId:
        role === MediaDataRoomOrganizationRole.DATA_PARTNER
          ? (dataPartnerConfig?.matchingIdFormat ?? undefined)
          : role === MediaDataRoomOrganizationRole.PUBLISHER
            ? (publisherConfig?.matchingIdFormat ?? undefined)
            : undefined,
      matchingIdHashingAlgorithm:
        role === MediaDataRoomOrganizationRole.DATA_PARTNER
          ? (dataPartnerConfig?.matchingIdHashingAlgorithm ?? undefined)
          : role === MediaDataRoomOrganizationRole.PUBLISHER
            ? (publisherConfig?.matchingIdHashingAlgorithm ?? undefined)
            : undefined,
      name,
      permissions: Object.values(MediaDataRoomOrganizationPermission)
        .filter(
          (permission) =>
            ![
              MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE,
              MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE,
            ].includes(permission)
        )
        .reduce(
          (permissionsReducer, permission) => ({
            ...permissionsReducer,
            [permission]: permissions.includes(permission),
          }),
          {} as MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["participants"][number]["permissions"]
        ),
      role,
      supportedCollaborationTypes:
        dataPartnerConfig || publisherConfig
          ? [
              MediaDataRoomCollaborationType.OVERLAP_ANALYSIS,
              ...((dataPartnerConfig || publisherConfig)?.allowInsights
                ? [MediaDataRoomCollaborationType.INSIGHTS]
                : []),
              ...((dataPartnerConfig || publisherConfig)?.allowLookalike
                ? [MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES]
                : []),
              ...((dataPartnerConfig || publisherConfig)
                ?.allowRuleBasedAudiences
                ? [MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES]
                : []),
              ...((dataPartnerConfig || publisherConfig)?.allowRetargeting
                ? [MediaDataRoomCollaborationType.REMARKETING_AUDIENCES]
                : []),
            ]
          : undefined,
    })
  ),
});

export const mapFormValuesToCollaborationTypesAndPermissions = (
  formValues: MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues
): {
  collaborationTypes: MediaDataRoomCollaborationType[];
  permissions: Record<string, MediaDataRoomOrganizationPermission[]>;
} => ({
  collaborationTypes: Object.entries(formValues.collaborationTypes)
    .filter(([, value]) => value)
    .map(([key]) => key as MediaDataRoomCollaborationType),
  permissions: formValues.participants.reduce(
    (acc, { id, permissions }) => ({
      ...acc,
      [id]: [
        ...(formValues[
          MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE
        ] === id
          ? [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]
          : []),
        ...(formValues[
          MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE
        ] === id
          ? [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]
          : []),
        ...Object.entries(permissions)
          .filter(([, value]) => value)
          .map(([key]) => key as MediaDataRoomOrganizationPermission),
      ],
    }),
    {} as Record<string, MediaDataRoomOrganizationPermission[]>
  ),
});

export const getAllDataRoomParticipantsPermissionsByRole = (
  collaborationTypes: MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["collaborationTypes"]
): Record<
  MediaDataRoomOrganizationRole,
  Record<
    MediaDataRoomOrganizationPermission,
    MediaDataRoomParticipantPermissionConfig
  >
> =>
  Object.values(MediaDataRoomOrganizationRole).reduce(
    (acc, value) => ({
      ...acc,
      [value]: mediaDataRoomParticipantPermissionConfigsCreator(
        value,
        Object.entries(collaborationTypes)
          .filter(([, value]) => value)
          .map(([key]) => key as MediaDataRoomCollaborationType)
      ),
    }),
    {} as Record<
      MediaDataRoomOrganizationRole,
      Record<
        MediaDataRoomOrganizationPermission,
        MediaDataRoomParticipantPermissionConfig
      >
    >
  );

export const flattenMediaDataRoomCreationCollaborationTypesAndPermissionsStepFormFieldErrors =
  (
    errors: FieldErrors<MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues>
  ): string[] =>
    Object.values(errors)
      .flatMap((error) => {
        if (error.message && !("root" in error)) {
          return [error.message];
        }
        if (!error.root || !("types" in error.root)) {
          return [];
        }
        if (!error.root.types || !error.root.types[error.root.type]) {
          return [];
        }
        if (Array.isArray(error.root.types[error.root.type])) {
          return error.root.types[error.root.type];
        }
        return [error.root.types[error.root.type]];
      })
      .filter(Boolean) as string[];
