import { DqCard, DqCardContent } from "@decentriq/components";
import {
  type AvailableDataPartnerFragment,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/joy";
import { memo } from "react";
import { Controller } from "react-hook-form";
import {
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRolePresentationMap,
} from "features/dataRoomCreation/models";
import { type MediaDataRoomCreationParticipantSelectionFormValues } from "../model";
import { DataPartnerCard, PublisherCard } from "./index";

type SelectParticipantFormProps = {
  onInvite: () => void;
  data: (AvailablePublisherFragment | AvailableDataPartnerFragment)[];
} & (
  | {
      role: MediaDataRoomOrganizationRole.PUBLISHER;
    }
  | {
      role: MediaDataRoomOrganizationRole.DATA_PARTNER;
    }
);

const SelectParticipantForm = memo<SelectParticipantFormProps>(
  ({ data, onInvite, role }) => {
    const theme = useTheme();

    return (
      <Controller<
        MediaDataRoomCreationParticipantSelectionFormValues,
        "selectedParticipantId"
      >
        name="selectedParticipantId"
        render={({ field, fieldState }) => {
          return (
            <FormControl error={Boolean(fieldState.error?.message)}>
              <RadioGroup {...field} value={field.value || ""}>
                <Grid container={true} spacing={2} sx={{ flexWrap: "wrap" }}>
                  <Grid sm={4} xs={12}>
                    <DqCard color="primary" onClick={onInvite} variant="soft">
                      <DqCardContent>
                        <FontAwesomeIcon
                          color={theme.palette.primary[500]}
                          icon={faPlus}
                          size="2x"
                        />
                        <Typography
                          color="primary"
                          fontWeight={500}
                          level="title-md"
                          textAlign="center"
                        >
                          {`Invite a ${mediaDataRoomOrganizationRolePresentationMap[role]}`}
                        </Typography>
                      </DqCardContent>
                    </DqCard>
                  </Grid>
                  {data.map((publisherOrDataPartner) => {
                    return (
                      <Grid key={publisherOrDataPartner.id} sm={4} xs={12}>
                        <DqCard
                          selectable={{
                            component: "radio",
                            value: publisherOrDataPartner.id,
                          }}
                        >
                          <DqCardContent>
                            {role ===
                            MediaDataRoomOrganizationRole.PUBLISHER ? (
                              <PublisherCard
                                {...(publisherOrDataPartner as AvailablePublisherFragment)}
                              />
                            ) : role ===
                              MediaDataRoomOrganizationRole.DATA_PARTNER ? (
                              <DataPartnerCard
                                {...(publisherOrDataPartner as AvailableDataPartnerFragment)}
                              />
                            ) : null}
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                mb: 1,
                                mt: 1,
                              }}
                            >
                              {publisherOrDataPartner.marketIds
                                .map((id) => (
                                  <Box
                                    key={id}
                                    sx={{ mb: 0.5, mr: 0.5, mt: 0 }}
                                  >
                                    <Chip key={id}>{id}</Chip>
                                  </Box>
                                ))
                                .slice(0, 3)}
                              {publisherOrDataPartner.marketIds.length > 3 ? (
                                <Box sx={{ mb: 0.5, mr: 0.5, mt: 0 }}>
                                  <Chip>
                                    {`+${publisherOrDataPartner.marketIds.length - 3}`}
                                  </Chip>
                                </Box>
                              ) : null}
                            </Box>
                          </DqCardContent>
                        </DqCard>
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

SelectParticipantForm.displayName = "SelectParticipantForm";
export default SelectParticipantForm;
