import {
  MatchingColumnFormat,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";

export enum RawMatchingID {
  EMAIL = "email",
  HASHED_EMAIL = "hashed_email",
  HASHED_PHONE_NUMBER = "hashed_phone_number",
  PHONE_NUMBER = "phone_number",
  STRING = "string",
}

export const matchingIdTypeToGqlValues: Record<
  RawMatchingID,
  [MatchingColumnFormat, TableColumnHashingAlgorithm | null]
> = {
  [RawMatchingID.EMAIL]: [MatchingColumnFormat.Email, null],
  [RawMatchingID.HASHED_EMAIL]: [
    MatchingColumnFormat.Email,
    TableColumnHashingAlgorithm.Sha256Hex,
  ],
  [RawMatchingID.HASHED_PHONE_NUMBER]: [
    MatchingColumnFormat.PhoneNumberE164,
    TableColumnHashingAlgorithm.Sha256Hex,
  ],
  [RawMatchingID.PHONE_NUMBER]: [MatchingColumnFormat.PhoneNumberE164, null],
  [RawMatchingID.STRING]: [MatchingColumnFormat.String, null],
};

export const matchingIdTypes: { name: string; value: RawMatchingID }[] = [
  { name: "String", value: RawMatchingID.STRING },
  { name: "Email", value: RawMatchingID.EMAIL },
  { name: "Hashed email", value: RawMatchingID.HASHED_EMAIL },
  { name: "Phone number", value: RawMatchingID.PHONE_NUMBER },
  { name: "Hashed phone number", value: RawMatchingID.HASHED_PHONE_NUMBER },
];
