import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import {
  type CollaborationTypes,
  type MediaDataRoomUserRole,
  type PublishMediaDataRoomInput,
} from "@decentriq/types";
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from "react";
import { type SchemaType } from "../../../DqCreateMediaDcrFormProvider/model";

export interface CreationWizardConfigurationContextValue {
  hasSelectedPublisher: boolean;
  hasSelectedDataPartner: boolean;
  dataRoomName: string;
  setDataRoomName: (name: SetStateAction<string>) => void;
  enabledFeatures: CollaborationTypes[];
  setEnabledFeatures: (
    enabledFeatures: SetStateAction<CollaborationTypes[]>
  ) => void;
  setWithObserver: (withObserver: SetStateAction<boolean>) => void;
  setWithAgency: (withAgency: SetStateAction<boolean>) => void;
  withObserver: boolean;
  withAgency: boolean;
  withDataPartner: boolean;
  showAbsoluteAudienceSizes: boolean;
  setShowAbsoluteAudienceSizes: (
    showAudienceSizes: SetStateAction<boolean>
  ) => void;
  matchingIdFormat: MatchingColumnFormat | null;
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | null;
  setMatchingIdFormat: (matchingIdFormat: MatchingColumnFormat) => void;
  setMatchingIdHashingAlgorithm: (
    tableColumnHashingAlgorithm: TableColumnHashingAlgorithm | null
  ) => void;
  addParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  removeParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  participantsEmails: Map<MediaDataRoomUserRole, string[]>;
  mainPublisherUserEmail: string;
  mainAdvertiserUserEmail: string;
  setMainPublisherUserEmail: (email: SetStateAction<string>) => void;
  setMainAdvertiserUserEmail: (email: SetStateAction<string>) => void;
  requestForCollaborationMessage: string;
  collaborationRequestRecipientId: string | undefined;
  setRequestForCollaborationMessage: (message: SetStateAction<string>) => void;
  getValues: (formValues: SchemaType) => PublishMediaDataRoomInput;
  handleSendCollaborationRequest: () => Promise<void>;
  handleSubmit: (formValues: SchemaType) => Promise<void>;
  allowedCollaborationTypes: CollaborationTypes[];
  enableAdvertiserAudienceDownload: boolean;
  setEnableAdvertiserAudienceDownload: (
    enableAdvertiserAudienceDownload: SetStateAction<boolean>
  ) => void;
  canProceedToConfiguration: boolean;
  contactButtonEnabled: boolean;
  contactButtonTooltip: string | null;
  setParticipantsEmails: Dispatch<
    SetStateAction<Map<MediaDataRoomUserRole, string[]>>
  >;
}

export const CreationWizardConfigurationContext =
  createContext<CreationWizardConfigurationContextValue | null>(null);

export const CreationWizardConfigurationContextProvider =
  CreationWizardConfigurationContext.Provider;

export const useCreationWizardConfiguration = () => {
  const contextValue = useContext(CreationWizardConfigurationContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardConfiguration must be used within a CreationWizardConfigurationWrapper"
    );
  }
  return contextValue;
};
