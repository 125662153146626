import {
  DqSidePanel,
  DqSidePanelActions,
  DqSidePanelContent,
} from "@decentriq/components";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataLabDetails,
  DataLabDetailsToolbar,
  DataLabWrapper,
  useDatalabsBaseUrl,
} from "features/dataLabs";

type DataLabDrawerProps = {
  open: boolean;
  selectedDataLabId: string | undefined;
  onClose: () => void;
};

const DataLabDrawer: React.FC<DataLabDrawerProps> = ({
  open,
  selectedDataLabId,
  onClose,
}) => {
  const navigate = useNavigate();
  const datalabsBaseUrl = useDatalabsBaseUrl();
  return (
    <DataLabWrapper id={selectedDataLabId!}>
      <DqSidePanel anchor="right" onClose={onClose} open={open}>
        <DataLabDetailsToolbar id={selectedDataLabId!} onClose={onClose} />
        <DqSidePanelContent>
          <DataLabDetails id={selectedDataLabId!} />
        </DqSidePanelContent>
        <DqSidePanelActions justifyContent="flex-end">
          <Button
            color="primary"
            endDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
            }
            onClick={() => navigate(`${datalabsBaseUrl}/${selectedDataLabId}`)}
            variant="solid"
          >
            Go to datalab
          </Button>
        </DqSidePanelActions>
      </DqSidePanel>
    </DataLabWrapper>
  );
};

DataLabDrawer.displayName = "DataLabDrawer";

export default memo(DataLabDrawer);
