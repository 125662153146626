import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useOrganizationPreferences, useSafeContext } from "hooks";
import { DataRoomType } from "models";

export interface DataRoomCreationContextValue {
  currentUserEmail: string;
  dataRoomType: DataRoomType | null;
  setDataRoomType: (dataRoomType: DataRoomType | null) => void;
  hasDataRoomTypeStep: boolean;
  canCreateAnalyticsDataRoom: boolean;
  canCreateMeasurementDataRoom: boolean;
  canCreateMediaDataRoom: boolean;
  isLoading: boolean;
  cancelCreation: () => void;
}

const DataRoomCreationContext =
  createContext<DataRoomCreationContextValue | null>(null);

export const DataRoomCreationContextProvider = DataRoomCreationContext.Provider;

export const useDataRoomCreation = () =>
  useSafeContext(DataRoomCreationContext);

export const DataRoomCreationWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const {
      numberOfAvailableDataRoomTypes,
      canViewMeasurements,
      canViewMediaDataRoom,
      hasAnalyticsFeatures,
      isLoading,
    } = useOrganizationPreferences();
    const { user } = useAuth0();
    const navigate = useNavigate();
    const [dataRoomType, setDataRoomType] = useState<DataRoomType | null>(null);
    const cancelCreation = useCallback(() => {
      navigate(-1);
    }, [navigate]);
    const hasDataRoomTypeStep = numberOfAvailableDataRoomTypes > 1;
    const currentUserEmail = user?.email ?? "";
    useEffect(() => {
      if (isLoading) {
        return;
      }
      setDataRoomType((currentDataRoomType) => {
        if (currentDataRoomType || hasDataRoomTypeStep) {
          return currentDataRoomType;
        }
        if (hasAnalyticsFeatures) {
          return DataRoomType.DataScience;
        }
        if (canViewMeasurements) {
          return DataRoomType.Measurement;
        }
        if (canViewMediaDataRoom) {
          return DataRoomType.MediaInsights;
        }
        return currentDataRoomType;
      });
    }, [
      setDataRoomType,
      isLoading,
      hasAnalyticsFeatures,
      canViewMediaDataRoom,
      canViewMeasurements,
      hasDataRoomTypeStep,
    ]);
    const contextValue = useMemo<DataRoomCreationContextValue>(
      () => ({
        canCreateAnalyticsDataRoom: hasAnalyticsFeatures,
        canCreateMeasurementDataRoom: canViewMeasurements,
        canCreateMediaDataRoom: canViewMediaDataRoom,
        cancelCreation,
        currentUserEmail,
        dataRoomType,
        hasDataRoomTypeStep,
        isLoading,
        setDataRoomType,
      }),
      [
        currentUserEmail,
        hasDataRoomTypeStep,
        dataRoomType,
        setDataRoomType,
        canViewMeasurements,
        canViewMediaDataRoom,
        hasAnalyticsFeatures,
        cancelCreation,
        isLoading,
      ]
    );
    return (
      <DataRoomCreationContext.Provider value={contextValue}>
        {children}
      </DataRoomCreationContext.Provider>
    );
  }
);
DataRoomCreationWrapper.displayName = "DataRoomCreationWrapper";
