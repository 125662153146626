import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getRawMatchingIdForFormatAndHashingAlgorithm,
  MediaDataRoomCollaborationType,
  type MediaDataRoomCreationConfigurationFormValues,
  MediaDataRoomOrganizationPermission,
  MediaDataRoomOrganizationRole,
  type MediaDataRoomParticipant,
  type MediaDataRoomParticipantPermissionConfig,
  mediaDataRoomParticipantPermissionConfigsCreator,
} from "features/dataRoomCreation/models";
import { type RawMatchingID } from "models/dataRoom/matchingId";

export interface MediaDataRoomCreationStateHookPayload {
  hasAdvertiserFeatures: boolean;
  hasDataPartnerFeatures: boolean;
  hasPublisherFeatures: boolean;
  hasCurrentUserRoleStep: boolean;
  organizationLogo?: string;
  organizationName: string;
  currentUserEmail: string;
  isLoading: boolean;
}

export interface MediaDataRoomCreationStateHookResult {
  participants: MediaDataRoomParticipant[];
  setParticipants: (participants: MediaDataRoomParticipant[]) => void;
  deleteParticipant: (participant: MediaDataRoomParticipant) => void;
  editParticipant: (participants: MediaDataRoomParticipant) => void;
  addParticipant: (participants: MediaDataRoomParticipant) => void;
  setCurrentUserRole: (currentUserRole: MediaDataRoomOrganizationRole) => void;
  currentUserRole: MediaDataRoomOrganizationRole | null;
  collaborationTypes: MediaDataRoomCollaborationType[];
  setCollaborationTypes: (
    collaborationTypes: MediaDataRoomCollaborationType[]
  ) => void;
  setPermissions: (
    permissionsMap: Record<string, MediaDataRoomOrganizationPermission[]>
  ) => void;
  configuration: MediaDataRoomCreationConfigurationFormValues | null;
  setConfiguration: (
    configuration: MediaDataRoomCreationConfigurationFormValues
  ) => void;
}

const useMediaDataRoomCreationState = ({
  hasAdvertiserFeatures,
  hasDataPartnerFeatures,
  hasPublisherFeatures,
  hasCurrentUserRoleStep,
  currentUserEmail,
  organizationLogo: logoBase64,
  organizationName,
  isLoading,
}: MediaDataRoomCreationStateHookPayload): MediaDataRoomCreationStateHookResult => {
  const [participants, setParticipants] = useState<MediaDataRoomParticipant[]>(
    []
  );
  const [collaborationTypes, setCollaborationTypes] = useState<
    MediaDataRoomCollaborationType[]
  >([MediaDataRoomCollaborationType.OVERLAP_ANALYSIS]);
  const [configuration, setConfiguration] =
    useState<MediaDataRoomCreationConfigurationFormValues | null>(null);
  const currentUserDefaultParticipant = useMemo<
    Pick<
      MediaDataRoomParticipant,
      "id" | "name" | "logo" | "isCurrentUser" | "emails" | "permissions"
    >
  >(
    () => ({
      emails: [currentUserEmail],
      id: crypto.randomUUID(),
      isCurrentUser: true,
      logo: logoBase64 ? `data:image/png;base64,${logoBase64}` : undefined,
      name: organizationName,
      permissions: [],
    }),
    [currentUserEmail, logoBase64, organizationName]
  );
  const addOrUpdateCurrentUserParticipantWithRole = useCallback(
    (
      participants: MediaDataRoomParticipant[],
      role: MediaDataRoomOrganizationRole
    ) => {
      const hasCurrentUserAdded = participants.some(
        (participant) => participant.isCurrentUser
      );
      if (hasCurrentUserAdded) {
        return participants.map((participant) =>
          participant.isCurrentUser
            ? {
                ...participant,
                role,
              }
            : participant
        );
      }
      return [
        {
          ...currentUserDefaultParticipant,
          role,
        },
        ...participants,
      ];
    },
    [currentUserDefaultParticipant]
  );
  const setCurrentUserRole = useCallback(
    (currentUserRole: MediaDataRoomOrganizationRole) =>
      setParticipants((participants) =>
        addOrUpdateCurrentUserParticipantWithRole(participants, currentUserRole)
      ),
    [addOrUpdateCurrentUserParticipantWithRole, setParticipants]
  );
  const deleteParticipant = useCallback(
    (participant: MediaDataRoomParticipant) =>
      setParticipants((participants) =>
        participants.filter(({ id }) => id !== participant.id)
      ),
    [setParticipants]
  );
  const editParticipant = useCallback(
    (updatedParticipant: MediaDataRoomParticipant) =>
      setParticipants((participants) =>
        participants.map((participant) =>
          participant.id === updatedParticipant.id
            ? updatedParticipant
            : participant
        )
      ),
    [setParticipants]
  );
  const addParticipant = useCallback(
    (participant: MediaDataRoomParticipant) =>
      setParticipants((participants) => [...participants, participant]),
    [setParticipants]
  );
  const setPermissions = useCallback(
    (permissionsMap: Record<string, MediaDataRoomOrganizationPermission[]>) =>
      setParticipants((participants) =>
        participants.map((participant) => ({
          ...participant,
          permissions: permissionsMap[participant.id] ?? [],
        }))
      ),
    [setParticipants]
  );
  const handleSetParticipantsWithDefaultPermissions = useCallback(
    (participants: MediaDataRoomParticipant[]) => {
      const isExclusivePermissionSet = new Map([
        [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE, false],
        [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE, false],
      ]);
      setParticipants(
        participants.map((participant) => {
          const rolePermissions =
            mediaDataRoomParticipantPermissionConfigsCreator(
              participant.role,
              collaborationTypes
            );
          return {
            ...participant,
            permissions: Object.entries(rolePermissions)
              .filter((entry) => {
                const [permission, config] = entry as [
                  MediaDataRoomOrganizationPermission,
                  MediaDataRoomParticipantPermissionConfig,
                ];
                const defaultValue = config.defaultValue ?? false;
                if (!isExclusivePermissionSet.has(permission)) {
                  return defaultValue;
                }
                if (isExclusivePermissionSet.get(permission)) {
                  return false;
                }
                if (defaultValue) {
                  isExclusivePermissionSet.set(permission, true);
                }
                return defaultValue;
              })
              .map(([key]) => key as MediaDataRoomOrganizationPermission),
          };
        })
      );
    },
    [setParticipants, collaborationTypes]
  );
  const defaultMatchingId = useMemo<RawMatchingID | null>(() => {
    const participantWithProvideBaseAudiencePermission = participants.find(
      (participant) =>
        participant.permissions.includes(
          MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE
        )
    );
    const matchingId =
      participantWithProvideBaseAudiencePermission?.publisherConfig
        ?.matchingIdFormat ||
      participantWithProvideBaseAudiencePermission?.dataPartnerConfig
        ?.matchingIdFormat;
    const hashingAlgorithm =
      participantWithProvideBaseAudiencePermission?.publisherConfig
        ?.matchingIdHashingAlgorithm ||
      participantWithProvideBaseAudiencePermission?.dataPartnerConfig
        ?.matchingIdHashingAlgorithm;
    if (!matchingId) {
      return null;
    }
    return getRawMatchingIdForFormatAndHashingAlgorithm(
      matchingId,
      hashingAlgorithm
    );
  }, [participants]);
  const currentUserRole = useMemo(
    () =>
      participants.find(({ emails }) => emails.includes(currentUserEmail))
        ?.role ?? null,
    [participants, currentUserEmail]
  );
  useEffect(
    () =>
      setConfiguration((configuration) => {
        if (
          defaultMatchingId &&
          configuration?.matchingId !== defaultMatchingId
        ) {
          return {
            matchingId: defaultMatchingId,
            name: configuration?.name ?? "",
            showAbsoluteAudienceSizes:
              configuration?.showAbsoluteAudienceSizes ?? false,
          };
        }
        return null;
      }),
    [setConfiguration, defaultMatchingId]
  );
  useEffect(() => {
    if (isLoading) {
      return;
    }
    setParticipants((participants) => {
      const hasCurrentUserSet = participants.some(
        (participant) => participant.isCurrentUser
      );
      if (hasCurrentUserSet || hasCurrentUserRoleStep) {
        return participants;
      }
      if (hasAdvertiserFeatures) {
        return addOrUpdateCurrentUserParticipantWithRole(
          participants,
          MediaDataRoomOrganizationRole.ADVERTISER
        );
      }
      if (hasDataPartnerFeatures) {
        return addOrUpdateCurrentUserParticipantWithRole(
          participants,
          MediaDataRoomOrganizationRole.DATA_PARTNER
        );
      }
      if (hasPublisherFeatures) {
        return addOrUpdateCurrentUserParticipantWithRole(
          participants,
          MediaDataRoomOrganizationRole.PUBLISHER
        );
      }
      return participants;
    });
  }, [
    setParticipants,
    addOrUpdateCurrentUserParticipantWithRole,
    isLoading,
    addParticipant,
    hasCurrentUserRoleStep,
    hasAdvertiserFeatures,
    hasDataPartnerFeatures,
    hasPublisherFeatures,
    currentUserRole,
  ]);
  return {
    addParticipant,
    collaborationTypes,
    configuration,
    currentUserRole,
    deleteParticipant,
    editParticipant,
    participants,
    setCollaborationTypes,
    setConfiguration,
    setCurrentUserRole,
    setParticipants: handleSetParticipantsWithDefaultPermissions,
    setPermissions,
  };
};

export default useMediaDataRoomCreationState;
