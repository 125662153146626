// TODO: Fix joy migration
import { useUserDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { CircularProgress, Typography } from "@mui/joy";
import { memo } from "react";
import { AdminDialog, UserDeleteButton } from "components";
import { useUserRole } from "hooks";
import UserDetailsJSONView from "./UserDetailsJSONView/UserDetailsJSONView";
import UserLogoEditor from "./UserLogoEditor/UserLogoEditor";
import UserOrganizationField from "./UserOrganizationField/UserOrganizationField";
import UserRoleField from "./UserRoleField/UserRoleField";

type UserDetailsDialogProps = {
  userId: string;
  open: boolean;
  onClose: () => void;
};

const UserDetailsDialog: React.FC<UserDetailsDialogProps> = ({
  userId,
  open,
  onClose,
}) => {
  const { data, loading } = useUserDetailsQuery({
    variables: { userId },
  });
  const user = data?.user;
  const { isSuperAdmin, isDecentriqAdmin } = useUserRole();

  return (
    <AdminDialog
      confirmButtonText="Close"
      disabled={!userId}
      maxWidth={isDecentriqAdmin ? "md" : "sm"}
      onClose={onClose}
      onConfirm={onClose}
      open={open}
      title="User details"
      withCancelButton={false}
    >
      <>
        {isSuperAdmin && <UserLogoEditor userId={userId} />}
        <Typography
          sx={{ alignItems: "center", display: "inline-flex", marginBottom: 1 }}
        >
          <strong>Email</strong>:{" "}
          {loading ? (
            <CircularProgress
              sx={{ "--CircularProgress-size": "16px", marginLeft: "6px" }}
            />
          ) : (
            user?.email
          )}
        </Typography>
        <UserRoleField userId={userId} />
        {isDecentriqAdmin && (
          <UserOrganizationField
            closeUserDetailsDialog={onClose}
            userId={userId}
          />
        )}
        {isDecentriqAdmin && <UserDetailsJSONView userId={userId} />}
        <UserDeleteButton closeUserDetailsDialog={onClose} userId={userId} />
      </>
    </AdminDialog>
  );
};

UserDetailsDialog.displayName = "UserDetailsDialog";

export default memo(UserDetailsDialog);
