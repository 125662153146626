import { testIds } from "@decentriq/utils";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";
import DataLabsSidebarIcon from "./assets/datalabs.svg";

const DatalabsSidebarMenuItem = memo(() => (
  <SidebarMenuItem
    data-testid={testIds.sidebar.sidebarMenuItems.datalabsButton}
    icon={<DataLabsSidebarIcon />}
    label="Datalabs"
    to="/datalabs"
    tooltipTitle="Datalabs"
  />
));

DatalabsSidebarMenuItem.displayName = "DatalabsSidebarMenuItem";

export default DatalabsSidebarMenuItem;
