import { type create_media_data_room as ddcCreateMediaDataRoom } from "ddc";
import { MediaDataRoomOrganizationPermission } from "features/dataRoomCreation/models";
import { RawMatchingID } from "models";

export type MediaDataRoomPublishingStep = "pending" | "completed";

export const matchingIdToFormatTypeAndHashingAlgorithmMap: Record<
  RawMatchingID,
  [
    ddcCreateMediaDataRoom.FormatType,
    ddcCreateMediaDataRoom.HashingAlgorithm | null,
  ]
> = {
  [RawMatchingID.HASHED_EMAIL]: ["EMAIL", "SHA256_HEX"],
  [RawMatchingID.EMAIL]: ["EMAIL", null],
  [RawMatchingID.HASHED_PHONE_NUMBER]: ["PHONE_NUMBER_E164", "SHA256_HEX"],
  [RawMatchingID.PHONE_NUMBER]: ["PHONE_NUMBER_E164", null],
  [RawMatchingID.STRING]: ["STRING", null],
};

export const mediaDataRoomOrganizationPermissionToPermissionMap: Record<
  MediaDataRoomOrganizationPermission,
  ddcCreateMediaDataRoom.Permission
> = {
  [MediaDataRoomOrganizationPermission.VIEW_OVERLAP]: "VIEW_OVERLAP",
  [MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]: "VIEW_INSIGHTS",
  [MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE]:
    "PROVIDE_SEED_AUDIENCE",
  [MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE]:
    "PROVIDE_BASE_AUDIENCE",
  [MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES]:
    "CREATE_CUSTOM_AUDIENCE",
  [MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES]: "EXPORT_AUDIENCE",
};
