import { useLocation } from "react-router-dom";

// Currently datalabs exist in more than one place under different URLs,
// therefore, the URL under which datalabs are rendered needs to be handled properly for
// correct redirects. Considering the above, a function returns a base URL by key word "datalabs", e.g.:
// /publisherportal/datalabs/123 => /publisherportal/datalabs
// /datalabs/12341fa1 => /datalabs
// /data-partner-portal/datalabs => /data-partner-portal/datalabs
const useDatalabsBaseUrl = (): string => {
  const { pathname } = useLocation();
  const pathnameParts = pathname.split("/");
  const datalabsPathPartIndex = pathnameParts.findIndex(
    (path) => path === "datalabs"
  );
  return datalabsPathPartIndex === -1
    ? pathname
    : pathnameParts.slice(0, datalabsPathPartIndex + 1).join("/");
};

export default useDatalabsBaseUrl;
