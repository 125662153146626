import { InfoTooltip } from "@decentriq/components";
import {
  Alert,
  Checkbox,
  FormControl,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";

const MediaDcrFeatures = memo(() => {
  const {
    allowExcludingSeedAudience,
    canViewDataPartners,
    enableAudienceBuilder,
    hasAdvertiserFeatures,
    hasDatalabFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
    isEditorDisabled,
    isPythonComputationTypeEnabled,
    updateOrganizationFlags,
    useLegacyDataLabs,
    skipMdcrAudiencesResettingOnDataLabChange,
  } = useOrganizationAdminConfiguration();
  return (
    <Stack>
      <Typography level="title-md">Media DCR</Typography>
      <FormControl>
        <Stack>
          <Tooltip title="This allows users in this organisation to create Media DCRs as Advertisers (if their license allows). This means that users can select publishers and (if enabled) data partners in the create Media DCR flow. Note that Python computation type must be enabled to use Media DCRs.">
            <Checkbox
              checked={hasAdvertiserFeatures}
              disabled={isEditorDisabled}
              label="Advertiser org role"
              name="hasAdvertiserFeatures"
              onChange={updateOrganizationFlags}
            />
          </Tooltip>
          {hasAdvertiserFeatures && !isPythonComputationTypeEnabled && (
            <Alert color="warning">
              Python computation type must be enabled to run Media DCRs.
            </Alert>
          )}
        </Stack>
      </FormControl>
      <FormControl>
        <Stack>
          <Tooltip title="This allows users in this organisation to access the Publisher Portal. This additionally allows users in this organisation to create Media DCRs as Publishers (if their license allows). Note that Python computation type must be enabled to use Media DCRs.">
            <Checkbox
              checked={hasPublisherFeatures}
              disabled={isEditorDisabled}
              label="Publisher Portal and Publisher org role"
              name="hasPublisherFeatures"
              onChange={updateOrganizationFlags}
            />
          </Tooltip>
          {hasPublisherFeatures && !isPythonComputationTypeEnabled && (
            <Alert color="warning">
              Python computation type must be enabled to run Media DCRs.
            </Alert>
          )}
        </Stack>
      </FormControl>
      <FormControl>
        <Stack>
          <Tooltip title="This allows users in this organisation to access the Data Partner Portal. This additionally allows users in this organisation to create Media DCRs as Data Partners (if their license allows). Note that Python computation type must be enabled to use Media DCRs.">
            <Checkbox
              checked={hasDataPartnerFeatures}
              disabled={isEditorDisabled}
              label="Data Partner Portal and Data Partner org role"
              name="hasDataPartnerFeatures"
              onChange={updateOrganizationFlags}
            />
          </Tooltip>
          {hasDataPartnerFeatures && !isPythonComputationTypeEnabled && (
            <Alert color="warning">
              Python computation type must be enabled to run Media DCRs.
            </Alert>
          )}
        </Stack>
      </FormControl>
      <FormControl>
        <Stack>
          <Tooltip title="If enabled and the user doesn't have Publisher or Data Partner org role, then we show a standalone Datalabs page in the sidebar. This enables cases where this org should provide the base audience but is neither a Publisher nor Data Partner.">
            <Checkbox
              checked={hasDatalabFeatures}
              disabled={isEditorDisabled}
              label="Datalab features"
              name="hasDatalabFeatures"
              onChange={updateOrganizationFlags}
            />
          </Tooltip>
        </Stack>
      </FormControl>
      <Typography level="title-sm">Media DCR features</Typography>
      <FormControl>
        <Tooltip title="If this is enabled, the user is presented with the data partner selection if they create a Media DCR as an Advertiser.">
          <Checkbox
            checked={canViewDataPartners}
            disabled={isEditorDisabled}
            label="Show data partner selection for advertiser"
            name="canViewDataPartners"
            onChange={updateOrganizationFlags}
          />
        </Tooltip>
      </FormControl>
      <FormControl>
        <Tooltip title="If this is enabled, the advertiser user can exclude the seed audience when creating a lookalike audience in Media DCRs created by users of this organisation. This feature should generally be disabled for privacy reasons.">
          <Checkbox
            checked={allowExcludingSeedAudience}
            disabled={isEditorDisabled}
            label="Allow excluding seed audience from lookalike audience"
            name="allowExcludingSeedAudience"
            onChange={updateOrganizationFlags}
          />
        </Tooltip>
      </FormControl>
      <FormControl>
        <Tooltip title="If this is enabled, the audiences are not gonna be reset when the datalab is changed.">
          <Checkbox
            checked={skipMdcrAudiencesResettingOnDataLabChange}
            disabled={isEditorDisabled}
            label="Skip audiences resetting on datalab change"
            name="skipMdcrAudiencesResettingOnDataLabChange"
            onChange={updateOrganizationFlags}
          />
        </Tooltip>
      </FormControl>
      <Typography level="title-sm">
        Compatibility
        <InfoTooltip
          tooltip="This section can be used if there are compatibility issues due to mismatching migration statuses."
          top="0"
        />
      </Typography>
      {/* Note: David specifically asked for this checkbox to have the opposite value than the flag. */}
      <FormControl>
        <Tooltip title="If this is enabled, then old MDCRs are created (prior to Audience Builder, aka prior to 4.0). Only enable this if there are compatibility issues.">
          <Checkbox
            // For this reason, the value here is negated
            checked={!enableAudienceBuilder}
            disabled={isEditorDisabled}
            label="Create old MDCRs"
            name="enableAudienceBuilder"
            // And the event handler is also negated
            onChange={(event) =>
              updateOrganizationFlags({
                ...event,
                target: {
                  ...event.target,
                  checked: !event.target.checked,
                  name: "enableAudienceBuilder",
                },
              })
            }
          />
        </Tooltip>
      </FormControl>
      <FormControl>
        <Tooltip title="If this is enabled, then old, 4.0 datalabs are created in the UI. Only enable this if there are compatibility issues.">
          <Checkbox
            checked={useLegacyDataLabs}
            disabled={isEditorDisabled}
            label="Create old Datalabs"
            name="useLegacyDataLabs"
            onChange={updateOrganizationFlags}
          />
        </Tooltip>
      </FormControl>
    </Stack>
  );
});

MediaDcrFeatures.displayName = "MediaDcrFeatures";

export default MediaDcrFeatures;
