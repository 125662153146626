import { z } from "zod";
import {
  MediaDataRoomOrganizationRole,
  type MediaDataRoomOrganizationRoleCardPresentationType,
} from "features/dataRoomCreation/models";

export interface MediaDataRoomOrganizationRoleTypeItem
  extends MediaDataRoomOrganizationRoleCardPresentationType {
  role: MediaDataRoomOrganizationRole;
  visible: boolean;
  testId: string;
}

export const MediaDataRoomCreationCurrentUserRoleStepSchema = z.object({
  organizationRole: z.nativeEnum(MediaDataRoomOrganizationRole, {
    message:
      "Please select your base role. You can adjust your permissions later.",
  }),
});

export type MediaDataRoomCreationCurrentUserRoleStepFormValues = z.infer<
  typeof MediaDataRoomCreationCurrentUserRoleStepSchema
>;
