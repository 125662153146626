import { DqIcon } from "@decentriq/components";
import { type AvailablePublisherFragment } from "@decentriq/graphql/dist/types";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { Fragment, memo } from "react";

type PublisherCardProps = AvailablePublisherFragment;

const PublisherCard = memo<PublisherCardProps>((publisherData) => {
  const { publisherLogo, publisherName } = publisherData;
  return (
    <Fragment>
      <Typography
        fontWeight={500}
        level="body-md"
        sx={{
          mb: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }}
        textAlign="center"
      >
        {publisherName}
      </Typography>
      {publisherLogo ? (
        <DqIcon icon={publisherLogo} iconProps={{ width: "100%" }} />
      ) : (
        <FontAwesomeIcon icon={faNewspaper} size="3x" />
      )}
    </Fragment>
  );
});

PublisherCard.displayName = "PublisherCard";

export default PublisherCard;
