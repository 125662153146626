import { gql } from "@apollo/client";
import { type Client, Compiler, proto, type Session } from "@decentriq/core";
import { type EnclaveSpecification } from "@decentriq/core/dist/types";
import {
  ActivationType as GqlActivationType,
  type ConsentlessAudience,
  CreateDatasetImportDocument,
  type DirectAudience,
  type MediaActivateConsentlessAudiencePayload,
  type MediaActivateDirectAudiencePayload,
  type MediaCalculateOverlapByAudiencePayload,
  type MediaCalculateOverlapInsightsPayload,
  type MediaCalculateStatisticsPayload,
  type MediaDeleteConsentlessAudiencePayload,
  type MediaDownloadConsentlessActivatedAudiencePayload,
  type MediaDownloadDirectActivatedAudiencePayload,
  type MediaGenerateConsentlessAudiencePayload,
  type MediaPublishDraftDataRoomPayload,
  type MediaRetrieveAudienceTypesPayload,
  type MediaRetrieveConsentlessActivatedAudiencesPayload,
  type MediaRetrieveConsentlessAudiencesPayload,
  type MediaRetrieveDataRoomPayload,
  type MediaRetrieveDirectActivatedAudiencesPayload,
  type MediaRetrieveDirectAudiencesPayload,
  type MediaRetrievePublishedDatasetsPayload,
  type MutationMediaActivateConsentlessAudienceArgs,
  type MutationMediaActivateDirectAudienceArgs,
  type MutationMediaDeleteConsentlessAudienceArgs,
  type MutationMediaDownloadActivatedConsentlessAudienceArgs,
  type MutationMediaDownloadActivatedDirectAudienceArgs,
  type MutationMediaGenerateConsentlessAudienceArgs,
  type MutationMediaPublishAdvertiserDatasetArgs,
  type MutationMediaPublishDraftDataRoomArgs,
  type MutationMediaPublishPublisherDatasetArgs,
  type MutationMediaUnpublishAdvertiserDatasetArgs,
  type MutationMediaUnpublishPublisherDatasetArgs,
  type MutationStoreActivatedDirectAudienceAsDatasetArgs,
  type QueryMediaCalculateOverlapByAudienceArgs,
  type QueryMediaCalculateOverlapInsightsArgs,
  type QueryMediaCalculateStatisticsArgs,
  type QueryMediaRetrieveAudienceTypesArgs,
  type QueryMediaRetrieveConsentlessActivatedAudiencesArgs,
  type QueryMediaRetrieveConsentlessAudiencesArgs,
  type QueryMediaRetrieveDataRoomArgs,
  type QueryMediaRetrieveDirectActivatedAudiencesArgs,
  type QueryMediaRetrieveDirectAudiencesArgs,
  type QueryMediaRetrievePublishedDatasetsArgs,
  type StoreDirectActivatedAudienceAsDatasetPayload,
} from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import JSZip from "jszip";
import * as forge from "node-forge";
import { parse as parseCsv, unparse as unparseCsv } from "papaparse";
import * as uuid from "uuid";
import { type ValidationConfig } from "validate-js";
import { type ApiCoreContextValue } from "contexts";
import { logWarning, parseMediaDataRoomError } from "utils";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";
import {
  LruCache,
  LruExpiringPromiseCache,
  LruPromiseCache,
} from "../LruCache";
import { sanitizeResolver } from "..";

// Set to true to enable console logging of what's going on.
const DEBUG = false;

type MediaDataRoomLatest = any;
type MediaAuxiliaryStateLatest = any;
type DirectActivationConfigLatest = any;

export const makeMediaPublishDraftDataRoomResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      {
        input: {
          activationType,
          advertiserUserEmails,
          activationDownloadByAdvertiser,
          activationDownloadByPublisher,
          activationDownloadByAgency,
          observerUserEmails,
          publisherUserEmails,
          agencyUserEmails,
          mainAdvertiserUserEmail,
          mainPublisherUserEmail,
          enableOverlapInsights,
          name,
        },
      }: MutationMediaPublishDraftDataRoomArgs,
      context: LocalResolverContext
    ): Promise<MediaPublishDraftDataRoomPayload> => {
      const gqlActivationType =
        activationType === undefined ? null : activationType;
      const mediaActivationType =
        gqlActivationTypeToMediaActivationType(gqlActivationType);
      const enclaveSpecifications =
        await retrieveLatestMediaEnclaveSpecificationsCached(client);
      const driverAttestationHash = calculateAttestationHash(
        enclaveSpecifications.driverEnclaveSpecification
      );
      const [authenticationRootCertificatePem, session] = await Promise.all([
        retrieveAuthenticationRootCertificatePemCached(client),
        sessionManager.get({ driverAttestationHash }),
      ]);

      const advertiserSchema =
        activationTypeToAdvertiserSchema(mediaActivationType);
      const advertiserValidation: ValidationConfig = {
        config: {
          columns: advertiserSchema.map((columnName) => {
            return {
              allowNull: true,
              formatType: "STRING",
              name: columnName,
            };
          }),
        },
        version: "v0",
      };

      const publisherSchema =
        activationTypeToPublisherSchema(mediaActivationType);
      const publisherValidation: ValidationConfig = {
        config: {
          columns: publisherSchema.map((columnName) => {
            return {
              allowNull: true,
              formatType: "STRING",
              name: columnName,
            };
          }),
        },
        version: "v0",
      };

      const request: media_request.MediaRequest = {
        publishDataRoom: {
          dataRoom: {
            v5: {
              activationType: mediaActivationType,
              advertiserEmails: advertiserUserEmails,
              advertiserSchema,
              advertiserValidation,
              agencyEmails: agencyUserEmails,
              authenticationRootCertificatePem,
              driverEnclaveSpecification:
                enclaveSpecifications.driverEnclaveSpecification,
              enableAuditLogRetrieval: true,
              enableDevComputations: true,
              enableDownloadByAdvertiser: activationDownloadByAdvertiser,
              enableDownloadByAgency: activationDownloadByAgency,
              enableDownloadByPublisher: activationDownloadByPublisher,
              enableOverlapInsights: enableOverlapInsights,
              id: uuid.v4(),
              mainAdvertiserEmail: mainAdvertiserUserEmail,
              mainPublisherEmail: mainPublisherUserEmail,
              name: name,
              // TODO
              observerEmails: observerUserEmails,
              publisherEmails: publisherUserEmails,
              publisherSchema,
              publisherValidation,
              pythonEnclaveSpecification:
                enclaveSpecifications.pythonEnclaveSpecification,
            },
          },
          requirePassword: false,
          showOrganizationLogo: false, // TODO, set this according to the dcr settings
        },
      };
      const response = await session.sendMediaRequest(request, DEBUG);
      if ("publishDataRoom" in response) {
        return {
          driverAttestationHash,
          id: response.publishDataRoom.dataRoomId,
        };
      } else {
        throw new Error("Expected publishDataRoom response");
      }
    }
  );

export const makePublishMediaAdvertiserDatasetResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      {
        input: {
          dataRoomId,
          driverAttestationHash,
          datasetHash,
          encryptionKey: rawEncryptionKey,
        },
      }: MutationMediaPublishAdvertiserDatasetArgs,
      context: LocalResolverContext
    ): Promise<void> => {
      const [scopeId, session] = await Promise.all([
        client.ensureDcrDataScope(dataRoomId),
        sessionManager.get({
          driverAttestationHash,
        }),
      ]);
      const encryptionKey: Uint8Array =
        store.popStrict(rawEncryptionKey).material;
      const request: media_request.MediaRequest = {
        publishAdvertiserDataset: {
          dataRoomIdHex: dataRoomId,
          datasetHashHex: datasetHash,
          encryptionKeyHex: forge.util.binary.hex.encode(encryptionKey),
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaRequest(request, DEBUG);
      if ("publishAdvertiserDataset" in response) {
        cache.publishedDatasets.evict(dataRoomId);
        context.client.writeFragment({
          data: { advertiserDatasetHash: datasetHash },
          fragment: gql`
            fragment PublishedMediaDataRoomAdvertiserDatasetHash on PublishedMediaDataRoom {
              advertiserDatasetHash
            }
          `,
          id: context.client.cache.identify({
            __typename: "PublishedMediaDataRoom",
            id: dataRoomId,
          }),
        });
        return;
      } else {
        throw new Error("Expected publishAdvertiserDataset response");
      }
    }
  );

export const makeMediaPublishPublisherDatasetResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      {
        input: {
          dataRoomId,
          datasetHash,
          driverAttestationHash,
          encryptionKey: rawEncryptionKey,
        },
      }: MutationMediaPublishPublisherDatasetArgs,
      context: LocalResolverContext
    ): Promise<void> => {
      const [scopeId, session] = await Promise.all([
        client.ensureDcrDataScope(dataRoomId),
        sessionManager.get({
          driverAttestationHash,
        }),
      ]);
      const encryptionKey: Uint8Array =
        store.popStrict(rawEncryptionKey).material;
      const request: media_request.MediaRequest = {
        publishPublisherDataset: {
          dataRoomIdHex: dataRoomId,
          datasetHashHex: datasetHash,
          encryptionKeyHex: forge.util.binary.hex.encode(encryptionKey),
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaRequest(request, DEBUG);
      if ("publishPublisherDataset" in response) {
        cache.publishedDatasets.evict(dataRoomId);
        context.client.writeFragment({
          data: { publisherDatasetHash: datasetHash },
          fragment: gql`
            fragment PublishedMediaDataRoomPublisherDatasetHash on PublishedMediaDataRoom {
              publisherDatasetHash
            }
          `,
          id: context.client.cache.identify({
            __typename: "PublishedMediaDataRoom",
            id: dataRoomId,
          }),
        });
        return;
      } else {
        throw new Error("Expected publishPublisherDataset response");
      }
    }
  );

export const makeMediaUnpublishAdvertiserDatasetResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaUnpublishAdvertiserDatasetArgs,
      context: LocalResolverContext
    ): Promise<void> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const [session, dataRoom, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        retrieveMediaDataRoom(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      switch (dataRoom.activationType) {
        case "direct": {
          if (key != null) {
            optimisticAudienceCache.withDirectAdvertiser(
              key,
              client.userEmail,
              async (state) =>
                casModify(
                  state,
                  async (state) =>
                    updateDirectAdvertiserAudiences(session, key, state),
                  (_) => []
                )
            );
          }
          const request: media_request.MediaRequest = {
            unpublishDirectActivationConfig: {
              dataRoomIdHex: dataRoomId,
            },
          };
          const response = await session.sendMediaRequest(request, DEBUG);
          if ("unpublishDirectActivationConfig" in response) {
            cache.directActivationConfig.evict(dataRoomId);
          } else {
            throw new Error(
              "Expected unpublishDirectActivationConfig response"
            );
          }
          break;
        }
        case "consentless": {
          if (key != null) {
            optimisticAudienceCache.withConsentlessAdvertiser(
              key,
              client.userEmail,
              async (state) =>
                casModify(
                  state,
                  async (state) =>
                    updateConsentlessAdvertiserAudiences(session, key, state),
                  (_) => []
                )
            );
          }
          break;
        }
      }

      {
        const request: media_request.MediaRequest = {
          unpublishAdvertiserDataset: {
            dataRoomIdHex: dataRoomId,
          },
        };
        const response = await session.sendMediaRequest(request, DEBUG);
        if ("unpublishAdvertiserDataset" in response) {
          cache.publishedDatasets.evict(dataRoomId);
          context.client.writeFragment({
            data: { advertiserDatasetHash: null },
            fragment: gql`
              fragment PublishedMediaDataRoomAdvertiserDatasetHash on PublishedMediaDataRoom {
                advertiserDatasetHash
              }
            `,
            id: context.client.cache.identify({
              __typename: "PublishedMediaDataRoom",
              id: input.input.dataRoomId,
            }),
          });
          return;
        } else {
          throw new Error("Expected unpublishAdvertiserDataset response");
        }
      }
    }
  );

export const makeMediaUnpublishPublisherDatasetResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaUnpublishPublisherDatasetArgs,
      context: LocalResolverContext
    ): Promise<void> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const [session, dataRoom, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        retrieveMediaDataRoom(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      if (key != null) {
        switch (dataRoom.activationType) {
          case "direct": {
            optimisticAudienceCache.withDirectPublisher(
              key,
              client.userEmail,
              async (state) =>
                casModify(
                  state,
                  async (state) =>
                    updateDirectPublisherAudiences(session, key, state),
                  (_) => []
                )
            );
            break;
          }
          case "consentless": {
            optimisticAudienceCache.withConsentlessPublisher(
              key,
              client.userEmail,
              async (state) =>
                casModify(
                  state,
                  async (state) =>
                    updateConsentlessPublisherAudiences(session, key, state),
                  (_) => []
                )
            );
            break;
          }
        }
      }

      {
        const request: media_request.MediaRequest = {
          unpublishPublisherDataset: {
            dataRoomIdHex: dataRoomId,
          },
        };
        const response = await session.sendMediaRequest(request, DEBUG);
        if ("unpublishPublisherDataset" in response) {
          cache.publishedDatasets.evict(dataRoomId);
          context.client.writeFragment({
            data: { publisherDatasetHash: null },
            fragment: gql`
              fragment PublishedMediaDataRoomPublisherDatasetHash on PublishedMediaDataRoom {
                publisherDatasetHash
              }
            `,
            id: context.client.cache.identify({
              __typename: "PublishedMediaDataRoom",
              id: input.input.dataRoomId,
            }),
          });
          return;
        } else {
          throw new Error("Expected unpublishPublisherDataset response");
        }
      }
    }
  );

export const makeMediaGenerateConsentlessAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaGenerateConsentlessAudienceArgs
    ): Promise<MediaGenerateConsentlessAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, precision, audienceTypes } =
        input.input;

      const [session, dataRoom, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        retrieveMediaDataRoomCached(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      if (key == null) {
        throw new Error(
          "Generating a new audience requires both the publisher and advertiser dataset uploaded"
        );
      }

      const newAudiences: media_request.ConsentlessAdvertiserAudience[] = [];
      for (const audienceType of audienceTypes) {
        const audienceId = uuid.v4();
        newAudiences.push({
          activated: false,
          audienceType,
          downloaded: false,
          id: audienceId,
          precision,
        });
      }

      if (
        [...dataRoom.advertiserEmails, ...dataRoom.agencyEmails].includes(
          client.userEmail
        )
      ) {
        await optimisticAudienceCache.withConsentlessAdvertiser(
          key,
          client.userEmail,
          async (state) =>
            casModify(
              state,
              async (state) =>
                updateConsentlessAdvertiserAudiences(session, key, state),
              (state) => {
                state.push(...newAudiences);
                return state;
              }
            )
        );
      } else {
        throw new Error(
          "Consentless audiences can only be created by the advertiser/agency"
        );
      }

      return {
        audienceIds: newAudiences.map((audience) => audience.id),
      };
    }
  );
};

// Computed value caches are invalidated on the input dataset hashes. Races can still cause misaligment, as
// we don't yet have client-side dataset pinning for compute executions.
// Individual caches may be invalidated by more values depending on the type of cache.
type DataRoomComputeCacheKey = {
  dataRoomId: string;
  publisherDatasetHash: string;
  advertiserDatasetHash: string;
};

type IndexedState<A> = {
  index: number;
  state: A;
};

type AudienceState = {
  consentlessPublisher: Map<
    string,
    IndexedState<media_request.ConsentlessPublisherAudience[]>
  >;
  consentlessAdvertiser: Map<
    string,
    IndexedState<media_request.ConsentlessAdvertiserAudience[]>
  >;
  directPublisher: Map<
    string,
    IndexedState<media_request.DirectPublisherAudience[]>
  >;
  directAdvertiser: Map<
    string,
    IndexedState<media_request.DirectAdvertiserAudience[]>
  >;
};

const cache = {
  audiences: new LruCache<DataRoomComputeCacheKey, AudienceState>(5),
  directActivationConfig: new LruExpiringPromiseCache<
    string,
    DirectActivationConfigLatest | null
  >(5, 5 * 60 * 1000),
  directActivationResult: new LruExpiringPromiseCache<
    DataRoomComputeCacheKey & { audienceType: string },
    Uint8Array
  >(5, 5 * 60 * 1000),
  mediaDataRoom: new LruPromiseCache<string, MediaDataRoomLatest>(5),
  overlapBasicResult: new LruExpiringPromiseCache<
    DataRoomComputeCacheKey,
    Uint8Array
  >(5, 5 * 60 * 1000),
  overlapInsightsResult: new LruExpiringPromiseCache<
    DataRoomComputeCacheKey & { audienceTypes: string[] },
    Uint8Array
  >(5, 5 * 60 * 1000),
  publishedDatasets: new LruExpiringPromiseCache<
    string,
    MediaRetrievePublishedDatasetsPayload
  >(5, 5 * 60 * 1000),
};

const optimisticAudienceCache = (function () {
  function defaultState(): AudienceState {
    return {
      consentlessAdvertiser: new Map(),
      consentlessPublisher: new Map(),
      directAdvertiser: new Map(),
      directPublisher: new Map(),
    };
  }

  return {
    withConsentlessAdvertiser: async function (
      key: DataRoomComputeCacheKey,
      user: string,
      f: (
        state: IndexedState<media_request.ConsentlessAdvertiserAudience[]>
      ) => Promise<IndexedState<media_request.ConsentlessAdvertiserAudience[]>>
    ): Promise<void> {
      const state = cache.audiences.get(key) ?? defaultState();
      const newState = await f(
        state.consentlessAdvertiser.get(user) ?? { index: 0, state: [] }
      );
      state.consentlessAdvertiser.set(user, newState);
      cache.audiences.put(key, state);
    },

    withConsentlessPublisher: async function (
      key: DataRoomComputeCacheKey,
      user: string,
      f: (
        state: IndexedState<media_request.ConsentlessPublisherAudience[]>
      ) => Promise<IndexedState<media_request.ConsentlessPublisherAudience[]>>
    ): Promise<void> {
      const state = cache.audiences.get(key) ?? defaultState();
      const newState = await f(
        state.consentlessPublisher.get(user) ?? { index: 0, state: [] }
      );
      state.consentlessPublisher.set(user, newState);
      cache.audiences.put(key, state);
    },

    withDirectAdvertiser: async function (
      key: DataRoomComputeCacheKey,
      user: string,
      f: (
        state: IndexedState<media_request.DirectAdvertiserAudience[]>
      ) => Promise<IndexedState<media_request.DirectAdvertiserAudience[]>>
    ): Promise<void> {
      const state = cache.audiences.get(key) ?? defaultState();
      const newState = await f(
        state.directAdvertiser.get(user) ?? { index: 0, state: [] }
      );
      state.directAdvertiser.set(user, newState);
      cache.audiences.put(key, state);
    },

    withDirectPublisher: async function (
      key: DataRoomComputeCacheKey,
      user: string,
      f: (
        state: IndexedState<media_request.DirectPublisherAudience[]>
      ) => Promise<IndexedState<media_request.DirectPublisherAudience[]>>
    ): Promise<void> {
      const state = cache.audiences.get(key) ?? defaultState();
      const newState = await f(
        state.directPublisher.get(user) ?? { index: 0, state: [] }
      );
      state.directPublisher.set(user, newState);
      cache.audiences.put(key, state);
    },
  };
})();

export const makeMediaDeleteConsentlessAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaDeleteConsentlessAudienceArgs
    ): Promise<MediaDeleteConsentlessAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, audienceId } = input.input;

      const [session, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      // Only the advertiser can "delete" the audience, however this will potentially leave a residual state
      // (whether the audience was downloaded or not) on the publisher side. That residual can only be
      // deleted by the publisher.
      if (key != null) {
        await optimisticAudienceCache.withConsentlessAdvertiser(
          key,
          client.userEmail,
          async (state) =>
            casModify(
              state,
              async (state) =>
                updateConsentlessAdvertiserAudiences(session, key, state),
              (state) => {
                let deleted = false;
                for (
                  let audienceIndex = 0;
                  audienceIndex < state.length;
                  audienceIndex++
                ) {
                  if (state[audienceIndex].id === audienceId) {
                    state.splice(audienceIndex, 1);
                    deleted = true;
                  }
                }
                if (!deleted) {
                  throw new Error(
                    `Audience with id ${audienceId} cannot be deleted by user ${client.userEmail}`
                  );
                }
                return state;
              }
            )
        );
      }

      return {
        audienceId,
      };
    }
  );
};

async function casModify<S>(
  { index, state }: IndexedState<S>,
  update: (state: IndexedState<S>) => Promise<[IndexedState<S>, boolean]>,
  modify: (state: S) => S
): Promise<IndexedState<S>> {
  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
  while (true) {
    state = modify(state);
    let success = false;
    [{ state, index }, success] = await update({ index, state });
    if (success) {
      break;
    }
  }
  return { index, state };
}

async function updateConsentlessPublisherAudiences(
  session: Session,
  key: DataRoomComputeCacheKey,
  {
    index,
    state: audiences,
  }: IndexedState<media_request.ConsentlessPublisherAudience[]>
): Promise<
  [IndexedState<media_request.ConsentlessPublisherAudience[]>, boolean]
> {
  const response = await updateAuxiliaryState(session, {
    dataRoomIdHex: key.dataRoomId,
    index,
    state: {
      v2: {
        activationState: {
          consentlessPublisher: {
            audiences,
          },
        },
        pin: {
          datasetAdvertiserHashHex: key.advertiserDatasetHash,
          datasetPublisherHashHex: key.publisherDatasetHash,
        },
      },
    },
  });
  index = response.index;

  if (response.state != null) {
    const state = await convertAuxiliaryStateToLatest(response.state);
    if (
      "consentlessPublisher" in state.activationState &&
      isAuxiliaryPinUpToDate(key, state.pin)
    ) {
      audiences = state.activationState.consentlessPublisher.audiences;
    } else {
      audiences = [];
    }
  } else {
    audiences = [];
  }

  return [{ index, state: audiences }, response.success];
}

async function convertAuxiliaryStateToLatest(
  state: media_request.MediaAuxiliaryState
): Promise<MediaAuxiliaryStateLatest> {
  const compiler = await Compiler.initialize();
  return compiler.convertMediaAuxiliaryStateAnyToLatest(state);
}

async function updateDirectPublisherAudiences(
  session: Session,
  key: DataRoomComputeCacheKey,
  {
    index,
    state: audiences,
  }: IndexedState<media_request.DirectPublisherAudience[]>
): Promise<[IndexedState<media_request.DirectPublisherAudience[]>, boolean]> {
  const response = await updateAuxiliaryState(session, {
    dataRoomIdHex: key.dataRoomId,
    index,
    state: {
      v2: {
        activationState: {
          directPublisher: {
            audiences,
          },
        },
        pin: {
          datasetAdvertiserHashHex: key.advertiserDatasetHash,
          datasetPublisherHashHex: key.publisherDatasetHash,
        },
      },
    },
  });
  index = response.index;
  if (response.state != null) {
    const state = await convertAuxiliaryStateToLatest(response.state);
    if ("directPublisher" in state.activationState) {
      audiences = state.activationState.directPublisher.audiences;
    } else {
      audiences = [];
    }
  } else {
    audiences = [];
  }
  return [{ index, state: audiences }, response.success];
}

async function updateDirectAdvertiserAudiences(
  session: Session,
  key: DataRoomComputeCacheKey,
  {
    index,
    state: audiences,
  }: IndexedState<media_request.DirectAdvertiserAudience[]>
): Promise<[IndexedState<media_request.DirectAdvertiserAudience[]>, boolean]> {
  const response = await updateAuxiliaryState(session, {
    dataRoomIdHex: key.dataRoomId,
    index,
    state: {
      v2: {
        activationState: {
          directAdvertiser: {
            audiences,
          },
        },
        pin: {
          datasetAdvertiserHashHex: key.advertiserDatasetHash,
          datasetPublisherHashHex: key.publisherDatasetHash,
        },
      },
    },
  });
  index = response.index;
  if (response.state != null) {
    const state = await convertAuxiliaryStateToLatest(response.state);
    if ("directAdvertiser" in state.activationState) {
      audiences = state.activationState.directAdvertiser.audiences;
    } else {
      audiences = [];
    }
  } else {
    audiences = [];
  }
  return [{ index, state: audiences }, response.success];
}

async function updateConsentlessAdvertiserAudiences(
  session: Session,
  key: DataRoomComputeCacheKey,
  {
    index,
    state: audiences,
  }: IndexedState<media_request.ConsentlessAdvertiserAudience[]>
): Promise<
  [IndexedState<media_request.ConsentlessAdvertiserAudience[]>, boolean]
> {
  const response = await updateAuxiliaryState(session, {
    dataRoomIdHex: key.dataRoomId,
    index,
    state: {
      v0: {
        activationState: {
          consentlessAdvertiser: {
            audiences,
          },
        },
        pin: {
          datasetAdvertiserHashHex: key.advertiserDatasetHash,
          datasetPublisherHashHex: key.publisherDatasetHash,
        },
      },
    },
  });
  index = response.index;
  if (response.state != null) {
    const state = await convertAuxiliaryStateToLatest(response.state);
    if (
      "consentlessAdvertiser" in state.activationState &&
      isAuxiliaryPinUpToDate(key, state.pin)
    ) {
      audiences = state.activationState.consentlessAdvertiser.audiences;
    } else {
      audiences = [];
    }
  } else {
    audiences = [];
  }
  return [{ index, state: audiences }, response.success];
}

async function updateAuxiliaryState(
  session: Session,
  state: Extract<
    media_request.MediaRequest,
    { updateAuxiliaryState: any }
  >["updateAuxiliaryState"]
): Promise<
  Extract<
    media_response.MediaResponse,
    { updateAuxiliaryState: any }
  >["updateAuxiliaryState"]
> {
  const request: media_request.MediaRequest = {
    updateAuxiliaryState: state,
  };

  const response = await session.sendMediaRequest(request, DEBUG);
  if ("updateAuxiliaryState" in response) {
    return response.updateAuxiliaryState;
  } else {
    throw new Error("Expected updateAuxiliaryState response");
  }
}

export const makeMediaActivateConsentlessAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaActivateConsentlessAudienceArgs
    ): Promise<MediaActivateConsentlessAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, audienceId } = input.input;

      const [session, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      if (key == null) {
        throw new Error(
          "Activating an audience requires both the publisher and advertiser dataset uploaded"
        );
      }

      await optimisticAudienceCache.withConsentlessAdvertiser(
        key,
        client.userEmail,
        async (state) =>
          casModify(
            state,
            async (state) =>
              updateConsentlessAdvertiserAudiences(session, key, state),
            (state) => {
              let activated = false;
              for (const audience of state) {
                if (audience.id === audienceId) {
                  audience.activated = true;
                  activated = true;
                }
              }
              if (!activated) {
                throw new Error(
                  `Audience with id ${audienceId} cannot be activated by user ${client.userEmail}`
                );
              }
              return state;
            }
          )
      );

      return {
        audienceId,
      };
    }
  );
};

async function retrieveDirectActivationConfig(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<DirectActivationConfigLatest | null> {
  try {
    const [scopeId, session] = await Promise.all([
      client.ensureDcrDataScope(dataRoomId),
      sessionManager.get({ driverAttestationHash }),
    ]);
    const request: media_request.MediaRequest = {
      submitRetrieveDirectActivationConfig: {
        dataRoomIdHex: dataRoomId,
        scopeIdHex: scopeId,
      },
    };
    const response = await session.sendMediaRequest(request, DEBUG);
    if ("submitRetrieveDirectActivationConfig" in response) {
      const directActivationConfigRaw = await session.getComputationResult(
        {
          computeNodeId:
            response.submitRetrieveDirectActivationConfig.computeNodeName,
          jobId: response.submitRetrieveDirectActivationConfig.jobIdHex,
        },
        {
          interval: 1,
          timeout: undefined,
        }
      );
      if (directActivationConfigRaw.length === 0) {
        return null;
      } else {
        const directActivationConfig: any = null!;
        return await convertDirectActivationConfigToLatest(
          directActivationConfig
        );
      }
    } else {
      throw new Error("Expected submitRetrieveDirectActivationConfig response");
    }
  } catch (error) {
    throw parseMediaDataRoomError(error);
  }
}

async function retrieveDirectActivationConfigCached(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<DirectActivationConfigLatest | null> {
  const cached = cache.directActivationConfig.get(dataRoomId);
  if (cached !== undefined) {
    return cached;
  } else {
    const promise = retrieveDirectActivationConfig(
      client,
      sessionManager,
      dataRoomId,
      driverAttestationHash
    );
    cache.directActivationConfig.put(dataRoomId, promise);
    return promise;
  }
}
async function storeDirectActivationConfig(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string,
  configLatest: DirectActivationConfigLatest
): Promise<void> {
  const config: direct_activation_config.DirectActivationConfig = {
    v1: configLatest,
  };
  const configRaw = new TextEncoder().encode(JSON.stringify(config));

  const encryptionKey = new Key();
  const [scopeId, manifestHash, session] = await Promise.all([
    client.ensureDcrDataScope(dataRoomId),
    client.uploadDataset(
      configRaw,
      encryptionKey,
      "direct_activation_config.json"
    ),
    sessionManager.get({ driverAttestationHash }),
  ]);
  const request: media_request.MediaRequest = {
    publishDirectActivationConfig: {
      dataRoomIdHex: dataRoomId,
      datasetHashHex: manifestHash,
      encryptionKeyHex: forge.util.binary.hex.encode(encryptionKey.material),
      scopeIdHex: scopeId,
    },
  };
  const response = await session.sendMediaRequest(request, DEBUG);
  if ("publishDirectActivationConfig" in response) {
    cache.directActivationConfig.evict(dataRoomId);
    return;
  } else {
    throw new Error("Expected publishDirectActivationConfig response");
  }
}

export const makeMediaActivateDirectAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaActivateDirectAudienceArgs
    ): Promise<MediaActivateDirectAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, audienceTypes } = input.input;

      let config = await retrieveDirectActivationConfigCached(
        client,
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );

      let addedAudienceTypes = [];
      if (config == null) {
        config = {
          audienceTypes,
        };
        addedAudienceTypes = audienceTypes;
      } else {
        const existingAudienceTypesSet = new Set(config.audienceTypes);
        for (const audienceType of audienceTypes) {
          if (!existingAudienceTypesSet.has(audienceType)) {
            config.audienceTypes.push(audienceType);
            addedAudienceTypes.push(audienceType);
          }
        }
      }

      // TODO this should be more atomic, we should probably introduce indexing/CASing or some kind of pinning for uploaded datasets as well.
      await storeDirectActivationConfig(
        client,
        sessionManager,
        dataRoomId,
        driverAttestationHash,
        config
      );

      return {
        audienceTypes: addedAudienceTypes,
      };
    }
  );
};

async function convertDirectActivationConfigToLatest(
  config: direct_activation_config.DirectActivationConfig
): Promise<DirectActivationConfigLatest> {
  const compiler = await Compiler.initialize();
  return compiler.convertDirectActivationConfigAnyToLatest(config);
}

const calculateDirectActivationCached = (() => {
  return async function (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    key: DataRoomComputeCacheKey,
    driverAttestationHash: string,
    audienceType: string,
    expectedAudienceTypes: string[]
  ): Promise<string> {
    // First check cache
    const cached = cache.directActivationResult.get({ ...key, audienceType });

    if (cached !== undefined) {
      const rawZip = await cached;

      const audienceCsv = await getAudienceCsvFromRawZip(
        rawZip,
        audienceType,
        expectedAudienceTypes
      );
      if (audienceCsv != null) {
        return audienceCsv;
      }
    }

    // It either has not been calculated yet, or the expected audienceType is not in the result.
    // We overwrite the cache.
    const promise = calculateDirectActivation(
      client,
      sessionManager,
      key,
      driverAttestationHash
    );
    cache.directActivationResult.put({ ...key, audienceType }, promise);
    const rawZip = await promise;
    const audienceCsv = await getAudienceCsvFromRawZip(
      rawZip,
      audienceType,
      expectedAudienceTypes
    );
    if (audienceCsv != null) {
      return audienceCsv;
    } else {
      // It's *still* not in the result, this is an error.
      throw new Error(
        `Could not find audience type CSV for "${audienceType}" in result`
      );
    }
  };
})();

async function calculateDirectActivation(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<Uint8Array> {
  try {
    const [scopeId, session] = await Promise.all([
      client.ensureDcrDataScope(key.dataRoomId),
      sessionManager.get({
        driverAttestationHash,
      }),
      retrieveAudienceTypes(client, sessionManager, key, driverAttestationHash),
    ]);

    const request: media_request.MediaRequest = {
      calculateDirectActivation: {
        dataRoomIdHex: key.dataRoomId,
        scopeIdHex: scopeId,
      },
    };

    const response = await session.sendMediaRequest(request, DEBUG);

    if ("calculateDirectActivation" in response) {
      return await session.getComputationResult(
        {
          computeNodeId: response.calculateDirectActivation.computeNodeName,
          jobId: response.calculateDirectActivation.jobIdHex,
        },
        {
          interval: 1,
          timeout: undefined,
        }
      );
    } else {
      throw new Error("Expected calculateDirectActivation response");
    }
  } catch (error) {
    throw parseMediaDataRoomError(error);
  }
}

export const makeMediaDownloadActivatedDirectAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaDownloadActivatedDirectAudienceArgs
    ): Promise<MediaDownloadDirectActivatedAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, audienceType } = input.input;
      const [session, dataRoom, key] = await Promise.all([
        sessionManager.get({
          driverAttestationHash,
        }),
        retrieveMediaDataRoomCached(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);

      if (key == null) {
        throw new Error(
          "Downloading an audience requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audienceTypes = await retrieveAudienceTypes(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      const audienceCsv = await calculateDirectActivationCached(
        client,
        sessionManager,
        key,
        driverAttestationHash,
        audienceType,
        audienceTypes
      );

      await setAudienceFileToDownloaded(
        client,
        session,
        key,
        input.input.audienceType,
        dataRoom
      );

      return {
        audienceCsv: store.push(audienceCsv),
        audienceType,
      };
    }
  );
};

export const makeMediaStoreActivatedDirectAudiencesAsDatasetResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationStoreActivatedDirectAudienceAsDatasetArgs,
      context: LocalResolverContext
    ): Promise<StoreDirectActivatedAudienceAsDatasetPayload> => {
      const { dataRoomId, driverAttestationHash, audienceType } = input.input;
      const [session, key, dataRoom] = await Promise.all([
        sessionManager.get({
          driverAttestationHash,
        }),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        retrieveMediaDataRoomCached(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);
      if (key == null) {
        throw new Error(
          "Downloading an audience requires both the publisher and advertiser dataset uploaded"
        );
      }

      const audienceFile = getAudienceFile(audienceType);

      const result = await context.client.mutate({
        mutation: CreateDatasetImportDocument,
        variables: {
          input: {
            compute: {
              computeNodeId: "direct_activation",
              dataRoomId,
              driverAttestationHash,
              importFileWithName: audienceFile,
              isHighLevelNode: false,
              renameFileTo: `${dataRoom.name} - ${audienceType}.csv`,
              shouldImportAllFiles: false,
              shouldImportAsRaw: false,
            },
            datasetName: dataRoom.name, // will be overridden
          },
        },
      });

      await setAudienceFileToDownloaded(
        client,
        session,
        key,
        input.input.audienceType,
        dataRoom
      );

      return {
        audienceType,
        createImportPayload: result.data!.createDatasetImport,
      };
    }
  );
};

async function setAudienceFileToDownloaded(
  client: Client,
  session: Session,
  key: DataRoomComputeCacheKey,
  audienceType: string,
  dataRoom: MediaDataRoomLatest
): Promise<void> {
  if (
    [...dataRoom.advertiserEmails, ...dataRoom.agencyEmails].includes(
      client.userEmail
    )
  ) {
    await optimisticAudienceCache.withDirectAdvertiser(
      key,
      client.userEmail,
      async (state) =>
        casModify(
          state,
          async (state) => updateDirectAdvertiserAudiences(session, key, state),
          (state) => {
            const existing = state.find(
              (audience) => audience.audienceType === audienceType
            );
            if (existing === undefined) {
              state.push({
                audienceType,
                downloaded: true,
              });
            } else {
              existing.downloaded = true;
            }
            return state;
          }
        )
    );
  } else if (dataRoom.publisherEmails.includes(client.userEmail)) {
    await optimisticAudienceCache.withDirectPublisher(
      key,
      client.userEmail,
      async (state) =>
        casModify(
          state,
          async (state) => updateDirectPublisherAudiences(session, key, state),
          (state) => {
            const existing = state.find(
              (audience) => audience.audienceType === audienceType
            );
            if (existing === undefined) {
              state.push({
                audienceType: audienceType,
                downloaded: true,
              });
            } else {
              existing.downloaded = true;
            }
            return state;
          }
        )
    );
  } else {
    throw new Error(
      "Cannot determine whether " +
        client.userEmail +
        " is a publisher or advertiser/agency"
    );
  }
}

export const makeMediaDownloadActivatedConsentlessAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) => {
  return sanitizeResolver(
    async (
      _obj: any,
      input: MutationMediaDownloadActivatedConsentlessAudienceArgs
    ): Promise<MediaDownloadConsentlessActivatedAudiencePayload> => {
      const { dataRoomId, driverAttestationHash, audienceId } = input.input;
      const [session, dataRoom, key] = await Promise.all([
        sessionManager.get({ driverAttestationHash }),
        retrieveMediaDataRoomCached(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
        getDataRoomComputeCacheKey(
          sessionManager,
          dataRoomId,
          driverAttestationHash
        ),
      ]);
      if (key == null) {
        throw new Error(
          "Downloading an audience requires both the publisher and advertiser dataset uploaded"
        );
      }
      const [audienceTypes, [advertiserAudiences]] = await Promise.all([
        retrieveAudienceTypes(
          client,
          sessionManager,
          key,
          driverAttestationHash
        ),
        retrieveConsentlessAudiencesFromEnclave(
          sessionManager,
          key,
          driverAttestationHash
        ),
      ]);
      const results = await calculateOverlapInsightsCached(
        client,
        sessionManager,
        key,
        driverAttestationHash,
        audienceTypes // TODO: chunk up for large lists of audiences
      );

      let audienceAdvertiser = null;
      for (const [_, audiences] of advertiserAudiences) {
        audienceAdvertiser = audiences.find(
          (audience) => audience.id === input.input.audienceId
        );
        if (audienceAdvertiser != undefined) {
          break;
        }
      }
      if (audienceAdvertiser == undefined) {
        throw new Error(`Audience with id ${input.input.audienceId} not found`);
      }

      const audienceCsv = await getAudienceCsvFromRawZip(
        results,
        audienceAdvertiser.audienceType,
        audienceTypes
      );
      if (audienceCsv == null) {
        throw new Error(
          `Could not find audience type CSV for "${audienceAdvertiser.audienceType}" in result`
        );
      }

      const csv = parseCsv(audienceCsv, {
        delimiter: ",",
        skipEmptyLines: true,
      });
      if (csv.errors.length > 0) {
        throw new Error(
          `Could not parse overlap insights for audience type ${
            audienceAdvertiser.audienceType
          } as CSV: ${csv.errors
            .map((error) => "Line " + error.row + ": " + error.message)
            .join("\n")}`
        );
      }
      const segmentsCsvData = [];
      // Implicit schema: segment base_propensity  overlap_propensity  net_propensity  cumulative_addressable segment_count
      for (const row of csv.data) {
        if (!Array.isArray(row)) {
          throw new Error(`Expected an array row in insights CSV, got ${row}`);
        }
        if (row.length < 6) {
          throw new Error(
            `Expected at least 6 columns in row in insights CSV, got ${row}`
          );
        }
        const cumulative = parseFloat(row[4]);
        segmentsCsvData.push([row[0]]);
        if (cumulative >= audienceAdvertiser.precision) {
          break;
        }
      }

      const segmentsCsv = unparseCsv(segmentsCsvData, {
        header: false,
        newline: "\n",
      });

      if (
        [...dataRoom.advertiserEmails, ...dataRoom.agencyEmails].includes(
          client.userEmail
        )
      ) {
        await optimisticAudienceCache.withConsentlessAdvertiser(
          key,
          client.userEmail,
          async (state) =>
            casModify(
              state,
              async (state) =>
                updateConsentlessAdvertiserAudiences(session, key, state),
              (state) => {
                const existing = state.find(
                  (audience) => audience.id === input.input.audienceId
                );
                if (existing === undefined) {
                  // TODO: revamp the auxiliary state to the "merge" model with declared deletions/updates.
                  // throw new Error(
                  //   "Cannot find consentless audience definition while downloading as advertiser/agency"
                  // );
                  logWarning(
                    `Cannot update 'downloaded' state of audience ${input.input.audienceId} as we don't own it`
                  );
                } else {
                  existing.downloaded = true;
                }
                return state;
              }
            )
        );
      } else if (dataRoom.publisherEmails.includes(client.userEmail)) {
        await optimisticAudienceCache.withConsentlessPublisher(
          key,
          client.userEmail,
          async (state) =>
            casModify(
              state,
              async (state) =>
                updateConsentlessPublisherAudiences(session, key, state),
              (state) => {
                const existing = state.find(
                  (audience) => audience.id === input.input.audienceId
                );
                if (existing === undefined) {
                  state.push({
                    downloaded: true,
                    id: input.input.audienceId,
                  });
                } else {
                  existing.downloaded = true;
                }
                return state;
              }
            )
        );
      } else {
        throw new Error(
          "Cannot determine whether " +
            client.userEmail +
            " is a publisher or advertiser/agency"
        );
      }

      const storeReference = store.push(segmentsCsv);

      return {
        audienceCsv: storeReference,
        audienceId,
      };
    }
  );
};

// Query
export const makeMediaCalculateOverlapByAudienceResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaCalculateOverlapByAudienceArgs
    ): Promise<MediaCalculateOverlapByAudiencePayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );

      if (key == null) {
        throw new Error(
          "Calculating the overlap requires both the publisher and advertiser dataset uploaded"
        );
      }

      const zipRaw = await calculateOverlapBasicCached(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      const zip = await JSZip.loadAsync(zipRaw);
      const overlapCsv = zip.file("overlap.csv");
      if (overlapCsv == null) {
        throw new Error(`Could not find "overlap.csv" in result`);
      }
      const csvRaw = await overlapCsv.async("string");
      const reference = store.push(csvRaw);
      return {
        overlapCsv: reference,
      };
    }
  );

export const makeMediaRetrievePublishedDatasetsResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrievePublishedDatasetsArgs
    ): Promise<MediaRetrievePublishedDatasetsPayload> => {
      const result = await retrievePublishedDatasetsCached(
        sessionManager,
        input.input.dataRoomId,
        input.input.driverAttestationHash
      );
      return result;
    }
  );

export const makeMediaCalculateStatisticsResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaCalculateStatisticsArgs
    ): Promise<MediaCalculateStatisticsPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Calculating the overlap statistics requires both the publisher and advertiser dataset uploaded"
        );
      }
      const zipRaw = await calculateOverlapBasicCached(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      const zip = await JSZip.loadAsync(zipRaw);
      const statisticsJson = zip.file("statistics.json");
      if (statisticsJson == null) {
        throw new Error(`Could not find "statistics.json" in result`);
      }
      const json = JSON.parse(await statisticsJson.async("string"));
      return {
        __typename: "MediaCalculateStatisticsPayload",
        advertiserNumberOfAudiences:
          json["advertiser_number_of_audience_types"],
        advertiserSize: json["advertiser_size"],
        publisherNumberOfSegments: json["publisher_number_of_segments"],
      };
    }
  );

export const makeMediaRetrieveAudienceTypes = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveAudienceTypesArgs
    ): Promise<MediaRetrieveAudienceTypesPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Retrieving the audience type list requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audienceTypes = await retrieveAudienceTypes(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      return {
        audienceTypes,
      };
    }
  );

function isAuxiliaryPinUpToDate(
  key: DataRoomComputeCacheKey,
  pin: media_request.MediaDatasetPin
): boolean {
  return (
    key.advertiserDatasetHash === pin.datasetAdvertiserHashHex &&
    key.publisherDatasetHash === pin.datasetPublisherHashHex
  );
}

export const makeMediaCalculateOverlapInsightsResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaCalculateOverlapInsightsArgs
    ): Promise<MediaCalculateOverlapInsightsPayload> => {
      const { dataRoomId, driverAttestationHash, audienceType } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Calculating overlap insights requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audienceTypes = await retrieveAudienceTypes(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      const results = await calculateOverlapInsightsCached(
        client,
        sessionManager,
        key,
        driverAttestationHash,
        audienceTypes // TODO: chunk up for large lists of audiences
      );
      const csvRaw = await getAudienceCsvFromRawZip(
        results,
        audienceType,
        audienceTypes
      );
      const reference = store.push(csvRaw);
      return {
        insightsCsv: reference,
      };
    }
  );

export const makeMediaRetrieveDataRoomResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveDataRoomArgs
    ): Promise<MediaRetrieveDataRoomPayload> => {
      const dataRoom = await retrieveMediaDataRoomCached(
        sessionManager,
        input.input.dataRoomId,
        input.input.driverAttestationHash
      );
      return {
        highLevel: dataRoom,
      };
    }
  );

async function getDataRoomComputeCacheKey(
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<DataRoomComputeCacheKey | null> {
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherDatasetHash == null
  ) {
    return null;
  }

  return {
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
    publisherDatasetHash: datasets.publisherDatasetHash,
  };
}

async function retrieveDirectAudiencesFromEnclave(
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<
  [
    Map<string, media_request.DirectAdvertiserAudience[]>,
    Map<string, media_request.DirectPublisherAudience[]>,
  ]
> {
  const [session, dataRoom] = await Promise.all([
    sessionManager.get({ driverAttestationHash }),
    retrieveMediaDataRoomCached(
      sessionManager,
      key.dataRoomId,
      driverAttestationHash
    ),
  ]);
  const request: media_request.MediaRequest = {
    retrieveAuxiliaryState: {
      dataRoomIdHex: key.dataRoomId,
    },
  };

  const response = await session.sendMediaRequest(request, DEBUG);

  const publisherAudiences: Map<
    string,
    media_request.DirectPublisherAudience[]
  > = new Map();
  const advertiserAudiences: Map<
    string,
    media_request.DirectAdvertiserAudience[]
  > = new Map();
  if ("retrieveAuxiliaryState" in response) {
    for (const state of response.retrieveAuxiliaryState.state) {
      const auxiliaryStateVersioned: media_request.MediaAuxiliaryState =
        media_auxiliary_state_schema.schema.parse(state.state);
      const auxiliaryState = await convertAuxiliaryStateToLatest(
        auxiliaryStateVersioned
      );
      if (!isAuxiliaryPinUpToDate(key, auxiliaryState.pin)) {
        continue;
      }
      if ("directPublisher" in auxiliaryState.activationState) {
        if (!dataRoom.publisherEmails.includes(state.user)) {
          logWarning(
            `Publisher auxiliary state found set by user ${state.user} who is not a publisher. Disregarding.`
          );
          continue;
        }
        const audiences =
          auxiliaryState.activationState.directPublisher.audiences;
        await optimisticAudienceCache.withDirectPublisher(
          key,
          state.user,
          async () => {
            return { index: state.index, state: audiences };
          }
        );
        publisherAudiences.set(state.user, audiences);
      } else if ("directAdvertiser" in auxiliaryState.activationState) {
        if (
          ![...dataRoom.advertiserEmails, ...dataRoom.agencyEmails].includes(
            state.user
          )
        ) {
          logWarning(
            `Advertiser auxiliary state found set by user ${state.user} who is not an advertiser/agency. Disregarding.`
          );
          continue;
        }
        const audiences =
          auxiliaryState.activationState.directAdvertiser.audiences;
        await optimisticAudienceCache.withDirectAdvertiser(
          key,
          state.user,
          async () => {
            return { index: state.index, state: audiences };
          }
        );
        advertiserAudiences.set(state.user, audiences);
      }
    }
  } else {
    throw new Error("Expected retrieveAuxiliaryState response");
  }

  return [advertiserAudiences, publisherAudiences];
}

async function retrieveConsentlessAudiencesFromEnclave(
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<
  [
    Map<string, media_request.ConsentlessAdvertiserAudience[]>,
    Map<string, media_request.ConsentlessPublisherAudience[]>,
  ]
> {
  const [session, dataRoom] = await Promise.all([
    sessionManager.get({ driverAttestationHash }),
    retrieveMediaDataRoomCached(
      sessionManager,
      key.dataRoomId,
      driverAttestationHash
    ),
  ]);

  const request: media_request.MediaRequest = {
    retrieveAuxiliaryState: {
      dataRoomIdHex: key.dataRoomId,
    },
  };

  const response = await session.sendMediaRequest(request, DEBUG);

  // TODO use dataset hashes in `key` to key the auxiliary state
  const advertiserAudiences: Map<
    string,
    media_request.ConsentlessAdvertiserAudience[]
  > = new Map();
  const publisherAudiences: Map<
    string,
    media_request.ConsentlessPublisherAudience[]
  > = new Map();
  if ("retrieveAuxiliaryState" in response) {
    for (const state of response.retrieveAuxiliaryState.state) {
      const auxiliaryStateVersioned: media_request.MediaAuxiliaryState =
        media_auxiliary_state_schema.schema.parse(state.state);
      const auxiliaryState = await convertAuxiliaryStateToLatest(
        auxiliaryStateVersioned
      );
      if (!isAuxiliaryPinUpToDate(key, auxiliaryState.pin)) {
        continue;
      }
      if ("consentlessAdvertiser" in auxiliaryState.activationState) {
        if (
          ![...dataRoom.advertiserEmails, ...dataRoom.agencyEmails].includes(
            state.user
          )
        ) {
          logWarning(
            `Advertiser auxiliary state found set by user ${state.user} who is not an advertiser/agency. Disregarding.`
          );
          continue;
        }
        const audiences =
          auxiliaryState.activationState.consentlessAdvertiser.audiences;
        await optimisticAudienceCache.withConsentlessAdvertiser(
          key,
          state.user,
          async () => {
            return { index: state.index, state: audiences };
          }
        );
        advertiserAudiences.set(state.user, audiences);
      }
      if ("consentlessPublisher" in auxiliaryState.activationState) {
        if (!dataRoom.publisherEmails.includes(state.user)) {
          logWarning(
            `Publisher auxiliary state found set by user ${state.user} who is not a publisher. Disregarding.`
          );
          continue;
        }
        const audiences =
          auxiliaryState.activationState.consentlessPublisher.audiences;
        await optimisticAudienceCache.withConsentlessPublisher(
          key,
          state.user,
          async () => {
            return { index: state.index, state: audiences };
          }
        );
        publisherAudiences.set(state.user, audiences);
      }
    }
  } else {
    throw new Error("Expected retrieveAuxiliaryState response");
  }

  return [advertiserAudiences, publisherAudiences];
}

export const makeMediaRetrieveConsentlessAudiencesResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveConsentlessAudiencesArgs
    ): Promise<MediaRetrieveConsentlessAudiencesPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Retrieving the audiences requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audiences = await retrieveConsentlessAudiences(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      return {
        nodes: audiences,
      };
    }
  );

async function retrieveConsentlessAudiences(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<ConsentlessAudience[]> {
  const [[advertiserAudiences, publisherAudiences], audienceTypes] =
    await Promise.all([
      retrieveConsentlessAudiencesFromEnclave(
        sessionManager,
        key,
        driverAttestationHash
      ),
      retrieveAudienceTypes(client, sessionManager, key, driverAttestationHash),
    ]);

  const overlapInsights = await calculateOverlapInsightsCached(
    client,
    sessionManager,
    key,
    driverAttestationHash,
    audienceTypes
  );

  const consentlessAudiences = [];
  const publisherAudiencesMap: Map<
    string,
    { user: string; audience: media_request.ConsentlessPublisherAudience }
  > = new Map();
  publisherAudiences.forEach((audiences, user) => {
    audiences.forEach((audience) => {
      publisherAudiencesMap.set(audience.id, { audience, user });
    });
  });
  for (const [user, audienceAdvertisers] of advertiserAudiences) {
    for (const audienceAdvertiser of audienceAdvertisers) {
      const audienceCsv = await getAudienceCsvFromRawZip(
        overlapInsights,
        audienceAdvertiser.audienceType,
        audienceTypes
      );
      if (audienceCsv == null) {
        throw new Error(
          `Could not find audience type CSV for "${audienceAdvertiser.audienceType}" in result`
        );
      }

      const csv = parseCsv(audienceCsv, {
        delimiter: ",",
        skipEmptyLines: true,
      });
      if (csv.errors.length > 0) {
        throw new Error(
          `Could not parse overlap insights for audience type ${
            audienceAdvertiser.audienceType
          } as CSV: ${csv.errors
            .map((error) => "Line " + error.row + ": " + error.message)
            .join("\n")}`
        );
      }
      let audienceSize = 0;
      // Implicit schema: segment base_propensity  overlap_propensity  net_propensity  cumulative_addressable segment_count
      for (const row of csv.data) {
        if (!Array.isArray(row)) {
          throw new Error(`Expected an array row in insights CSV, got ${row}`);
        }
        if (row.length < 6) {
          throw new Error(
            `Expected at least 6 columns in row in insights CSV, got ${row}`
          );
        }
        const cumulative = parseFloat(row[4]);
        audienceSize = parseInt(row[5]);
        if (cumulative >= audienceAdvertiser.precision) {
          break;
        }
      }

      const audiencePublisher = publisherAudiencesMap.get(
        audienceAdvertiser.id
      );
      const downloaded =
        (audiencePublisher === undefined
          ? false
          : audiencePublisher.audience.downloaded) ||
        (audienceAdvertiser === undefined
          ? false
          : audienceAdvertiser.downloaded);
      const consentlessAudience: ConsentlessAudience = {
        __typename: "ConsentlessAudience",
        activated: audienceAdvertiser.activated,
        downloaded,
        id: audienceAdvertiser.id,
        name: audienceAdvertiser.audienceType,
        owner: user,
        precision: audienceAdvertiser.precision,
        size: audienceSize,
      };
      consentlessAudiences.push(consentlessAudience);
    }
  }

  return consentlessAudiences;
}

async function retrieveDirectAudiences(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<DirectAudience[]> {
  const [
    audienceOverlapSizes,
    directActivationConfig,
    [directAdvertiserAudiences, directPublisherAudiences],
  ] = await Promise.all([
    retrieveAudienceOverlapSizes(
      client,
      sessionManager,
      key,
      driverAttestationHash
    ),
    retrieveDirectActivationConfigCached(
      client,
      sessionManager,
      key.dataRoomId,
      driverAttestationHash
    ),
    retrieveDirectAudiencesFromEnclave(
      sessionManager,
      key,
      driverAttestationHash
    ),
  ]);

  const activatedAudienceTypes =
    directActivationConfig == null
      ? new Set()
      : new Set(directActivationConfig.audienceTypes);
  const directPublisherAudiencesMap: Map<
    string,
    media_request.DirectPublisherAudience
  > = new Map();
  directPublisherAudiences.forEach((audiences, user) => {
    audiences.forEach((audience) => {
      const mergedAudience: media_request.DirectPublisherAudience = {
        audienceType: audience.audienceType,
        downloaded:
          (directPublisherAudiencesMap.get(audience.audienceType)?.downloaded ??
            false) ||
          audience.downloaded,
      };
      directPublisherAudiencesMap.set(audience.audienceType, mergedAudience);
    });
  });
  const directAdvertiserAudiencesMap: Map<
    string,
    media_request.DirectAdvertiserAudience
  > = new Map();
  directAdvertiserAudiences.forEach((audiences, user) => {
    audiences.forEach((audience) => {
      const mergedAudience: media_request.DirectAdvertiserAudience = {
        audienceType: audience.audienceType,
        downloaded:
          (directAdvertiserAudiencesMap.get(audience.audienceType)
            ?.downloaded ??
            false) ||
          audience.downloaded,
      };
      directAdvertiserAudiencesMap.set(audience.audienceType, mergedAudience);
    });
  });
  const audiences: DirectAudience[] = [];
  for (const [audienceType, size] of audienceOverlapSizes) {
    audiences.push({
      __typename: "DirectAudience",
      activated: activatedAudienceTypes.has(audienceType),
      downloaded:
        (directPublisherAudiencesMap.get(audienceType)?.downloaded ?? false) ||
        (directAdvertiserAudiencesMap.get(audienceType)?.downloaded ?? false),
      name: audienceType,
      size,
    });
  }

  return audiences;
}

export const makeMediaRetrieveDirectAudiencesResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveDirectAudiencesArgs
    ): Promise<MediaRetrieveDirectAudiencesPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Retrieving the audiences requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audiences = await retrieveDirectAudiences(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      return {
        nodes: audiences,
      };
    }
  );

export const makeMediaRetrieveConsentlessActivatedAudiencesResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveConsentlessActivatedAudiencesArgs
    ): Promise<MediaRetrieveConsentlessActivatedAudiencesPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Retrieving the activated audiences requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audiences = await retrieveConsentlessAudiences(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      return {
        nodes: audiences.filter((audience) => audience.activated),
      };
    }
  );

export const makeMediaRetrieveDirectActivatedAudiencesResolver = (
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  store: ApiCoreContextValue["store"]
) =>
  sanitizeResolver(
    async (
      _obj: any,
      input: QueryMediaRetrieveDirectActivatedAudiencesArgs
    ): Promise<MediaRetrieveDirectActivatedAudiencesPayload> => {
      const { dataRoomId, driverAttestationHash } = input.input;
      const key = await getDataRoomComputeCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      if (key == null) {
        throw new Error(
          "Retrieving the activated audiences requires both the publisher and advertiser dataset uploaded"
        );
      }
      const audiences = await retrieveDirectAudiences(
        client,
        sessionManager,
        key,
        driverAttestationHash
      );
      return {
        nodes: audiences.filter((audience) => audience.activated),
      };
    }
  );

// Helpers
export function calculateAttestationHash(
  enclaveSpecification: media_request.EnclaveSpecification
): string {
  const md = forge.md.sha256.create();
  const attestationSpecificationEncoded = forge.util.binary.base64.decode(
    enclaveSpecification.attestationProtoBase64
  );
  md.update(forge.util.binary.raw.encode(attestationSpecificationEncoded));
  return md.digest().toHex();
}

function gqlActivationTypeToMediaActivationType(
  gqlActivationType: GqlActivationType | null
): media_request.ActivationType | null {
  switch (gqlActivationType) {
    case GqlActivationType.Consentless:
      return "consentless";
    case GqlActivationType.Direct:
      return "direct";
    case null:
      return null;
    default:
      throw new Error(`Unknown data clean room type ${gqlActivationType}`);
  }
}

function activationTypeToPublisherSchema(
  activationType: media_request.ActivationType | null
): media_request.ColumnTypeV1[] {
  if (activationType === null) {
    return ["matchingId"];
  } else if (activationType === "consentless") {
    return ["matchingId", "segment"];
  } else if (activationType === "direct") {
    return ["matchingId", "activationId"];
  } else {
    throw new Error(`Unknown data clean room type '${activationType}'`);
  }
}

function activationTypeToAdvertiserSchema(
  activationType: media_request.ActivationType | null
): media_request.ColumnTypeV1[] {
  if (activationType === null) {
    return ["matchingId"];
  } else if (activationType === "consentless") {
    return ["matchingId", "audienceType"];
  } else if (activationType === "direct") {
    return ["matchingId", "audienceType"];
  } else {
    throw new Error(`Unknown data clean room type '${activationType}'`);
  }
}

const retrieveAuthenticationRootCertificatePemCached = (() => {
  let cache: Promise<Uint8Array> | null = null;
  return async (client: ApiCoreContextValue["client"]) => {
    if (cache == null) {
      cache = client.decentriqCaRootCertificate();
    }
    return new TextDecoder().decode(await cache);
  };
})();

interface MediaEnclaveSpecifications {
  driverEnclaveSpecification: media_request.EnclaveSpecification;
  pythonEnclaveSpecification: media_request.EnclaveSpecification;
}

export async function retrieveLatestMediaEnclaveSpecifications(
  client: ApiCoreContextValue["client"]
): Promise<MediaEnclaveSpecifications> {
  const allSpecifications = await client.getEnclaveSpecifications();
  let driverEnclaveSpecification: EnclaveSpecification | null = null;
  let pythonEnclaveSpecification: EnclaveSpecification | null = null;
  for (const specification of allSpecifications) {
    if (specification.name === "decentriq.driver") {
      if (
        driverEnclaveSpecification === null ||
        parseInt(driverEnclaveSpecification.version) <
          parseInt(specification.version)
      ) {
        driverEnclaveSpecification = specification;
      }
    } else if (specification.name === "decentriq.python-ml-worker-32-64") {
      if (
        pythonEnclaveSpecification === null ||
        parseInt(pythonEnclaveSpecification.version) <
          parseInt(specification.version)
      ) {
        pythonEnclaveSpecification = specification;
      }
    }
  }
  if (driverEnclaveSpecification === null) {
    throw new Error("Could not find 'decentriq.driver' enclave specification");
  }
  if (pythonEnclaveSpecification === null) {
    // Try to find a backup nitro specification for the python node
    // TODO remove once nitro is completely decommissioned
    for (const specification of allSpecifications) {
      if (specification.name === "decentriq.python-ml-worker") {
        if (
          pythonEnclaveSpecification === null ||
          parseInt(pythonEnclaveSpecification.version) <
            parseInt(specification.version)
        ) {
          pythonEnclaveSpecification = specification;
        }
      }
    }
    if (pythonEnclaveSpecification === null) {
      throw new Error(
        "Could not find 'decentriq.python-ml-worker-32-64' or 'decentriq.python-ml-worker' enclave specification"
      );
    }
  }
  return {
    driverEnclaveSpecification: enclaveSpecificationToMediaEnclaveSpecification(
      driverEnclaveSpecification
    ),
    pythonEnclaveSpecification: enclaveSpecificationToMediaEnclaveSpecification(
      pythonEnclaveSpecification
    ),
  };
}

function enclaveSpecificationToMediaEnclaveSpecification(
  enclaveSpecification: EnclaveSpecification
): media_request.EnclaveSpecification {
  const attestationProtoBase64 = forge.util.binary.base64.encode(
    proto.attestation.AttestationSpecification.encodeDelimited(
      enclaveSpecification.proto
    ).finish()
  );

  return {
    attestationProtoBase64,
    id: enclaveSpecification.name,
    workerProtocol: Math.max(...enclaveSpecification.workerProtocols),
  };
}

const retrieveLatestMediaEnclaveSpecificationsCached = (() => {
  let cache: Promise<MediaEnclaveSpecifications> | null = null;
  return async (client: ApiCoreContextValue["client"]) => {
    if (cache === null) {
      cache = retrieveLatestMediaEnclaveSpecifications(client);
    }
    return await cache;
  };
})();

export const retrieveMediaDataRoomCached = (() => {
  return async (
    sessionManager: ApiCoreContextValue["sessionManager"],
    dataRoomId: string,
    driverAttestationHash: string
  ) => {
    const cached = cache.mediaDataRoom.get(dataRoomId);
    if (cached !== undefined) {
      return await cached;
    }
    const promise = retrieveMediaDataRoom(
      sessionManager,
      dataRoomId,
      driverAttestationHash
    );
    cache.mediaDataRoom.put(dataRoomId, promise);
    return await promise;
  };
})();

async function retrieveMediaDataRoom(
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<MediaDataRoomLatest> {
  const session = await sessionManager.get({ driverAttestationHash });
  const request: media_request.MediaRequest = {
    retrieveDataRoom: {
      dataRoomIdHex: dataRoomId,
    },
  };
  const response = await session.sendMediaRequest(request, DEBUG);
  if ("retrieveDataRoom" in response) {
    const compiler = await Compiler.initialize();
    return compiler.convertMediaDataRoomAnyToLatest(
      response.retrieveDataRoom.dataRoom
    );
  } else {
    throw new Error("Expected retrieveDataRoom response");
  }
}

export async function retrievePublishedDatasets(
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<MediaRetrievePublishedDatasetsPayload> {
  const session = await sessionManager.get({
    driverAttestationHash,
  });
  const request: media_request.MediaRequest = {
    retrievePublishedDatasets: {
      dataRoomIdHex: dataRoomId,
    },
  };
  const response = await session.sendMediaRequest(request, DEBUG);
  if ("retrievePublishedDatasets" in response) {
    return {
      advertiserDatasetHash:
        response.retrievePublishedDatasets.advertiserDatasetHashHex,
      publisherDatasetHash:
        response.retrievePublishedDatasets.publisherDatasetHashHex,
    };
  } else {
    throw new Error("Expected retrieveDataRoom response");
  }
}

export async function retrievePublishedDatasetsCached(
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<MediaRetrievePublishedDatasetsPayload> {
  const cached = cache.publishedDatasets.get(dataRoomId);
  if (cached !== undefined) {
    return await cached;
  }
  const promise = retrievePublishedDatasets(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );
  cache.publishedDatasets.put(dataRoomId, promise);
  return await promise;
}

async function calculateOverlapBasicCached(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<Uint8Array> {
  const cached = cache.overlapBasicResult.get(key);
  if (cached !== undefined) {
    return await cached;
  }
  const promise = calculateOverlapBasic(
    client,
    sessionManager,
    key.dataRoomId,
    driverAttestationHash
  );
  cache.overlapBasicResult.put(key, promise);
  return await promise;
}

async function calculateOverlapBasic(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<Uint8Array> {
  try {
    const [scopeId, session] = await Promise.all([
      client.ensureDcrDataScope(dataRoomId),
      sessionManager.get({ driverAttestationHash }),
    ]);
    const request: media_request.MediaRequest = {
      calculateOverlapBasic: {
        dataRoomIdHex: dataRoomId,
        scopeIdHex: scopeId,
      },
    };
    const response = await session.sendMediaRequest(request, DEBUG);
    if ("calculateOverlapBasic" in response) {
      return await session.getComputationResult(
        {
          computeNodeId: response.calculateOverlapBasic.computeNodeName,
          jobId: response.calculateOverlapBasic.jobIdHex,
        },
        {
          interval: 1,
          timeout: undefined,
        }
      );
    } else {
      throw new Error("Expected calculateOverlapBasic response");
    }
  } catch (error) {
    throw parseMediaDataRoomError(error);
  }
}

const calculateOverlapInsightsCached = (() => {
  return async (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    key: DataRoomComputeCacheKey,
    driverAttestationHash: string,
    audienceTypes: Array<string>
  ) => {
    const cached = cache.overlapInsightsResult.get({ ...key, audienceTypes });
    if (cached !== undefined) {
      return await cached;
    }
    const promise = calculateOverlapInsights(
      client,
      sessionManager,
      key,
      driverAttestationHash,
      audienceTypes
    );
    cache.overlapInsightsResult.put({ ...key, audienceTypes }, promise);
    return await promise;
  };
})();

async function calculateOverlapInsights(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string,
  audienceTypes: Array<string>
): Promise<Uint8Array> {
  try {
    const [scopeId, session] = await Promise.all([
      client.ensureDcrDataScope(key.dataRoomId),
      sessionManager.get({
        driverAttestationHash,
      }),
      retrieveAudienceTypes(client, sessionManager, key, driverAttestationHash),
    ]);

    const request: media_request.MediaRequest = {
      calculateOverlapInsights: {
        audienceTypes: audienceTypes,
        dataRoomIdHex: key.dataRoomId,
        scopeIdHex: scopeId,
      },
    };

    const response = await session.sendMediaRequest(request, DEBUG);

    if ("calculateOverlapInsights" in response) {
      return await session.getComputationResult(
        {
          computeNodeId: response.calculateOverlapInsights.computeNodeName,
          jobId: response.calculateOverlapInsights.jobIdHex,
        },
        {
          interval: 1,
          timeout: undefined,
        }
      );
    } else {
      throw new Error("Expected calculateOverlapInsights response");
    }
  } catch (error) {
    throw parseMediaDataRoomError(error);
  }
}

async function getOverlapCsvFromRawZip(rawZip: Uint8Array): Promise<string> {
  const zip = await JSZip.loadAsync(rawZip);
  const overlapCsv = zip.file("overlap.csv");
  if (overlapCsv == null) {
    throw new Error(`Could not find "overlap.csv" in result`);
  }
  const csvRaw = await overlapCsv.async("string");
  return csvRaw;
}

async function retrieveAudienceTypes(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<Array<string>> {
  const overlapZipRaw = await calculateOverlapBasicCached(
    client,
    sessionManager,
    key,
    driverAttestationHash
  );
  const csvRaw = await getOverlapCsvFromRawZip(overlapZipRaw);
  const csv = parseCsv(csvRaw, { delimiter: ",", skipEmptyLines: true });
  if (csv.errors.length > 0) {
    throw new Error(
      `Could not parse "overlap.csv" as CSV: ${csv.errors.join("\n")}`
    );
  }
  return csv.data.map((row) => {
    if (!Array.isArray(row)) {
      throw new Error(`Expected an array CSV row`);
    }
    return row[0];
  });
}

async function retrieveAudienceOverlapSizes(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  key: DataRoomComputeCacheKey,
  driverAttestationHash: string
): Promise<Array<[string, number]>> {
  const overlapZipRaw = await calculateOverlapBasicCached(
    client,
    sessionManager,
    key,
    driverAttestationHash
  );
  const csvRaw = await getOverlapCsvFromRawZip(overlapZipRaw);
  const csv = parseCsv(csvRaw, { delimiter: ",", skipEmptyLines: true });
  if (csv.errors.length > 0) {
    throw new Error(
      `Could not parse "overlap.csv" as CSV: ${csv.errors.join("\n")}`
    );
  }
  return csv.data.map((row) => {
    if (!Array.isArray(row)) {
      throw new Error(`Expected an array CSV row`);
    }
    return [row[0], parseInt(row[2])];
  });
}

function getAudienceFile(audienceType: string): string {
  const audienceTypeBase64 = forge.util.binary.base64.encode(
    new TextEncoder().encode(audienceType)
  );
  return `${audienceTypeBase64}.csv`;
}

async function getAudienceCsvFromRawZip(
  rawZip: Uint8Array,
  audienceType: string,
  expectedAudienceTypes: string[]
): Promise<string | null> {
  const zip = await JSZip.loadAsync(rawZip);
  const audienceFile = getAudienceFile(audienceType);
  const insightsCsv = zip.file(audienceFile);
  if (insightsCsv == null) {
    if (expectedAudienceTypes.includes(audienceType)) {
      // Use dummy empty CSV. This can happen if the audience type was filtered out for privacy reasons.
      // TODO: indicate this some other way to the caller
      return "";
    } else {
      return null;
    }
  }
  return await insightsCsv.async("string");
}
