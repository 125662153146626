import { DqCard, DqCardContent, DqIcon } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, type MouseEventHandler, useCallback, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRoleCardPresentation,
} from "features/dataRoomCreation/models";
import { DataRoomType } from "models";
import {
  CreateDataRoomStepActionsContainer,
  CreateDataRoomStepContainer,
  CreateDataRoomStepContentContainer,
  CreateDataRoomStepHeader,
} from "../CreateDataRoomStep";
import {
  type MediaDataRoomCreationCurrentUserRoleStepFormValues,
  MediaDataRoomCreationCurrentUserRoleStepSchema,
  type MediaDataRoomOrganizationRoleTypeItem,
} from "./models";

export interface MediaDataRoomCreationCurrentUserRoleStepProps {
  hasAdvertiserFeatures: boolean;
  hasPublisherFeatures: boolean;
  hasDataPartnerFeatures: boolean;
  defaultOrganizationRole?: MediaDataRoomOrganizationRole;
  onSubmit?: (organizationRole: MediaDataRoomOrganizationRole) => void;
  onCancel?: () => void;
}

const MediaDataRoomCreationCurrentUserRoleStep =
  memo<MediaDataRoomCreationCurrentUserRoleStepProps>(
    ({
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
      defaultOrganizationRole,
      onSubmit,
      onCancel,
    }) => {
      const currentUserRoleForm =
        useForm<MediaDataRoomCreationCurrentUserRoleStepFormValues>({
          defaultValues: {
            organizationRole: defaultOrganizationRole,
          },
          mode: "onSubmit",
          resolver: zodResolver(MediaDataRoomCreationCurrentUserRoleStepSchema),
        });
      const { reset: resetForm, handleSubmit: handleFormSubmit } =
        currentUserRoleForm;

      const { palette } = useTheme();
      const { enqueueSnackbar } = useSnackbar();

      const availableRoles = useMemo<MediaDataRoomOrganizationRoleTypeItem[]>(
        () =>
          [
            {
              ...mediaDataRoomOrganizationRoleCardPresentation[
                MediaDataRoomOrganizationRole.ADVERTISER
              ],
              description:
                "Choose this, if Advertiser best describes your role in this Media DCR.",
              role: MediaDataRoomOrganizationRole.ADVERTISER,
              testId: testIds.dataRoomCreation.currentUserRoleSelect.advertiser,
              visible: hasAdvertiserFeatures,
            },
            {
              ...mediaDataRoomOrganizationRoleCardPresentation[
                MediaDataRoomOrganizationRole.PUBLISHER
              ],
              description:
                "Choose this, if Publisher best describes your role in this Media DCR.",
              role: MediaDataRoomOrganizationRole.PUBLISHER,
              testId: testIds.dataRoomCreation.currentUserRoleSelect.publisher,
              visible: hasPublisherFeatures,
            },
            {
              ...mediaDataRoomOrganizationRoleCardPresentation[
                MediaDataRoomOrganizationRole.DATA_PARTNER
              ],
              description:
                "Choose this, if Data partner best describes your role in this Media DCR.",
              role: MediaDataRoomOrganizationRole.DATA_PARTNER,
              testId:
                testIds.dataRoomCreation.currentUserRoleSelect.dataPartner,
              visible: hasDataPartnerFeatures,
            },
          ].filter(({ visible }) => visible),
        [hasAdvertiserFeatures, hasDataPartnerFeatures, hasPublisherFeatures]
      );

      const handleCancel = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);

      const handleSubmit = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
          void handleFormSubmit(
            ({ organizationRole }) => {
              onSubmit?.(organizationRole);
            },
            (errors) => {
              if (errors.organizationRole) {
                enqueueSnackbar(errors.organizationRole.message);
              }
            }
          )();
        },
        [onSubmit, enqueueSnackbar, handleFormSubmit]
      );

      return (
        <FormProvider {...currentUserRoleForm}>
          <CreateDataRoomStepContainer>
            <CreateDataRoomStepHeader
              dataRoomType={DataRoomType.MediaInsights}
            />
            <CreateDataRoomStepContentContainer>
              <Typography level="h4">
                Select in which role you want to create the data clean room
              </Typography>
              <Controller<MediaDataRoomCreationCurrentUserRoleStepFormValues>
                name="organizationRole"
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value ?? null}>
                    <Grid columnSpacing={2} container={true} rowSpacing={2}>
                      {availableRoles.map(
                        ({ icon, title, description, role, testId }) => (
                          <Grid key={role} sm={4} xs={12}>
                            <DqCard
                              data-testid={testId}
                              selectable={{
                                component: "radio",
                                value: role,
                              }}
                            >
                              <DqCardContent
                                alignItems="flex-start"
                                gap={2}
                                justifyContent="flex-start"
                              >
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  gap={2}
                                >
                                  <DqIcon
                                    icon={icon}
                                    iconProps={{
                                      color: palette.primary[400],
                                      size: "2x",
                                    }}
                                  />
                                  <Typography
                                    level="title-lg"
                                    textAlign="center"
                                  >
                                    {title}
                                  </Typography>
                                </Stack>
                                <Typography level="body-sm">
                                  {description}
                                </Typography>
                              </DqCardContent>
                            </DqCard>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </RadioGroup>
                )}
              />
            </CreateDataRoomStepContentContainer>
            <CreateDataRoomStepActionsContainer>
              <Button onClick={handleCancel}>Back</Button>
              <Button color="primary" onClick={handleSubmit} variant="solid">
                Continue
              </Button>
            </CreateDataRoomStepActionsContainer>
          </CreateDataRoomStepContainer>
        </FormProvider>
      );
    }
  );

MediaDataRoomCreationCurrentUserRoleStep.displayName =
  "MediaDataRoomCreationCurrentUserRoleStep";

export default MediaDataRoomCreationCurrentUserRoleStep;
