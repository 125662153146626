import { DqLoader } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { DataRoomAuditLogs } from "containers";
import {
  MediaDataRoomData,
  MediaDataRoomInsights,
  MediaDataRoomOverlapInsightsWrapper,
  MediaDataRoomTab,
  OverlapInsightsExecutionContext,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";

interface MediaDataRoomTabsProps {
  activeTab: MediaDataRoomTab;
  baseUrl: string;
}

const MediaDataRoomTabs = memo<MediaDataRoomTabsProps>(
  ({ activeTab, baseUrl }) => {
    const {
      loading: dataRoomLoading,
      dataRoomId,
      driverAttestationHash,
      supportedFeatures: { enableInsights, canViewInsights, canViewAudiences },
    } = useMediaDataRoom();
    const { dataLabDataLoading } = useMediaDataRoomInsightsData();
    const loading = dataRoomLoading || dataLabDataLoading;
    if (loading) {
      return <DqLoader />;
    }
    if (
      (activeTab === MediaDataRoomTab.insights && !enableInsights) ||
      (activeTab === MediaDataRoomTab.activation && !canViewAudiences)
    ) {
      return (
        <Navigate replace={true} to={`${baseUrl}/${MediaDataRoomTab.data}`} />
      );
    }
    return (
      <Tabs
        key={dataRoomId}
        sx={{ flex: 1, overflow: "hidden" }}
        value={activeTab}
      >
        <TabList>
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.data}
            to={`${baseUrl}/${MediaDataRoomTab.data}`}
            value={MediaDataRoomTab.data}
          >
            Data
          </Tab>
          {canViewInsights && (
            <Tab
              component={Link}
              data-testid={testIds.containers.mediaDataRoom.insights}
              to={`${baseUrl}/${MediaDataRoomTab.insights}`}
              value={MediaDataRoomTab.insights}
            >
              Insights
            </Tab>
          )}
          {canViewAudiences && (
            <Tab
              component={Link}
              data-testid={testIds.containers.mediaDataRoom.activation}
              to={`${baseUrl}/${MediaDataRoomTab.activation}`}
              value={MediaDataRoomTab.activation}
            >
              Audiences
            </Tab>
          )}
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.auditLog}
            to={`${baseUrl}/${MediaDataRoomTab.auditLog}`}
            value={MediaDataRoomTab.auditLog}
          >
            Audit log
          </Tab>
        </TabList>
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.data}>
          <MediaDataRoomData />
        </TabPanel>
        {canViewInsights && (
          <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.insights}>
            <MediaDataRoomOverlapInsightsWrapper
              executionContext={OverlapInsightsExecutionContext.insightsTab}
            >
              <MediaDataRoomInsights />
            </MediaDataRoomOverlapInsightsWrapper>
          </TabPanel>
        )}
        {canViewAudiences && (
          <TabPanel
            sx={{ overflow: "auto" }}
            value={MediaDataRoomTab.activation}
          >
            <Outlet />
          </TabPanel>
        )}
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.auditLog}>
          <DataRoomAuditLogs
            dcrHash={dataRoomId}
            driverAttestationHash={driverAttestationHash}
          />
        </TabPanel>
      </Tabs>
    );
  }
);

MediaDataRoomTabs.displayName = "MediaDataRoomTabs";

export default MediaDataRoomTabs;
