import { useAuth0 } from "@auth0/auth0-react";
import { createContext, memo, useCallback, useMemo } from "react";
import { type MediaDataRoomCreationConfigurationFormValues } from "features/dataRoomCreation/models";
import {
  useCurrentUserOrganizationBranding,
  useOrganizationPreferences,
  useSafeContext,
} from "hooks";
import useMediaDataRoomPublish, {
  type MediaDataRoomPublishHookResult,
} from "./useMediaDataRoomPublish/useMediaDataRoomPublish";
import { MediaDataRoomCreationAvailableParticipantsWrapper } from "./MediaDataRoomCreationAvailableParticipantsContext";
import useMediaDataRoomCreationState, {
  type MediaDataRoomCreationStateHookResult,
} from "./useMediaDataRoomCreationState";

export type MediaDataRoomCreationContextValue =
  MediaDataRoomCreationStateHookResult &
    Omit<MediaDataRoomPublishHookResult, "publish"> & {
      hasAdvertiserFeatures: boolean;
      hasPublisherFeatures: boolean;
      hasDataPartnerFeatures: boolean;
      hasCurrentUserRoleStep: boolean;
      publish: (
        configuration: MediaDataRoomCreationConfigurationFormValues
      ) => Promise<void>;
    };

const MediaDataRoomCreationContext =
  createContext<MediaDataRoomCreationContextValue | null>(null);
MediaDataRoomCreationContext.displayName = "MediaDataRoomCreationContext";

export const MediaDataRoomCreationContextProvider =
  MediaDataRoomCreationContext.Provider;

export const useMediaDataRoomCreation = () =>
  useSafeContext(MediaDataRoomCreationContext);

export const MediaDataRoomCreationWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const {
      hasAdvertiserFeatures,
      hasDataPartnerFeatures,
      hasPublisherFeatures,
      numberOfSupportedMediaRoles,
      isLoading: isPreferencesLoading,
    } = useOrganizationPreferences();
    const hasCurrentUserRoleStep = numberOfSupportedMediaRoles > 1;
    const {
      logoBase64,
      organizationName,
      isLoading: isBrandingLoading,
    } = useCurrentUserOrganizationBranding();
    const isLoading = isPreferencesLoading || isBrandingLoading;
    const { user } = useAuth0();
    const currentUserEmail = user?.email ?? "";
    const {
      collaborationTypes,
      configuration,
      deleteParticipant,
      editParticipant,
      participants,
      setCollaborationTypes,
      setConfiguration,
      setCurrentUserRole,
      setParticipants,
      setPermissions,
      currentUserRole,
      addParticipant,
    } = useMediaDataRoomCreationState({
      currentUserEmail,
      hasAdvertiserFeatures,
      hasCurrentUserRoleStep,
      hasDataPartnerFeatures,
      hasPublisherFeatures,
      isLoading,
      organizationLogo: logoBase64 ?? "",
      organizationName,
    });
    const { publishingStep, publish } = useMediaDataRoomPublish();
    const handlePublish = useCallback(
      async (configuration: MediaDataRoomCreationConfigurationFormValues) => {
        setConfiguration(configuration);
        return await publish({
          collaborationTypes,
          configuration,
          participants,
        });
      },
      [collaborationTypes, participants, publish, setConfiguration]
    );
    const contextValue = useMemo<MediaDataRoomCreationContextValue>(
      () => ({
        addParticipant,
        collaborationTypes,
        configuration,
        currentUserRole,
        deleteParticipant,
        editParticipant,
        hasAdvertiserFeatures,
        hasCurrentUserRoleStep,
        hasDataPartnerFeatures,
        hasPublisherFeatures,
        participants,
        publish: handlePublish,
        publishingStep,
        setCollaborationTypes,
        setConfiguration,
        setCurrentUserRole,
        setParticipants,
        setPermissions,
      }),
      [
        hasAdvertiserFeatures,
        hasDataPartnerFeatures,
        hasPublisherFeatures,
        handlePublish,
        publishingStep,
        collaborationTypes,
        configuration,
        deleteParticipant,
        editParticipant,
        addParticipant,
        participants,
        currentUserRole,
        hasCurrentUserRoleStep,
        setCollaborationTypes,
        setConfiguration,
        setCurrentUserRole,
        setParticipants,
        setPermissions,
      ]
    );
    return (
      <MediaDataRoomCreationContext.Provider value={contextValue}>
        <MediaDataRoomCreationAvailableParticipantsWrapper>
          {children}
        </MediaDataRoomCreationAvailableParticipantsWrapper>
      </MediaDataRoomCreationContext.Provider>
    );
  }
);
MediaDataRoomCreationWrapper.displayName = "MediaDataRoomCreationWrapper";
