
URL = globalThis.URL
import __vite__wasmUrl from "/home/runner/work/delta/delta/trusted/delta-transparency-verification-wasm/dist/delta_transparency_verification_wasm_bg.wasm?url"
import __vite__initWasm from "/__vite-plugin-wasm-helper"

import { __wbindgen_error_new as __vite__wasmImport_0_0, __wbindgen_throw as __vite__wasmImport_0_1, __wbindgen_init_externref_table as __vite__wasmImport_0_2 } from "./delta_transparency_verification_wasm_bg.js";
const __vite__wasmModule = await __vite__initWasm({ "./delta_transparency_verification_wasm_bg.js": { __wbindgen_error_new: __vite__wasmImport_0_0, __wbindgen_throw: __vite__wasmImport_0_1, __wbindgen_init_externref_table: __vite__wasmImport_0_2 } }, __vite__wasmUrl);
export const memory = __vite__wasmModule.memory;
export const __calloc = __vite__wasmModule.__calloc;
export const __free = __vite__wasmModule.__free;
export const __strstr = __vite__wasmModule.__strstr;
export const __memset = __vite__wasmModule.__memset;
export const verify_measurement_transparency = __vite__wasmModule.verify_measurement_transparency;
export const mbedtls_log = __vite__wasmModule.mbedtls_log;
export const mbedtls_mutex_init = __vite__wasmModule.mbedtls_mutex_init;
export const mbedtls_mutex_free = __vite__wasmModule.mbedtls_mutex_free;
export const mbedtls_mutex_lock = __vite__wasmModule.mbedtls_mutex_lock;
export const mbedtls_mutex_unlock = __vite__wasmModule.mbedtls_mutex_unlock;
export const __wbindgen_export_0 = __vite__wasmModule.__wbindgen_export_0;
export const __wbindgen_malloc = __vite__wasmModule.__wbindgen_malloc;
export const __externref_table_dealloc = __vite__wasmModule.__externref_table_dealloc;
export const __wbindgen_start = __vite__wasmModule.__wbindgen_start;
