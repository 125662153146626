import { Stack, styled } from "@mui/joy";

const CreateDataRoomStepContentContainer = styled(Stack)<{
  maxWidth?: number;
}>(({ theme: { breakpoints, spacing } }) => ({
  flex: 1,
  gap: spacing(2),
  height: "100%",
  margin: `0 auto ${spacing(4)}`,
  maxWidth: breakpoints.values.lg,
  overflowY: "auto",
  padding: spacing(2),
  width: "100%",
}));

export default CreateDataRoomStepContentContainer;
