import { memo, useCallback } from "react";
import { useWizard, Wizard } from "react-use-wizard";
import {
  MediaDataRoomCreationCollaborationTypesAndPermissionsStep,
  MediaDataRoomCreationConfigurationStep,
  MediaDataRoomCreationCurrentUserRoleStep,
  MediaDataRoomCreationParticipantsStep,
} from "features/dataRoomCreation/components";
import {
  useDataRoomCreation,
  useMediaDataRoomCreation,
} from "features/dataRoomCreation/contexts";
import DataRoomCreationWizardStep from "./DataRoomCreationWizardStep";

const MediaDataRoomCreationWizard = memo(() => {
  const {
    hasAdvertiserFeatures,
    hasDataPartnerFeatures,
    hasCurrentUserRoleStep,
    hasPublisherFeatures,
    collaborationTypes,
    participants,
    setCollaborationTypes,
    setCurrentUserRole,
    currentUserRole,
    addParticipant,
    setParticipants,
    deleteParticipant,
    setPermissions,
    editParticipant,
    publishingStep,
    publish,
    configuration,
  } = useMediaDataRoomCreation();
  const { cancelCreation } = useDataRoomCreation();
  const { previousStep, isFirstStep } = useWizard();
  const handleCancel = useCallback(() => {
    if (isFirstStep) {
      cancelCreation();
      return;
    }
    previousStep();
  }, [cancelCreation, isFirstStep, previousStep]);
  return (
    <Wizard>
      {hasCurrentUserRoleStep && (
        <DataRoomCreationWizardStep
          render={({ nextStep, isFirstStep, previousStep }) => (
            <MediaDataRoomCreationCurrentUserRoleStep
              defaultOrganizationRole={currentUserRole ?? undefined}
              hasAdvertiserFeatures={hasAdvertiserFeatures}
              hasDataPartnerFeatures={hasDataPartnerFeatures}
              hasPublisherFeatures={hasPublisherFeatures}
              onCancel={isFirstStep ? handleCancel : previousStep}
              onSubmit={(role) => {
                setCurrentUserRole(role);
                void nextStep();
              }}
            />
          )}
        />
      )}
      <DataRoomCreationWizardStep
        render={({ nextStep, isFirstStep, previousStep }) => (
          <MediaDataRoomCreationParticipantsStep
            defaultParticipants={participants}
            onCancel={isFirstStep ? handleCancel : previousStep}
            onParticipantAdded={addParticipant}
            onParticipantDelete={deleteParticipant}
            onParticipantEdit={editParticipant}
            onSubmit={(participants) => {
              setParticipants(participants);
              void nextStep();
            }}
          />
        )}
      />
      <DataRoomCreationWizardStep
        render={({ nextStep, previousStep }) => (
          <MediaDataRoomCreationCollaborationTypesAndPermissionsStep
            collaborationTypes={collaborationTypes}
            onCancel={(collaborationTypes) => {
              setCollaborationTypes(collaborationTypes);
              previousStep();
            }}
            onSubmit={({ collaborationTypes, permissions }) => {
              setCollaborationTypes(collaborationTypes);
              setPermissions(permissions);
              void nextStep();
            }}
            participants={participants}
          />
        )}
      />
      <DataRoomCreationWizardStep
        render={({ previousStep }) => (
          <MediaDataRoomCreationConfigurationStep
            collaborationTypes={collaborationTypes}
            defaultFormValues={configuration ?? undefined}
            isPublished={publishingStep === "completed"}
            isPublishing={publishingStep === "pending"}
            onCancel={previousStep}
            onSubmit={publish}
          />
        )}
      />
    </Wizard>
  );
});
MediaDataRoomCreationWizard.displayName = "MediaDataRoomCreationWizard";

export default MediaDataRoomCreationWizard;
