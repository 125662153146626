import { z } from "zod";
import { DataRoomType, type DataRoomTypeCardPresentationType } from "models";

export interface DataRoomSelectTypeItem
  extends DataRoomTypeCardPresentationType {
  dataRoomType: DataRoomType;
  visible: boolean;
  testId: string;
}

export const DataRoomCreationDataRoomTypeStepSchema = z.object({
  dataRoomType: z.nativeEnum(DataRoomType, {
    message: "Please select data clean room type to create",
  }),
});

export type DataRoomCreationDataRoomTypeStepFormValues = z.infer<
  typeof DataRoomCreationDataRoomTypeStepSchema
>;
