import { testIds } from "@decentriq/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useDatalabsBaseUrl } from "features/dataLabs";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import {
  matchingIdTypes,
  matchingIdTypeToGqlValues,
  RawMatchingID,
} from "models/dataRoom/matchingId";
import { getEffectiveErrorMessage } from "utils";
import { useDataLabsContext } from "../../contexts";

const NUM_EMBEDDINGS_MIN = 10;

const commonFields = {
  matchingId: z.nativeEnum(RawMatchingID, {
    message: "Matching ID is required",
  }),
  name: z.string().min(1, "Name is required"),
  useDemographicsData: z.boolean(),
  useMatchingData: z.boolean(),
  useSegmentsData: z.boolean(),
};

const schema = z.discriminatedUnion("useUserEmbeddingData", [
  z.object({
    numEmbeddings: z.coerce
      .number({
        invalid_type_error: `Embeddings per user must be a number greater than ${NUM_EMBEDDINGS_MIN}`,
        required_error: `Embeddings per user must be a number greater than ${NUM_EMBEDDINGS_MIN}`,
      })
      .min(
        NUM_EMBEDDINGS_MIN,
        `Embeddings per user can't be less than ${NUM_EMBEDDINGS_MIN}`
      ),
    useUserEmbeddingData: z.literal(true),
    ...commonFields,
  }),
  z.object({
    numEmbeddings: z.undefined(),
    useUserEmbeddingData: z.literal(false),
    ...commonFields,
  }),
]);

type SchemaType = z.infer<typeof schema>;

const defaultValues = {
  matchingId: undefined,
  name: "",
  numEmbeddings: undefined,
  useDemographicsData: false,
  useMatchingData: true,
  useSegmentsData: false,
  useUserEmbeddingData: false,
};

const FORM_ID = "create-data-lab-form";

interface DataLabsCreateDialogProps {
  open: boolean;
  onCancel: (id?: string) => void;
}

const DataLabCreateDialog: React.FC<DataLabsCreateDialogProps> = ({
  open,
  onCancel,
}) => {
  const navigate = useNavigate();
  const datalabsBaseUrl = useDatalabsBaseUrl();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DATA_LAB,
  });
  const { createDataLab } = useDataLabsContext();
  const [disabled, setDisabled] = useState(false);
  const form = useForm<SchemaType>({
    defaultValues,
    disabled,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = form;
  const { isSubmitting, isDirty } = formState;
  const useUserEmbeddingData = watch("useUserEmbeddingData");
  const handleCreate = useCallback(
    async (values: SchemaType) => {
      try {
        const response = await createDataLab({
          matchingIdFormat:
            matchingIdTypeToGqlValues[values.matchingId as RawMatchingID][0],
          matchingIdHashingAlgorithm:
            matchingIdTypeToGqlValues[values.matchingId as RawMatchingID][1],
          name: values.name,
          numEmbeddings: values.numEmbeddings || NUM_EMBEDDINGS_MIN, // NOTE: Backend expects a number regardless of `requireEmbeddingsDataset`
          requireDemographicsDataset: values.useDemographicsData,
          requireEmbeddingsDataset: values.useUserEmbeddingData,
          requireSegmentsDataset: values.useSegmentsData,
        });
        const id = response?.data?.dataLab.create.record.id;
        onCancel();
        navigate(`${datalabsBaseUrl}/${id}`);
      } catch (error) {
        enqueueSnackbar("Unable to create datalab", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [createDataLab, enqueueSnackbar, onCancel, navigate, datalabsBaseUrl]
  );
  useEffect(() => {
    if (!useUserEmbeddingData) {
      setValue("numEmbeddings", undefined);
      clearErrors("numEmbeddings");
    }
  }, [clearErrors, setValue, useUserEmbeddingData]);
  useEffect(() => {
    setDisabled(isSubmitting);
  }, [isSubmitting]);
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  return (
    <FormProvider {...form}>
      <form id={FORM_ID} onSubmit={handleSubmit(handleCreate)}>
        <Modal
          disableEscapeKeyDown={isDirty}
          onClose={() => {
            if (!isSubmitting) onCancel();
          }}
          open={open}
        >
          <ModalDialog>
            <DialogTitle>Create new datalab</DialogTitle>
            <Divider />
            <DialogContent>
              <Stack>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => {
                    const { error } = fieldState;
                    return (
                      <FormControl
                        disabled={field.disabled}
                        error={Boolean(error)}
                        required={true}
                      >
                        <FormLabel>Name</FormLabel>
                        <Input
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog.nameInput
                          }
                          placeholder={`e.g. "My datalab name"`}
                          {...field}
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="useMatchingData"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <FormControl
                        disabled={true} // NOTE: User cannot opt-out of matching data
                      >
                        <Checkbox
                          checked={checked}
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog
                              .matchingDataCheckbox
                          }
                          label="Use matching data"
                          {...field}
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="useDemographicsData"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <FormControl disabled={field.disabled}>
                        <Checkbox
                          checked={checked}
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog
                              .demographicDataCheckbox
                          }
                          label="Use demographics data"
                          {...field}
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="useSegmentsData"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <FormControl disabled={field.disabled}>
                        <Checkbox
                          checked={checked}
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog
                              .segmentsDataCheckbox
                          }
                          label="Use segments data"
                          {...field}
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="useUserEmbeddingData"
                  render={({ field: { value, ...field } }) => {
                    return (
                      <FormControl disabled={field.disabled}>
                        <Checkbox
                          checked={value}
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog
                              .userEmbeddingDataCheckbox
                          }
                          label={"Use user embedding data"}
                          {...field}
                        />
                      </FormControl>
                    );
                  }}
                />
                {useUserEmbeddingData ? (
                  <Controller
                    control={control}
                    name="numEmbeddings"
                    render={({ field, fieldState }) => {
                      const { error } = fieldState;
                      return (
                        <FormControl
                          disabled={field.disabled}
                          error={Boolean(error)}
                          required={true}
                        >
                          <FormLabel>Embeddings per user</FormLabel>
                          <Input
                            data-testid={
                              testIds.dataLabs.dataLabCreateDialog
                                .numEmbeddingsInput
                            }
                            placeholder={`e.g. "${NUM_EMBEDDINGS_MIN}"`}
                            slotProps={{ input: { min: NUM_EMBEDDINGS_MIN } }}
                            type="number"
                            {...field}
                          />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                ) : null}
                <Controller
                  control={control}
                  name="matchingId"
                  render={({ field, fieldState }) => {
                    const { error } = fieldState;
                    return (
                      <FormControl
                        disabled={field.disabled}
                        error={Boolean(error)}
                        required={true}
                      >
                        <FormLabel>Matching ID</FormLabel>
                        <Select
                          data-testid={
                            testIds.dataLabs.dataLabCreateDialog
                              .matchingIdSelector
                          }
                          placeholder="No matching ID selected"
                          renderValue={(selectedOption) => {
                            const option = matchingIdTypes.find(
                              (o) => o.value === selectedOption?.value
                            );
                            if (!option) return "No matching ID selected";
                            return option.name;
                          }}
                          {...field}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          value={field.value || null}
                        >
                          {matchingIdTypes.map(({ value, name }, index) => (
                            <Option
                              data-testid={`${testIds.dataLabs.dataLabCreateDialog.matchingIdHelper}${value}`}
                              key={`${value}-${index}`}
                              value={value}
                            >
                              {name}
                            </Option>
                          ))}
                        </Select>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                disabled={disabled}
                form={FORM_ID}
                onClick={() => onCancel()}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                data-testid={testIds.dataLabs.dataLabCreateDialog.submitButton}
                disabled={disabled}
                form={FORM_ID}
                loading={isSubmitting}
                loadingPosition="start"
                type="submit"
                variant="solid"
              >
                Create datalab
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </form>
    </FormProvider>
  );
};

export default DataLabCreateDialog;
