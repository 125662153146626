import {
  data_connector_job,
  type EnclaveSpecification,
  import_dataset,
} from "@decentriq/core";
import {
  DataConnectorJobSetToFailureDocument,
  DataConnectorJobSetToSuccessDocument,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetImportPayload,
  type DataConnectorJob,
  DataConnectorJobDocument,
  DataConnectorJobFragment,
  type DataConnectorJobQuery,
  type DataConnectorJobQueryVariables,
  type DataConnectorJobResult,
  type MutationCreateDatasetImportArgs,
  type MutationPollDatasetImportArgs,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import * as forge from "node-forge";
import { type Keychain, KeychainItemKind } from "services/keychain";
import { type ApiCoreContextValue } from "contexts";
import { getEffectiveErrorMessage, logWarning } from "utils";
import {
  getLatestEnclaveSpecsPerType,
  getLowLevelDependencyNodeId,
  idAsHash,
} from "utils/apicore";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeCreateDatasetImportResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    getKeychain: () => Keychain
  ) =>
  async (
    _obj: null,
    args: MutationCreateDatasetImportArgs,
    context: LocalResolverContext
  ): Promise<CreateDatasetImportPayload> => {
    const {
      s3,
      gcs,
      compute,
      datasetName,
      snowflake,
      azure,
      salesforce,
      permutive,
    } = args.input;
    const encryptionKey = new Key();

    let enclaveSpecifications;
    let enclaveSpecs: Map<string, EnclaveSpecification>;

    try {
      enclaveSpecifications = await client.getEnclaveSpecifications();
      enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
    } catch (error) {
      throw new Error(error as string);
    }

    // Permutive connector uploads at least 3 datasets, so, all of their ids should be stored and looped over
    const datasetImportIds = [];

    if (s3 !== undefined) {
      const { credentials, sourceConfig } = s3!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetS3(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          sourceConfig,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (snowflake !== undefined) {
      const { credentials, sourceConfig } = snowflake!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetSnowflake(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          sourceConfig,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (azure !== undefined) {
      const { credentials } = azure!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetAzure(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (gcs !== undefined) {
      const { credentials, bucketName, objectName } = gcs!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetGCS(
        client,
        session,
        enclaveSpecs,
        {
          bucketName,
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          objectName,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (salesforce !== undefined) {
      const { domainUrl, apiName, productType, credentials } = salesforce!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetSalesforce(
        client,
        session,
        enclaveSpecs,
        {
          apiName,
          credentials,
          datasetName,
          domainUrl,
          encryptionKey,
          name: datasetName,
          productType,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (permutive !== undefined) {
      const { credentials, datasets, configuration, serviceProvider } =
        permutive!;
      const session = await sessionManager.get();
      if (
        serviceProvider === PermutiveServiceProvider.S3 &&
        configuration.aws &&
        credentials.aws
      ) {
        const {
          aws: { bucket, region },
        } = configuration;
        const {
          aws: { accessKey, secretKey },
        } = credentials;
        const permutiveDatasetsIds = await Promise.all(
          (
            Object.values(datasets).filter(
              (datasetName) => datasetName?.trim()?.length
            ) as string[]
          ).map(async (datasetName) =>
            import_dataset.importDatasetS3(client, session, enclaveSpecs, {
              credentials: {
                accessKey,
                secretKey,
              },
              datasetName,
              encryptionKey,
              name: datasetName,
              sourceConfig: {
                bucket,
                objectKey: datasetName,
                region,
              },
            })
          )
        );
        datasetImportIds.push(...permutiveDatasetsIds);
      } else if (
        serviceProvider === PermutiveServiceProvider.GoogleCloudStorage &&
        configuration.gcs &&
        credentials.gcs
      ) {
        const permutiveDatasetsIds = await Promise.all(
          (
            Object.values(datasets).filter(
              (datasetName) => datasetName?.trim()?.length
            ) as string[]
          ).map(async (datasetName) =>
            import_dataset.importDatasetGCS(client, session, enclaveSpecs, {
              bucketName: configuration.gcs.bucketName,
              credentials: credentials.gcs.credentials,
              datasetName,
              encryptionKey,
              name: datasetName,
              objectName: datasetName,
            })
          )
        );
        datasetImportIds.push(...permutiveDatasetsIds);
      }
    } else if (compute !== undefined) {
      const {
        computeNodeId,
        dataRoomId,
        driverAttestationHash,
        shouldImportAsRaw,
        shouldImportAllFiles,
        importFileWithName,
        renameFileTo,
        isHighLevelNode = true,
        parameters,
      } = compute!;
      const session = await sessionManager.get({ driverAttestationHash });

      let lowLevelComputeNodeId;

      let parameterObject: { [key: string]: string } | undefined;
      if (parameters) {
        parameterObject = Object.fromEntries(
          parameters.map((param) => [param.computeNodeName, param.content])
        );
      }

      if (isHighLevelNode) {
        lowLevelComputeNodeId = await getLowLevelDependencyNodeId(
          session,
          dataRoomId,
          computeNodeId
        );
      } else {
        lowLevelComputeNodeId = computeNodeId;
      }

      const importOptions = {
        computeNodeId: lowLevelComputeNodeId,
        dataRoomId,
        datasetName,
        encryptionKey,
        importFileWithName: importFileWithName || undefined,
        name: datasetName,
        parameters: parameterObject,
        renameFileTo: renameFileTo || undefined,
        shouldImportAllFiles: shouldImportAllFiles || false,
        shouldImportAsRaw: shouldImportAsRaw || false,
      };
      const datasetImportId = await import_dataset.importDatasetCompute(
        client,
        session,
        enclaveSpecs,
        importOptions
      );
      datasetImportIds.push(datasetImportId);
    } else {
      throw new Error("No source type configured");
    }

    const dataConnectorJobs: DataConnectorJob[] = [];

    for (const datasetImportId of datasetImportIds) {
      await getKeychain().insertItem({
        id: datasetImportId,
        kind: KeychainItemKind.PendingDatasetImport,
        value: encryptionKey.toHex(),
      });

      const response = await context.client.query<
        DataConnectorJobQuery,
        DataConnectorJobQueryVariables
      >({
        query: DataConnectorJobDocument,
        variables: {
          id: datasetImportId,
        },
      });
      const newDatasetImport = response.data.dataConnectorJob;
      dataConnectorJobs.push(newDatasetImport);

      context.cache.modify({
        fields: {
          dataConnectorJobs: (existing = {}) => {
            const datasetImportRef = context.cache.writeFragment({
              data: response.data.dataConnectorJob,
              fragment: DataConnectorJobFragment,
            });
            return {
              ...existing,
              nodes: [datasetImportRef, ...(existing?.nodes || [])],
            };
          },
        },
      });
    }

    // TODO: rewrite to datasetImport: { nodes: [...newDatasetImports] } across the app in order to handle multiple Permutive datasets
    return {
      dataConnectorJob: dataConnectorJobs[dataConnectorJobs.length - 1],
    };
  };

export const makePollDatasetImportResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    getKeychain: () => Keychain
  ) =>
  async (
    _obj: null,
    args: MutationPollDatasetImportArgs,
    context: LocalResolverContext
  ): Promise<DataConnectorJobResult> => {
    const datasetImportId = args.id;

    const enclaveSpecifications = await client.getEnclaveSpecifications();

    /**
     * Should only throw an error, if storing the dataset key fails
     * If the job fails, the error is shown in the Dataset Drawer
     */
    let importResult;
    let error;
    let success;
    let shouldThrowError = false;

    try {
      importResult = await data_connector_job.getDataConnectorJobResult({
        client,
        dataConnectorJobId: datasetImportId,
        options: {
          additionalEnclaveSpecs: enclaveSpecifications,
        },
      });
      success = true;
    } catch (e) {
      error = getEffectiveErrorMessage(e);
      success = false;
    }

    // Insert new dataset->key entries in case the import was succesful.
    if (importResult !== undefined) {
      const keychain = getKeychain();

      const matchingPendingImports = await keychain.getItem(
        datasetImportId,
        KeychainItemKind.PendingDatasetImport
      );

      const encryptionKeyBytes = forge.util.binary.hex.decode(
        matchingPendingImports.value
      );
      for (const datasetMeta of importResult.datasets) {
        try {
          await keychain.insertItem({
            id: datasetMeta.manifestHash,
            kind: KeychainItemKind.Dataset,
            // The UI stores the encryption key this way
            value: idAsHash(encryptionKeyBytes)!,
          });
        } catch (storeEncryptionKeyError) {
          logWarning(
            `Error when trying to insert new dataset->key mapping for finished import (id: ${datasetImportId}): `,
            storeEncryptionKeyError
          );
          error = getEffectiveErrorMessage(storeEncryptionKeyError);
          success = false;
          shouldThrowError = true;
        }
      }
    }

    const result: DataConnectorJobResult = {
      error: error ? error : null,
      success,
    };

    if (!success) {
      await context.client.mutate({
        mutation: DataConnectorJobSetToFailureDocument,
        variables: {
          id: datasetImportId,
        },
      });
      if (shouldThrowError) {
        throw new Error(error);
      }
      return result;
    }

    await context.client.mutate({
      mutation: DataConnectorJobSetToSuccessDocument,
      variables: {
        id: datasetImportId,
      },
    });
    // TODO check if it updates the cache on success
    await context.client.query<
      DataConnectorJobQuery,
      DataConnectorJobQueryVariables
    >({
      fetchPolicy: "network-only",
      query: DataConnectorJobDocument,
      variables: {
        id: datasetImportId,
      },
    });

    return result;
  };
