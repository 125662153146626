import { DqTable } from "@decentriq/components";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  ALL_PUBLISHER_USERS_AUDIENCE,
  type Audience,
  type AudienceKind,
  audienceTypePresentationMap,
} from "features/mediaDataRoom/models";
import {
  ActionsMenu,
  AudienceCreationDate,
  AudienceName,
  AudienceSize,
  AudienceStatusLabel,
  ExportAudienceButton,
  MediaDataRoomAudienceDrawer,
} from "./components";

interface MediaDataRoomAudiencesTableProps {
  audiences: Audience[];
  isFiltered?: boolean;
}

const MediaDataRoomAudiencesTable: React.FC<
  MediaDataRoomAudiencesTableProps
> = ({ audiences, isFiltered }) => {
  const {
    supportedFeatures: {
      canExportAudience,
      showAbsoluteValues,
      canMakeAvailableForPublisher,
      canDeleteAudience,
      canProvideBaseAudience,
      canViewAudienceStatus,
    },
  } = useMediaDataRoom();
  const [selectedAudienceId, setSelectedAudienceId] = useState<string | null>(
    null
  );
  // TODO: This will eventually be migrated to the audiences context state
  const selectedAudienceWithSeedAudience = useMemo<
    (Audience & { seedAudience: Audience | null }) | null
  >(() => {
    if (!selectedAudienceId) {
      return null;
    }
    const audience = audiences.find((a) => a.id === selectedAudienceId);
    if (!audience) {
      return null;
    }
    if (audience.kind === "advertiser") {
      return { ...audience, seedAudience: null };
    }
    return {
      ...audience,
      seedAudience:
        [...audiences, ALL_PUBLISHER_USERS_AUDIENCE].find(
          (a) => a.id === audience.source_ref
        ) ?? null,
    };
  }, [audiences, selectedAudienceId]);

  const noRecordsToDisplay = useMemo(() => {
    if (isFiltered) {
      return "No results found";
    }
    return canProvideBaseAudience
      ? "The advertiser has not made any audience available for activation yet."
      : "The model is ready, please create your first audience.";
  }, [canProvideBaseAudience, isFiltered]);
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      // Name
      {
        Cell: ({
          row: {
            original: {
              mutable: { name },
              kind,
            },
          },
        }) => <AudienceName kind={kind} name={name} />,
        accessorKey: "mutable.name",
        grow: true,
        header: "Audience name",
      },
      // Kind
      {
        Cell: ({ cell }) => {
          const kind = cell.getValue<AudienceKind>();
          return audienceTypePresentationMap.get(kind) || "N/A";
        },
        accessorKey: "kind",
        header: "Audience type",
      },
      // Size
      {
        Cell: ({
          row: {
            original: { id, kind, audience_size },
          },
        }) => <AudienceSize audienceSize={audience_size} id={id} kind={kind} />,
        accessorKey: "audience_size",
        header: "Audience size",
      },
      // Creation Date
      {
        Cell: ({ cell }) => (
          <AudienceCreationDate
            createdAt={cell.getValue<Audience["mutable"]>().created_at}
          />
        ),
        accessorKey: "mutable",
        header: "Creation date",
      },
      // Status
      {
        Cell: ({
          row: {
            original: {
              mutable: { status },
              id,
            },
          },
        }) => {
          return <AudienceStatusLabel id={id} status={status} />;
        },
        Header: () => (
          <Stack alignItems="center" direction="row" gap={1}>
            Status
            <Tooltip
              title={
                <Typography level="body-sm" sx={{ color: "white" }}>
                  Published: Audience is live and available for campaigns.
                  <br />
                  Ready: Audience is generated and ready but not published.
                  <br />
                  Computing: Audience is still processing and will be available
                  when complete.
                </Typography>
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
          </Stack>
        ),
        header: "Status",
        id: "mutable.status",
      },
    ],
    []
  );
  return (
    <>
      <DqTable
        columns={columns}
        data={audiences}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            enableResizing: false,
            grow: false,
            header: "Actions",
            muiTableBodyCellProps: {
              align: "left",
              onClick: (event) => event.stopPropagation(),
              sx: {
                minWidth:
                  "max(calc(var(--col-mrt_row_actions-size)* 1px), 36px)",
              },
            },
            muiTableHeadCellProps: {
              align: "left",
              sx: {
                fontWeight: "semiBold",
                minWidth:
                  "max(calc(var(--header-mrt_row_actions-size)* 1px), 36px)",
              },
            },
            size: 200,
          },
        }}
        enableRowActions={
          canExportAudience || canMakeAvailableForPublisher || canDeleteAudience
        }
        getRowId={(row) => row.id}
        initialState={{
          columnVisibility: {
            audience_size: showAbsoluteValues,
            status: canViewAudienceStatus,
          },
        }}
        localization={{
          noRecordsToDisplay,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => setSelectedAudienceId(row.original.id),
          sx: { cursor: "pointer" },
        })}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
          },
        }}
        renderRowActions={({ row }) => (
          <Stack direction="row" flex={1} gap={1}>
            {canExportAudience && (
              <ExportAudienceButton audience={row.original} />
            )}
            {canMakeAvailableForPublisher || canDeleteAudience ? (
              <ActionsMenu
                audience={row.original}
                mode={canExportAudience ? "dropdown" : "icons"}
              />
            ) : null}
          </Stack>
        )}
      />
      <MediaDataRoomAudienceDrawer
        audience={selectedAudienceWithSeedAudience}
        hasExportButton={canExportAudience}
        onClose={() => setSelectedAudienceId(null)}
        open={Boolean(selectedAudienceWithSeedAudience)}
        seedAudience={selectedAudienceWithSeedAudience?.seedAudience ?? null}
      />
    </>
  );
};

export default MediaDataRoomAudiencesTable;
