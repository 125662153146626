import { type MediaDataRoomOrganizationRole } from "@decentriq/types";
import { createContext, type SetStateAction, useContext } from "react";
import { type OrganizationPreferences } from "hooks";
import { type MediaDataRoomCreationStep } from "../../types";

export interface CreationWizardStepperContextValue {
  activeStep: MediaDataRoomCreationStep;
  setActiveStep: React.Dispatch<SetStateAction<MediaDataRoomCreationStep>>;
  canSelectDataPartner: boolean;
  hasOwnDataToProvide: boolean;
  setHasOwnDataToProvide: React.Dispatch<SetStateAction<boolean>>;
  organizationRole: MediaDataRoomOrganizationRole | null;
  setOrganizationRole: (
    role: SetStateAction<MediaDataRoomOrganizationRole | null>
  ) => void;
  handleNextStep: () => void;
  handleBackStep: () => void;
  organizationPreferences: OrganizationPreferences;
}

export const CreationWizardStepperContext =
  createContext<CreationWizardStepperContextValue | null>(null);

export const CreationWizardStepperContextProvider =
  CreationWizardStepperContext.Provider;

export const useCreationWizardStepper = () => {
  const contextValue = useContext(CreationWizardStepperContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardStepper must be used within a CreationWizardStepperContextProvider"
    );
  }
  return contextValue;
};
