import { testIds } from "@decentriq/utils";
import { z } from "zod";
import {
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRoleCardPresentation,
  type MediaDataRoomOrganizationRoleCardPresentationType,
} from "features/dataRoomCreation/models";

export const MediaDataRoomCreationParticipantRoleSchema = z.object({
  participantRole: z.nativeEnum(MediaDataRoomOrganizationRole, {
    message: "Please select the participants role.",
  }),
});

export type MediaDataRoomCreationParticipantRoleFormValues = z.infer<
  typeof MediaDataRoomCreationParticipantRoleSchema
>;

export interface MediaDataRoomParticipantRoleCardPresentationType
  extends MediaDataRoomOrganizationRoleCardPresentationType {
  testId: string;
}

export const mediaDataRoomParticipantRoleCardPresentation: Record<
  MediaDataRoomOrganizationRole,
  MediaDataRoomParticipantRoleCardPresentationType
> = {
  [MediaDataRoomOrganizationRole.PUBLISHER]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.PUBLISHER
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.publisher,
  },
  [MediaDataRoomOrganizationRole.ADVERTISER]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.ADVERTISER
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.advertiser,
  },
  [MediaDataRoomOrganizationRole.DATA_PARTNER]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.DATA_PARTNER
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.dataPartner,
  },
  [MediaDataRoomOrganizationRole.AGENCY]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.AGENCY
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.agency,
  },
  [MediaDataRoomOrganizationRole.OBSERVER]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.OBSERVER
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.observer,
  },
  [MediaDataRoomOrganizationRole.CUSTOM]: {
    ...mediaDataRoomOrganizationRoleCardPresentation[
      MediaDataRoomOrganizationRole.CUSTOM
    ],
    testId: testIds.dataRoomCreation.participantRoleSelect.custom,
  },
};

export const availableRoles: MediaDataRoomOrganizationRole[] = [
  MediaDataRoomOrganizationRole.ADVERTISER,
  MediaDataRoomOrganizationRole.PUBLISHER,
  MediaDataRoomOrganizationRole.DATA_PARTNER,
  MediaDataRoomOrganizationRole.AGENCY,
  MediaDataRoomOrganizationRole.OBSERVER,
  MediaDataRoomOrganizationRole.CUSTOM,
];
