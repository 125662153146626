import { memo } from "react";
import { useWizard, type WizardValues } from "react-use-wizard";

type DataRoomCreationWizardStepProps = React.PropsWithChildren<{
  render: (wizard: WizardValues) => React.ReactNode;
}>;

const DataRoomCreationWizardStep = memo<DataRoomCreationWizardStepProps>(
  ({ render }) => render(useWizard())
);
DataRoomCreationWizardStep.displayName = "DataRoomCreationWizardStep";

export default DataRoomCreationWizardStep;
