import { DqTable } from "@decentriq/components";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Radio, Stack, Tooltip, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  activationCollaborationTypes,
  MediaDataRoomCollaborationType,
  type MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
  MediaDataRoomOrganizationPermission,
  mediaDataRoomOrganizationPermissionsPresentation,
  type MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRolePresentationMap,
  mediaDataRoomParticipantPermissionConfigsCreator,
} from "features/dataRoomCreation/models";
import { getAllDataRoomParticipantsPermissionsByRole } from "./utils";

interface MediaDataRoomCreationPermissionsFormProps {
  defaultFormParticipants: MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["participants"];
}

const MediaDataRoomCreationPermissionsForm =
  memo<MediaDataRoomCreationPermissionsFormProps>(
    ({ defaultFormParticipants }) => {
      const { watch, setValue, getValues } =
        useFormContext<MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues>();
      const collaborationTypesFormValues = watch("collaborationTypes");
      const hasInsightsEnabled =
        collaborationTypesFormValues[MediaDataRoomCollaborationType.INSIGHTS];
      const participantPermissionConfigs = useMemo(
        () =>
          getAllDataRoomParticipantsPermissionsByRole(
            collaborationTypesFormValues
          ),
        [collaborationTypesFormValues]
      );
      useEffect(() => {
        setValue(
          "participants",
          getValues("participants").map((participant) => ({
            ...participant,
            permissions: {
              ...participant.permissions,
              [MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]:
                hasInsightsEnabled &&
                (mediaDataRoomParticipantPermissionConfigsCreator(
                  participant.role,
                  [MediaDataRoomCollaborationType.INSIGHTS]
                )[MediaDataRoomOrganizationPermission.VIEW_INSIGHTS]
                  .defaultValue ??
                  false),
            },
          }))
        );
      }, [hasInsightsEnabled, setValue, getValues]);
      const hasActivationEnabled = useMemo(
        () =>
          activationCollaborationTypes.some(
            (collaborationType) =>
              collaborationTypesFormValues[collaborationType]
          ),
        [collaborationTypesFormValues]
      );
      useEffect(() => {
        setValue(
          "participants",
          getValues("participants").map((participant) => ({
            ...participant,
            permissions: {
              ...participant.permissions,
              ...[
                MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES,
                MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES,
              ].reduce(
                (acc, permission) => ({
                  ...acc,
                  [permission]:
                    hasActivationEnabled &&
                    (mediaDataRoomParticipantPermissionConfigsCreator(
                      participant.role,
                      activationCollaborationTypes
                    )[permission].defaultValue ??
                      false),
                }),
                {}
              ),
            },
          }))
        );
      }, [hasActivationEnabled, setValue, getValues]);
      const permissionsTableDefinition = useMemo<
        MRT_ColumnDef<
          MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["participants"][number]
        >[]
      >(
        () => [
          {
            accessorKey: "name",
            header: "Participant",
          },
          {
            Cell: ({ cell }) => {
              const role = cell.getValue<MediaDataRoomOrganizationRole>();
              return mediaDataRoomOrganizationRolePresentationMap[role];
            },
            accessorKey: "role",
            header: "Role",
            size: 100,
          },
          ...[
            MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE,
            MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE,
          ].map<
            MRT_ColumnDef<
              MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["participants"][number]
            >
          >((permission) => {
            const { title: header, description: tooltip } =
              mediaDataRoomOrganizationPermissionsPresentation[permission];
            return {
              Cell: ({
                row: {
                  original: { id, role },
                },
              }) => (
                <Stack direction="row" justifyContent="center" width="100%">
                  <Controller<
                    MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
                    | MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE
                    | MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE
                  >
                    name={
                      permission as
                        | MediaDataRoomOrganizationPermission.PROVIDE_BASE_AUDIENCE
                        | MediaDataRoomOrganizationPermission.PROVIDE_SEED_AUDIENCE
                    }
                    render={({ field }) => (
                      <Radio
                        checked={field.value === id}
                        disabled={
                          !participantPermissionConfigs[role][permission]
                            .enabled
                        }
                        onChange={field.onChange}
                        value={id}
                      />
                    )}
                  />
                </Stack>
              ),
              Header: () => (
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  justifyContent="center"
                >
                  {header}
                  &nbsp;
                  <Tooltip
                    title={
                      <Typography level="body-sm" sx={{ color: "white" }}>
                        {tooltip}
                      </Typography>
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                </Stack>
              ),
              accessorKey: permission,
              header,
              muiTableHeadCellProps: {
                align: "center",
              },
            };
          }),
          ...[
            MediaDataRoomOrganizationPermission.VIEW_OVERLAP,
            MediaDataRoomOrganizationPermission.VIEW_INSIGHTS,
            MediaDataRoomOrganizationPermission.CAN_CREATE_AUDIENCES,
            MediaDataRoomOrganizationPermission.CAN_EXPORT_AUDIENCES,
          ].map<
            MRT_ColumnDef<
              MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues["participants"][number]
            >
          >((permission) => {
            const { title: header, description: tooltip } =
              mediaDataRoomOrganizationPermissionsPresentation[permission];
            return {
              Cell: ({ row }) => {
                const participant = row.original;
                const permissionConfig =
                  participantPermissionConfigs[participant.role][permission];
                return (
                  <Stack direction="row" justifyContent="center" width="100%">
                    <Controller
                      name={`participants.${row.index}.permissions.${permission}`}
                      render={({ field }) => (
                        <Tooltip
                          title={
                            permissionConfig.prohibited
                              ? "This permission cannot be enabled for this role."
                              : !permissionConfig.enabled
                                ? "Disabled due to missing permissions. Select the appropriate collaboration type to enable."
                                : undefined
                          }
                        >
                          <Checkbox
                            checked={field.value as boolean}
                            disabled={!permissionConfig.enabled}
                            onChange={field.onChange}
                          />
                        </Tooltip>
                      )}
                    />
                  </Stack>
                );
              },
              Header: () => (
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  justifyContent="center"
                >
                  {header}
                  &nbsp;
                  <Tooltip
                    title={
                      <Typography level="body-sm" sx={{ color: "white" }}>
                        {tooltip}
                      </Typography>
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                </Stack>
              ),
              header,
              muiTableHeadCellProps: {
                align: "center",
              },
            };
          }),
        ],
        [participantPermissionConfigs]
      );
      return (
        <DqTable
          columns={permissionsTableDefinition}
          data={defaultFormParticipants}
          getRowId={(row) => row.id.toString()}
          muiTablePaperProps={{
            sx: {
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "hidden",
              width: "100%",
            },
          }}
        />
      );
    }
  );
MediaDataRoomCreationPermissionsForm.displayName =
  "MediaDataRoomCreationPermissionsForm";

export default MediaDataRoomCreationPermissionsForm;
