import { Stack } from "@mui/joy";
import { Fragment, memo } from "react";
import {
  AdvancedAnalyticsDcrFeatures,
  DmpFeatures,
  MeasurementDcrFeatures,
  MediaDcrFeatures,
  OrganizationArchiveButton,
  OrganizationComputeTimeoutEditor,
  OrganizationConnectorStates,
  OrganizationDetails,
  OrganizationDomainEditor,
  OrganizationFeaturesDisplay,
  OrganizationLicenseEditor,
  OrganizationLogoEditor,
  OrganizationMediaScopeEditor,
  OrganizationNameEditor,
} from "features/admin/components";
import {
  OrganizationAdminConfigurationWrapper,
  useOrganizationAdminConfiguration,
} from "../../contexts";

const OrganizationAdminConfiguration = memo(() => {
  const {
    showArchiveButton,
    showOrganizationDetails,
    showMediaScopeEditor,
    showComputeTimeoutEditor,
    showFeaturesEditor,
    showConnectorStates,
  } = useOrganizationAdminConfiguration();
  return (
    <Stack spacing={2} sx={{ maxWidth: 600, p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <OrganizationLogoEditor />
        {showArchiveButton && <OrganizationArchiveButton />}
      </Stack>
      <OrganizationNameEditor />
      <OrganizationLicenseEditor />
      {showOrganizationDetails && <OrganizationDetails />}
      <OrganizationDomainEditor />
      {showMediaScopeEditor && <OrganizationMediaScopeEditor />}
      {showComputeTimeoutEditor && <OrganizationComputeTimeoutEditor />}
      {showFeaturesEditor ? (
        <Fragment>
          <AdvancedAnalyticsDcrFeatures />
          <MediaDcrFeatures />
          <MeasurementDcrFeatures />
          <DmpFeatures />
        </Fragment>
      ) : (
        <OrganizationFeaturesDisplay />
      )}
      {showConnectorStates && <OrganizationConnectorStates />}
    </Stack>
  );
});

interface OrganizationAdminConfigurationWithWrapperProps {
  organizationId: string;
}

const OrganizationAdminConfigurationWithWrapper =
  memo<OrganizationAdminConfigurationWithWrapperProps>(({ organizationId }) => {
    return (
      <OrganizationAdminConfigurationWrapper organizationId={organizationId}>
        <OrganizationAdminConfiguration />
      </OrganizationAdminConfigurationWrapper>
    );
  });

OrganizationAdminConfigurationWithWrapper.displayName =
  "OrganizationAdminConfigurationWithWrapper";

export default OrganizationAdminConfigurationWithWrapper;
