import { memo } from "react";
import { DataRoomCreationWizard } from "features/dataRoomCreation";

const CreateDataRoomPage = memo(() => {
  return <DataRoomCreationWizard />;
});

CreateDataRoomPage.displayName = "CreateDataRoomPage";

export default CreateDataRoomPage;
