import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import { memo, useEffect, useMemo, useRef } from "react";
import { EnclaveKeychain, type Keychain } from "services/keychain";
import { KeychainProvider, useApiCore } from "contexts";

export const useKeychainSharedInstance = () => {
  const clientRef = useRef<Keychain | null>(null);
  return useMemo<[() => Keychain | null, (client: Keychain) => void]>(
    () => [
      () => clientRef.current,
      (client: Keychain) => {
        clientRef.current = client;
      },
    ],
    [clientRef]
  );
};

const KeychainWrapper = memo<
  React.PropsWithChildren & {
    onKeychainInstanceUpdated: (keychain: Keychain) => void;
  }
>(({ children, onKeychainInstanceUpdated }) => {
  const { client, getSessionV2 } = useApiCore();
  const apolloClient = useApolloClient();
  // TODO check if this won't get re-instantiated.
  const keychain = useMemo(
    () =>
      new EnclaveKeychain(
        client.userEmail,
        client,
        apolloClient as ApolloClient<NormalizedCacheObject>,
        getSessionV2
      ),
    [apolloClient, client, getSessionV2]
  );

  useEffect(() => {
    onKeychainInstanceUpdated(keychain);
  }, [onKeychainInstanceUpdated, keychain]);

  return <KeychainProvider value={keychain}>{children}</KeychainProvider>;
});

KeychainWrapper.displayName = "KeychainServiceWrapper";

export default KeychainWrapper;
