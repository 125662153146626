export interface KeychainItem {
  id: string;
  kind: KeychainItemKind;
  value: string;
}

export enum KeychainItemKind {
  Dataset = "DATASET",
  DatasetMetadata = "DATASET_METADATA",
  PendingDatasetImport = "PENDING_DATASET_IMPORT",
}

export interface Keychain {
  insertItem(item: KeychainItem): Promise<KeychainItem>;
  insertItems(items: KeychainItem[]): Promise<KeychainItem[]>;
  getItem(id: string, kind: KeychainItemKind): Promise<KeychainItem>;
  getItems(): Promise<KeychainItem[]>;
  registerListener(callback: (() => void) | (() => Promise<void>)): () => void;
}
