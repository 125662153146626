import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Grid, Stack, styled, Typography } from "@mui/joy";
import { groupBy } from "lodash";
import { memo, type MouseEventHandler, useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  MediaDataRoomCollaborationType,
  mediaDataRoomCollaborationTypesCardPresentation,
  type MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
  MediaDataRoomCreationCollaborationTypesAndPermissionsStepSchema,
  type MediaDataRoomOrganizationPermission,
  type MediaDataRoomParticipant,
} from "features/dataRoomCreation/models";
import { DataRoomType } from "models";
import {
  CreateDataRoomStepActionsContainer,
  CreateDataRoomStepContainer,
  CreateDataRoomStepContentContainer,
  CreateDataRoomStepHeader,
} from "../CreateDataRoomStep";
import MediaDataRoomCreationCollaborationTypeCardFormControl from "./MediaDataRoomCreationCollaborationTypeCardFormControl";
import MediaDataRoomCreationPermissionsForm from "./MediaDataRoomCreationPermissionsForm";
import { mediaDataRoomCollaborationTypes } from "./models";
import {
  flattenMediaDataRoomCreationCollaborationTypesAndPermissionsStepFormFieldErrors,
  mapCollaborationTypesAndParticipantsToFormValues,
  mapFormValuesToCollaborationTypesAndPermissions,
} from "./utils";

const CreateDataRoomCollaborationTypesAndPermissionsStepContentContainer =
  styled(CreateDataRoomStepContentContainer)(({ theme }) => ({
    maxWidth: theme.breakpoints.values.xl,
  }));

export interface MediaDataRoomCreationCollaborationTypesAndPermissionsStepProps {
  onSubmit: (formValue: {
    collaborationTypes: MediaDataRoomCollaborationType[];
    permissions: Record<string, MediaDataRoomOrganizationPermission[]>;
  }) => void;
  participants: MediaDataRoomParticipant[];
  collaborationTypes?: MediaDataRoomCollaborationType[];
  onCancel: (collaborationTypes: MediaDataRoomCollaborationType[]) => void;
}

const MediaDataRoomCreationCollaborationTypesAndPermissionsStep =
  memo<MediaDataRoomCreationCollaborationTypesAndPermissionsStepProps>(
    ({ onSubmit, onCancel, collaborationTypes, participants }) => {
      const defaultFormValues = useMemo(
        () =>
          mapCollaborationTypesAndParticipantsToFormValues(
            collaborationTypes ?? [
              MediaDataRoomCollaborationType.OVERLAP_ANALYSIS,
            ],
            participants
          ),
        [collaborationTypes, participants]
      );
      const form =
        useForm<MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues>(
          {
            criteriaMode: "all",
            defaultValues: defaultFormValues,
            mode: "onSubmit",
            resolver: zodResolver(
              MediaDataRoomCreationCollaborationTypesAndPermissionsStepSchema
            ),
          }
        );
      const {
        reset: resetForm,
        handleSubmit: handleFormSubmit,
        getValues,
        formState: { errors },
      } = form;
      const errorMessages = useMemo(
        () =>
          flattenMediaDataRoomCreationCollaborationTypesAndPermissionsStepFormFieldErrors(
            errors
          ),
        [errors]
      );
      const handleCancel = useCallback(() => {
        resetForm();
        const formValues = getValues();
        onCancel?.(
          mapFormValuesToCollaborationTypesAndPermissions(formValues)
            .collaborationTypes
        );
      }, [onCancel, resetForm, getValues]);
      const handleSubmit = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
          void handleFormSubmit((formValues) => {
            onSubmit?.(
              mapFormValuesToCollaborationTypesAndPermissions(formValues)
            );
          })();
        },
        [onSubmit, handleFormSubmit]
      );
      const collaborationTypesGrouped = useMemo(
        () =>
          Object.entries(
            groupBy(
              mediaDataRoomCollaborationTypes.map(
                (type) => mediaDataRoomCollaborationTypesCardPresentation[type]
              ),
              "group"
            )
          ),
        []
      );
      return (
        <FormProvider {...form}>
          <CreateDataRoomStepContainer>
            <CreateDataRoomStepHeader
              dataRoomType={DataRoomType.MediaInsights}
            />
            <CreateDataRoomCollaborationTypesAndPermissionsStepContentContainer>
              <Stack>
                <Typography level="h4">
                  Select collaboration types for this Media DCR
                </Typography>
                <Stack direction="column" spacing={2}>
                  {collaborationTypesGrouped.map(([groupName, items]) => (
                    <Stack direction="column" key={groupName} spacing={2}>
                      <Typography level="title-md">{groupName}</Typography>
                      <Grid container={true} spacing={2}>
                        {items.map((item) => (
                          <MediaDataRoomCreationCollaborationTypeCardFormControl
                            key={item.collaborationType}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack>
                <Typography level="h4">Set permissions</Typography>
                <MediaDataRoomCreationPermissionsForm
                  defaultFormParticipants={defaultFormValues.participants}
                />
              </Stack>
              {errorMessages.length > 0 && (
                <Alert color="danger" sx={{ mt: "auto" }}>
                  <ol style={{ margin: 0, paddingLeft: "32px" }}>
                    {errorMessages.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ol>
                </Alert>
              )}
            </CreateDataRoomCollaborationTypesAndPermissionsStepContentContainer>
            <CreateDataRoomStepActionsContainer>
              <Button onClick={handleCancel}>Back</Button>
              <Button color="primary" onClick={handleSubmit} variant="solid">
                Continue
              </Button>
            </CreateDataRoomStepActionsContainer>
          </CreateDataRoomStepContainer>
        </FormProvider>
      );
    }
  );
MediaDataRoomCreationCollaborationTypesAndPermissionsStep.displayName =
  "MediaDataRoomCreationCollaborationTypesAndPermissionsStep";

export default MediaDataRoomCreationCollaborationTypesAndPermissionsStep;
