import { memo } from "react";
import { Navigate, Outlet, useResolvedPath, useRoutes } from "react-router-dom";
import { DatalabWithIdFromParams } from "features/dataLabs";
import {
  DataPartnerPortal,
  DataPartnerPortalTabs,
} from "features/dataPartnerPortal";
import { useOrganizationPreferences } from "hooks";

const DataPartnerPortalPage = memo(() => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const { hasPublisherFeatures } = useOrganizationPreferences();

  const element = useRoutes([
    ...(hasPublisherFeatures
      ? []
      : [
          {
            children: [
              {
                element: (
                  <DataPartnerPortal
                    activeTab={DataPartnerPortalTabs.DATALABS}
                    baseUrl={url}
                  />
                ),
                path: "",
              },
              {
                element: <DatalabWithIdFromParams />,
                path: ":datalabId",
              },
            ],
            element: <Outlet />,
            path: `${DataPartnerPortalTabs.DATALABS}/*`,
          },
        ]),
    {
      element: (
        <DataPartnerPortal
          activeTab={DataPartnerPortalTabs.CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: DataPartnerPortalTabs.CONFIGURATIONS,
    },
    {
      element: (
        <Navigate to={`${url}/${DataPartnerPortalTabs.CONFIGURATIONS}`} />
      ),
      path: "/",
    },
    {
      element: (
        <Navigate to={`${url}/${DataPartnerPortalTabs.CONFIGURATIONS}`} />
      ),
      path: "*",
    },
  ]);

  return element;
});

DataPartnerPortalPage.displayName = "DataPartnerPortalPage";

export default DataPartnerPortalPage;
