import { MediaDataRoomCollaborationType } from "features/dataRoomCreation/models";

export const mediaDataRoomCollaborationTypes: MediaDataRoomCollaborationType[] =
  [
    MediaDataRoomCollaborationType.OVERLAP_ANALYSIS,
    MediaDataRoomCollaborationType.INSIGHTS,
    MediaDataRoomCollaborationType.REMARKETING_AUDIENCES,
    MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES,
    MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES,
  ];
