import { DqCard, DqCardContent } from "@decentriq/components";
import {
  faDatabase,
  faFileImport,
  faFlask,
  faNewspaper,
  faTable,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useOrganizationPreferences } from "hooks";

interface OtherPageLink {
  title: string;
  icon: IconDefinition;
  link: string;
}

const OtherPagesBlock = memo(() => {
  const { hasDataPartnerFeatures, hasDatalabFeatures, hasPublisherFeatures } =
    useOrganizationPreferences();

  const PAGES_LINKS = useMemo<OtherPageLink[]>(
    () => [
      {
        icon: faTable,
        link: "/datasets/datasets",
        title: "Datasets",
      },
      ...(hasPublisherFeatures
        ? [
            {
              icon: faNewspaper,
              link: "/publisherportal",
              title: "Publisher portal",
            },
          ]
        : []),
      ...(hasDataPartnerFeatures
        ? [
            {
              icon: faDatabase,
              link: "/data-partner-portal",
              title: "Data partner portal",
            },
          ]
        : []),
      ...(hasPublisherFeatures || hasDataPartnerFeatures || hasDatalabFeatures
        ? [
            {
              icon: faFlask,
              link: hasPublisherFeatures
                ? "/publisherportal/datalabs"
                : hasDataPartnerFeatures
                  ? "/data-partner-portal/datalabs"
                  : hasDatalabFeatures
                    ? "/datalabs"
                    : "",
              title: "Datalabs",
            },
          ]
        : [
            {
              icon: faFileImport,
              link: "/datasets/external",
              title: "Imports/Exports",
            },
          ]),
    ],
    [hasDataPartnerFeatures, hasDatalabFeatures, hasPublisherFeatures]
  );

  return (
    <Stack height="100%">
      <Typography fontWeight={500} level="body-md">
        Other pages
      </Typography>
      <DqCard>
        <DqCardContent alignItems="stretch" justifyContent="flex-start">
          {PAGES_LINKS.map(({ title, link, icon }) => (
            <Button
              color="primary"
              component={NavLink}
              key={link}
              startDecorator={<FontAwesomeIcon fixedWidth={true} icon={icon} />}
              sx={{ justifyContent: "flex-start" }}
              to={link}
              variant="plain"
            >
              {title}
            </Button>
          ))}
        </DqCardContent>
      </DqCard>
    </Stack>
  );
});

OtherPagesBlock.displayName = "OtherPagesBlock";

export default OtherPagesBlock;
