import { useAuth0 } from "@auth0/auth0-react";
import {
  Client,
  enclaveSpecifications,
  SessionManager,
  UserToken,
} from "@decentriq/core";
import { attestation as attestationProto } from "@decentriq/proto";
import * as forge from "node-forge";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Loading } from "components";
import {
  type ApiCoreContextValue,
  ApiCoreProvider,
} from "contexts/apicore/apicore";
import { useConfiguration } from "contexts/configuration/configuration";
import { clearDecentriqStorage, logInfo } from "utils";
import { type EnclaveToken, useEnclaveTokenStorage } from "wrappers";
import { Store } from "./utils";

interface ApiCoreWrapperProps {
  children?: React.ReactNode;
}

const ApiCoreWrapper: React.FC<ApiCoreWrapperProps> = memo(({ children }) => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const [store] = useState(new Store());
  const { state: storageEnclaveToken, setState: setStorageEnclaveToken } =
    useEnclaveTokenStorage();

  const {
    auth0RelayClientId: clientId,
    diswaHost: host,
    diswaPort: port,
    diswaUseTls: useTls,
    unsafeDisableKnownRootCaCheck,
    clientLogRequests: logRequests,
    environment,
    insecureEnclavesEnabled,
  } = useConfiguration();
  const [client, setClient] = useState<Client | undefined>();
  const [sessionManager, setApiCoreSessionManager] = useState<
    SessionManager | undefined
  >();

  const [driverMrsignerAttestationSpec, driverMrsignerAttestationSpecHash] =
    useMemo(() => {
      const spec = getDriverDcapMrsignerAttestationSpecification(
        environment,
        insecureEnclavesEnabled
      );
      const md = forge.md.sha256.create();
      md.update(
        forge.util.binary.raw.encode(
          attestationProto.AttestationSpecification.encodeDelimited(
            spec
          ).finish()
        )
      );
      const hash = md.digest().toHex();
      return [spec, hash];
    }, [environment, insecureEnclavesEnabled]);

  /// TODO: This is REALLY REALLY BAD. Just for temp usage
  useEffect(() => {
    if (sessionManager) {
      window.__exportDcrHlDefinition = __exportDcrHlDefinition(sessionManager);
    }
  }, [sessionManager]);

  useEffect(() => {
    const initializeClient = async () => {
      const email = user?.email;
      // TODO: use proper setup for enclave token
      if (email) {
        const platformAccessToken = await getAccessTokenSilently();
        if (storageEnclaveToken) {
          const enclaveToken: EnclaveToken = JSON.parse(storageEnclaveToken);
          if (enclaveToken.email !== email) {
            logInfo(
              "Enclave token found belonging to different user, triggering enclave email MFA"
            );
            clearDecentriqStorage();
            setStorageEnclaveToken(null);
            return;
          }
        }
        const token = new UserToken(
          platformAccessToken,
          storageEnclaveToken ? JSON.parse(storageEnclaveToken).token : ""
        );
        const newClient = Client.create(email, token, {
          clientId,
          host,
          logRequests,
          port,
          unsafeDisableKnownRootCaCheck,
          useTls,
        });
        setClient(newClient);
      }
    };
    void initializeClient();
  }, [
    clientId,
    host,
    port,
    useTls,
    unsafeDisableKnownRootCaCheck,
    user,
    getAccessTokenSilently,
    logRequests,
    storageEnclaveToken,
    setStorageEnclaveToken,
  ]);
  useEffect(() => {
    if (client) {
      setApiCoreSessionManager(
        new SessionManager(client, driverMrsignerAttestationSpec)
      );
    }
  }, [client, driverMrsignerAttestationSpec]);
  const reset = useCallback(() => {
    setClient(undefined);
    setApiCoreSessionManager(undefined);
  }, [setClient, setApiCoreSessionManager]);
  useEffect(reset, [reset, user?.email]);

  if (!client || !sessionManager || isLoading) {
    return <Loading />;
  } else {
    const value: ApiCoreContextValue = {
      client,
      driverMrsignerAttestationSpecHash,
      getSessionV2: async () => {
        const session = await sessionManager.getV2();
        await session.setAuthToken({
          type: "enclave-access",
          value: JSON.parse(storageEnclaveToken!).token,
        });
        return session;
      },
      reset,
      sessionManager,
      store,
    };
    return <ApiCoreProvider value={value}>{children}</ApiCoreProvider>;
  }
});
ApiCoreWrapper.displayName = "ApiCoreWrapper";

function getDriverDcapMrsignerAttestationSpecification(
  configurationEnvironment: string,
  configurationInsecureEnclavesEnabled: boolean
): attestationProto.AttestationSpecification {
  if (configurationEnvironment === "local") {
    const dcapRootCaDer = forge.util.binary.base64.decode(
      "MIIB/zCCAaagAwIBAgIBADAKBggqhkjOPQQDAjBlMQswCQYDVQQGEwJVUzEaMBgGA1UECgwRSW50ZWwgQ29ycG9yYXRpb24xFDASBgNVBAcMC1NhbnRhIENsYXJhMQswCQYDVQQIDAJDQTEXMBUGA1UEAwwOTW9jayBEQ0FQIFJvb3QwIBcNNzAwMTAxMDAwMDAwWhgPMjA3MDAxMDEwMDAwMDBaMGUxCzAJBgNVBAYTAlVTMRowGAYDVQQKDBFJbnRlbCBDb3Jwb3JhdGlvbjEUMBIGA1UEBwwLU2FudGEgQ2xhcmExCzAJBgNVBAgMAkNBMRcwFQYDVQQDDA5Nb2NrIERDQVAgUm9vdDBZMBMGByqGSM49AgEGCCqGSM49AwEHA0IABCku6eUyEl2rakObDOm9an10KkOHwU/pzh42B/y9d6/qzRhVAPgiCznsolBdwZviDzOhfaCsTxhvAgJ/L6A/+f2jRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwHQYDVR0OBBYEFNo5o+5ea0sNMlW/75VgGJCv2AcJMA4GA1UdDwEB/wQEAwIBBjAKBggqhkjOPQQDAgNHADBEAiAHwvykevXFPutkpejfVgO/UGMZlZnpotgMqvRV6BR8hgIgXCZPi9lgj5teIkRgP3PGZ5Oo6wpOp9acROqzkZ6tQGU="
    );
    const mrsigner = forge.util.binary.hex.decode(
      "9affcfae47b848ec2caf1c49b4b283531e1cc425f93582b36806e52a43d78d1a"
    );
    const sigstoreRootOlpcJson = forge.util.binary.base64.decode(
      "eyJzaWduYXR1cmVzIjpbeyJrZXlpZCI6IjE5NDk2ZTE5NzI2NDQ5YzY0MDE5ZTMyNTZiYTg4MzZhMjIxNmUyNWE4Njg4MTc0NmRkZTgyYTgzMDkwMDc0NjMiLCJzaWciOiIxMDkxMGVmN2Q1MjMwNjM2NGMyMTdiYTliODc2OWI0MDhlMWIzNWEyZGFiZWI3ZjBjMzg4NmQyZmU2YTg2ZTZiMDM5OWU5YjFhNjQwMGU4NWU1MTVkODhkYTNjOGNhMTg4N2FlNDFjMTZmNDgyMjJiNWMyNzRkODFhMDhlYTYwMyJ9XSwic2lnbmVkIjp7Il90eXBlIjoicm9vdCIsImNvbnNpc3RlbnRfc25hcHNob3QiOnRydWUsImV4cGlyZXMiOiIyMDIzLTA0LTE4VDE4OjEzOjQzWiIsImtleXMiOnsiMTk0OTZlMTk3MjY0NDljNjQwMTllMzI1NmJhODgzNmEyMjE2ZTI1YTg2ODgxNzQ2ZGRlODJhODMwOTAwNzQ2MyI6eyJrZXl0eXBlIjoiZWQyNTUxOSIsImtleXZhbCI6eyJwdWJsaWMiOiIzMDJhMzAwNTA2MDMyYjY1NzAwMzIxMDAzYjZhMjdiY2NlYjZhNDJkNjJhM2E4ZDAyYTZmMGQ3MzY1MzIxNTc3MWRlMjQzYTYzYWMwNDhhMThiNTlkYTI5In0sInNjaGVtZSI6ImVkMjU1MTkifX0sInJvbGVzIjp7InJvb3QiOnsia2V5aWRzIjpbIjE5NDk2ZTE5NzI2NDQ5YzY0MDE5ZTMyNTZiYTg4MzZhMjIxNmUyNWE4Njg4MTc0NmRkZTgyYTgzMDkwMDc0NjMiXSwidGhyZXNob2xkIjoxfSwic25hcHNob3QiOnsia2V5aWRzIjpbIjQ1YjI4MzgyNWViMTg0Y2FiZDU4MmViMTdiNzRmYzhlZDQwNGY2OGNmNDUyYWNhYmRhZDJlZDZmOTBjZTIxNmIiXSwidGhyZXNob2xkIjoxfSwidGFyZ2V0cyI6eyJrZXlpZHMiOlsiMTk0OTZlMTk3MjY0NDljNjQwMTllMzI1NmJhODgzNmEyMjE2ZTI1YTg2ODgxNzQ2ZGRlODJhODMwOTAwNzQ2MyJdLCJ0aHJlc2hvbGQiOjF9LCJ0aW1lc3RhbXAiOnsia2V5aWRzIjpbImUxODYzYmEwMjA3MDMyMmViYzYyNmRjZWNmOWQ4ODFhM2EzOGMzNWMzYjQxYTgzNzY1YjZhZDZjMzdlYWVjMmEiXSwidGhyZXNob2xkIjoxfX0sInNwZWNfdmVyc2lvbiI6IjEuMCIsInZlcnNpb24iOjV9fQ=="
    );

    return attestationProto.AttestationSpecification.create({
      intelDcapMrsigner: {
        acceptDebug: true,
        dcapRootCaDer,
        mrsigner,
        sigstoreRootOlpcJson,
      },
    });
  } else if (configurationInsecureEnclavesEnabled) {
    const dcapRootCaDer = forge.util.binary.base64.decode(
      "MIICjzCCAjSgAwIBAgIUImUM1lqdNInzg7SVUr9QGzknBqwwCgYIKoZIzj0EAwIwaDEaMBgGA1UEAwwRSW50ZWwgU0dYIFJvb3QgQ0ExGjAYBgNVBAoMEUludGVsIENvcnBvcmF0aW9uMRQwEgYDVQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExCzAJBgNVBAYTAlVTMB4XDTE4MDUyMTEwNDUxMFoXDTQ5MTIzMTIzNTk1OVowaDEaMBgGA1UEAwwRSW50ZWwgU0dYIFJvb3QgQ0ExGjAYBgNVBAoMEUludGVsIENvcnBvcmF0aW9uMRQwEgYDVQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExCzAJBgNVBAYTAlVTMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEC6nEwMDIYZOj/iPWsCzaEKi71OiOSLRFhWGjbnBVJfVnkY4u3IjkDYYL0MxO4mqsyYjlBalTVYxFP2sJBK5zlKOBuzCBuDAfBgNVHSMEGDAWgBQiZQzWWp00ifODtJVSv1AbOScGrDBSBgNVHR8ESzBJMEegRaBDhkFodHRwczovL2NlcnRpZmljYXRlcy50cnVzdGVkc2VydmljZXMuaW50ZWwuY29tL0ludGVsU0dYUm9vdENBLmRlcjAdBgNVHQ4EFgQUImUM1lqdNInzg7SVUr9QGzknBqwwDgYDVR0PAQH/BAQDAgEGMBIGA1UdEwEB/wQIMAYBAf8CAQEwCgYIKoZIzj0EAwIDSQAwRgIhAOW/5QkR+S9CiSDcNoowLuPRLsWGf/Yi7GSX94BgwTwgAiEA4J0lrHoMs+Xo5o/sX6O9QWxHRAvZUGOdRQ7cvqRXaqI="
    );
    const mrsigner = forge.util.binary.hex.decode(
      "9affcfae47b848ec2caf1c49b4b283531e1cc425f93582b36806e52a43d78d1a"
    );
    const sigstoreRootOlpcJson = forge.util.binary.base64.decode(
      "eyJzaWduYXR1cmVzIjpbeyJrZXlpZCI6IjE5NDk2ZTE5NzI2NDQ5YzY0MDE5ZTMyNTZiYTg4MzZhMjIxNmUyNWE4Njg4MTc0NmRkZTgyYTgzMDkwMDc0NjMiLCJzaWciOiIxMDkxMGVmN2Q1MjMwNjM2NGMyMTdiYTliODc2OWI0MDhlMWIzNWEyZGFiZWI3ZjBjMzg4NmQyZmU2YTg2ZTZiMDM5OWU5YjFhNjQwMGU4NWU1MTVkODhkYTNjOGNhMTg4N2FlNDFjMTZmNDgyMjJiNWMyNzRkODFhMDhlYTYwMyJ9XSwic2lnbmVkIjp7Il90eXBlIjoicm9vdCIsImNvbnNpc3RlbnRfc25hcHNob3QiOnRydWUsImV4cGlyZXMiOiIyMDIzLTA0LTE4VDE4OjEzOjQzWiIsImtleXMiOnsiMTk0OTZlMTk3MjY0NDljNjQwMTllMzI1NmJhODgzNmEyMjE2ZTI1YTg2ODgxNzQ2ZGRlODJhODMwOTAwNzQ2MyI6eyJrZXl0eXBlIjoiZWQyNTUxOSIsImtleXZhbCI6eyJwdWJsaWMiOiIzMDJhMzAwNTA2MDMyYjY1NzAwMzIxMDAzYjZhMjdiY2NlYjZhNDJkNjJhM2E4ZDAyYTZmMGQ3MzY1MzIxNTc3MWRlMjQzYTYzYWMwNDhhMThiNTlkYTI5In0sInNjaGVtZSI6ImVkMjU1MTkifX0sInJvbGVzIjp7InJvb3QiOnsia2V5aWRzIjpbIjE5NDk2ZTE5NzI2NDQ5YzY0MDE5ZTMyNTZiYTg4MzZhMjIxNmUyNWE4Njg4MTc0NmRkZTgyYTgzMDkwMDc0NjMiXSwidGhyZXNob2xkIjoxfSwic25hcHNob3QiOnsia2V5aWRzIjpbIjQ1YjI4MzgyNWViMTg0Y2FiZDU4MmViMTdiNzRmYzhlZDQwNGY2OGNmNDUyYWNhYmRhZDJlZDZmOTBjZTIxNmIiXSwidGhyZXNob2xkIjoxfSwidGFyZ2V0cyI6eyJrZXlpZHMiOlsiMTk0OTZlMTk3MjY0NDljNjQwMTllMzI1NmJhODgzNmEyMjE2ZTI1YTg2ODgxNzQ2ZGRlODJhODMwOTAwNzQ2MyJdLCJ0aHJlc2hvbGQiOjF9LCJ0aW1lc3RhbXAiOnsia2V5aWRzIjpbImUxODYzYmEwMjA3MDMyMmViYzYyNmRjZWNmOWQ4ODFhM2EzOGMzNWMzYjQxYTgzNzY1YjZhZDZjMzdlYWVjMmEiXSwidGhyZXNob2xkIjoxfX0sInNwZWNfdmVyc2lvbiI6IjEuMCIsInZlcnNpb24iOjV9fQ=="
    );
    return attestationProto.AttestationSpecification.create({
      intelDcapMrsigner: {
        acceptDebug: true,
        dcapRootCaDer,
        mrsigner,
        sigstoreRootOlpcJson,
      },
    });
  } else {
    const enclaveSpecification = enclaveSpecifications.specifications.get(
      "decentriq.driver:mrsigner"
    );
    if (!enclaveSpecification) {
      throw new Error(
        "decentriq.driver:mrsigner not found in enclave specifications"
      );
    }
    return enclaveSpecification.proto;
  }
}

const __exportDcrHlDefinition =
  (sessionManager: SessionManager) => async (dataRoomId: string) => {
    const sdkSession = await sessionManager.get();
    const publishedDataRoom = await sdkSession.retrieveDataRoom(dataRoomId);
    logInfo(
      JSON.stringify(
        JSON.parse(
          new TextDecoder().decode(publishedDataRoom.highLevelRepresentation!)
        ),
        null,
        2
      )
    );
  };

export default ApiCoreWrapper;
