import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Grid, Typography } from "@mui/joy";
import { memo, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  defaultMediaDataRoomCreationConfigurationFormValues,
  type MediaDataRoomCollaborationType,
  MediaDataRoomCreationConfigurationFormSchema,
  type MediaDataRoomCreationConfigurationFormValues,
} from "features/dataRoomCreation/models";
import { DataRoomType } from "models";
import {
  CreateDataRoomStepActionsContainer,
  CreateDataRoomStepContainer,
  CreateDataRoomStepContentContainer,
  CreateDataRoomStepHeader,
} from "../CreateDataRoomStep";
import MediaDataRoomCreationConfigurationForm from "./MediaDataRoomCreationConfigurationForm";

interface MediaDataRoomCreationConfigurationStepProps {
  collaborationTypes: MediaDataRoomCollaborationType[];
  isPublishing?: boolean;
  isPublished?: boolean;
  defaultFormValues?: MediaDataRoomCreationConfigurationFormValues;
  onSubmit: (formValues: MediaDataRoomCreationConfigurationFormValues) => void;
  onCancel: () => void;
}

const MediaDataRoomCreationConfigurationStep =
  memo<MediaDataRoomCreationConfigurationStepProps>(
    ({
      collaborationTypes,
      defaultFormValues,
      onCancel,
      onSubmit,
      isPublished,
      isPublishing,
    }) => {
      const configurationForm =
        useForm<MediaDataRoomCreationConfigurationFormValues>({
          defaultValues:
            defaultFormValues ??
            defaultMediaDataRoomCreationConfigurationFormValues,
          mode: "onChange",
          resolver: zodResolver(MediaDataRoomCreationConfigurationFormSchema),
        });
      const { handleSubmit: handleFormSubmit, reset: resetForm } =
        configurationForm;
      useEffect(() => {
        resetForm(
          defaultFormValues ??
            defaultMediaDataRoomCreationConfigurationFormValues
        );
      }, [defaultFormValues, resetForm]);
      const handleCancel = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);
      const handleSubmit = useCallback(() => {
        void handleFormSubmit((formValues) => {
          onSubmit?.(formValues);
        })();
      }, [onSubmit, handleFormSubmit]);
      return (
        <FormProvider {...configurationForm}>
          <CreateDataRoomStepContainer>
            <CreateDataRoomStepHeader
              collaborationTypes={collaborationTypes}
              dataRoomType={DataRoomType.MediaInsights}
            />
            <CreateDataRoomStepContentContainer>
              <Grid container={true}>
                <Grid lg={7} md={9} xs={12}>
                  <Typography level="h4">
                    Media clean room configuration
                  </Typography>
                  <MediaDataRoomCreationConfigurationForm
                    hasDefaultMatchingId={Boolean(
                      defaultFormValues?.matchingId
                    )}
                  />
                </Grid>
              </Grid>
            </CreateDataRoomStepContentContainer>
            <CreateDataRoomStepActionsContainer>
              <Button
                disabled={isPublished || isPublishing}
                onClick={handleCancel}
              >
                Back
              </Button>
              <Button
                color="primary"
                disabled={isPublished}
                loading={isPublishing}
                onClick={handleSubmit}
                variant="solid"
              >
                Publish
              </Button>
            </CreateDataRoomStepActionsContainer>
          </CreateDataRoomStepContainer>
        </FormProvider>
      );
    }
  );

export default MediaDataRoomCreationConfigurationStep;
