import { useAuth0 } from "@auth0/auth0-react";
import { type create_media_data_room as ddcCreateMediaDataRoom } from "ddc";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import {
  MediaDataRoomCollaborationType,
  type MediaDataRoomCreationConfigurationFormValues,
  type MediaDataRoomParticipant,
} from "features/dataRoomCreation/models";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import { getEffectiveErrorMessage } from "utils";
import {
  matchingIdToFormatTypeAndHashingAlgorithmMap,
  mediaDataRoomOrganizationPermissionToPermissionMap,
  type MediaDataRoomPublishingStep,
} from "./models";

export interface MediaDataRoomPublishHookResult {
  publishingStep: MediaDataRoomPublishingStep | null;
  publish: (state: {
    participants: MediaDataRoomParticipant[];
    collaborationTypes: MediaDataRoomCollaborationType[];
    configuration: MediaDataRoomCreationConfigurationFormValues;
  }) => Promise<void>;
}

const useMediaDataRoomPublish = (): MediaDataRoomPublishHookResult => {
  const { client, getSessionV2 } = useApiCore();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const navigate = useNavigate();
  const [publishingStep, setPublishingStep] =
    useState<MediaDataRoomPublishingStep | null>(null);
  const publish = useCallback<MediaDataRoomPublishHookResult["publish"]>(
    async ({ participants, collaborationTypes, configuration }) => {
      try {
        setPublishingStep("pending");
        const session = await getSessionV2();
        const verificationKey = await session.getVerificationKey();
        const [matchingIdFormat, hashMatchingIdWith] =
          matchingIdToFormatTypeAndHashingAlgorithmMap[
            configuration.matchingId
          ];
        const { id: dataRoomId } = await client.createDataRoom(
          {
            media: {
              enableDebugMode: false,
              enableInsights: collaborationTypes.includes(
                MediaDataRoomCollaborationType.INSIGHTS
              ),
              enableLookalikeAudiences: collaborationTypes.includes(
                MediaDataRoomCollaborationType.AI_LOOKALIKE_AUDIENCES
              ),
              enableRemarketing: collaborationTypes.includes(
                MediaDataRoomCollaborationType.REMARKETING_AUDIENCES
              ),
              enableRuleBasedAudiences: collaborationTypes.includes(
                MediaDataRoomCollaborationType.RULE_BASED_AUDIENCES
              ),
              hashMatchingIdWith,
              hideAbsoluteValuesFromInsights:
                !configuration.showAbsoluteAudienceSizes,
              matchingIdFormat,
              name: configuration.name,
              participants: participants
                .flatMap(({ permissions, emails }) =>
                  emails.map((email) => ({
                    email,
                    permissions,
                  }))
                )
                .reduce(
                  (acc, { email, permissions }) => ({
                    ...acc,
                    [email]: permissions.map(
                      (permission) =>
                        mediaDataRoomOrganizationPermissionToPermissionMap[
                          permission
                        ]
                    ),
                  }),
                  {} as {
                    [k: string]: ddcCreateMediaDataRoom.Permission[];
                  }
                ),
              version: "v0",
            },
          },
          verificationKey
        );
        enqueueSnackbar("The MDCR has been successfully created", {
          variant: "success",
        });
        setPublishingStep("completed");
        const isCurrentUserIncluded = participants
          .flatMap(({ emails }) => emails)
          .includes(user?.email ?? "");
        setTimeout(() => {
          navigate(
            isCurrentUserIncluded
              ? dataRoomPathBuilder(
                  dataRoomId,
                  DataRoomTypeNames.PublishedMediaDataRoom
                )
              : "/datarooms"
          );
        }, 1200);
      } catch (error) {
        enqueueSnackbar(`Data clean room could not be published.`, {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
        setPublishingStep(null);
      }
    },
    [client, enqueueSnackbar, getSessionV2, navigate, user?.email]
  );
  return {
    publish,
    publishingStep,
  };
};

export default useMediaDataRoomPublish;
