import { DqPortal as Portal } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DataLabCreateDialog } from "features/dataLabs";

const DataLabCreateButton: React.FC = () => {
  const [
    isDataLabCreateDialogVisible,
    { setTrue: openDataLabCreateDialog, setFalse: closeDataLabCreateDialog },
  ] = useBoolean(false);

  return (
    <>
      <Button
        color="primary"
        data-testid={testIds.dataLabs.dataLabScreen.newDataLab}
        onClick={openDataLabCreateDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
        variant="solid"
      >
        Create datalab
      </Button>
      <Portal>
        <DataLabCreateDialog
          onCancel={closeDataLabCreateDialog}
          open={isDataLabCreateDialogVisible}
        />
      </Portal>
    </>
  );
};

DataLabCreateButton.displayName = "DataLabCreateButton";

export default memo(DataLabCreateButton);
