import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Grid, Typography } from "@mui/joy";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { type MediaDataRoomParticipant } from "features/dataRoomCreation/models";
import { DataRoomType } from "models";
import {
  CreateDataRoomStepActionsContainer,
  CreateDataRoomStepContainer,
  CreateDataRoomStepContentContainer,
  CreateDataRoomStepHeader,
} from "../CreateDataRoomStep";
import {
  AddParticipantCard,
  ParticipantCard,
  ParticipantDrawer,
} from "./components";
import {
  type MediaDataRoomCreationParticipantsStepFormValues,
  MediaDataRoomCreationParticipantsStepSchema,
} from "./models";

export interface MediaDataRoomCreationParticipantsStepProps {
  onSubmit: (participants: MediaDataRoomParticipant[]) => void;
  onCancel: () => void;
  defaultParticipants: MediaDataRoomParticipant[];
  onParticipantAdded?: (participant: MediaDataRoomParticipant) => void;
  onParticipantDelete?: (participant: MediaDataRoomParticipant) => void;
  onParticipantEdit?: (participant: MediaDataRoomParticipant) => void;
}

const MediaDataRoomCreationParticipantsStep =
  memo<MediaDataRoomCreationParticipantsStepProps>(
    ({
      onCancel,
      onSubmit,
      defaultParticipants,
      onParticipantDelete,
      onParticipantAdded,
      onParticipantEdit,
    }) => {
      const participantsForm =
        useForm<MediaDataRoomCreationParticipantsStepFormValues>({
          defaultValues: {
            participants: defaultParticipants,
          },
          mode: "onSubmit",
          resolver: zodResolver(MediaDataRoomCreationParticipantsStepSchema),
        });
      const {
        reset: resetForm,
        control,
        handleSubmit,
        watch,
      } = participantsForm;

      // Under the hood, useFieldArray overrides existing id value with its own custom value
      // so when using useFieldArray's fields or getValues(), overwritten id is returned
      // Therefore, watch is used as only this method returns original id which is needed
      // for correct editing and adding logic
      const participants = watch("participants");

      const {
        append: addParticipant,
        remove: deleteParticipant,
        update: updateParticipant,
      } = useFieldArray({
        control,
        name: "participants",
      });

      const { enqueueSnackbar } = useSnackbar();

      // Set participant for the drawer
      const [selectedParticipant, setSelectedParticipant] =
        // Index is going to be removed as soon as id on participant object is introduced
        useState<MediaDataRoomParticipant | null>(null);

      // Function is called when all the dialogs are filled with participant data
      const handleAddParticipant = useCallback(
        (participant: MediaDataRoomParticipant) => {
          addParticipant(participant);
          onParticipantAdded?.(participant);
        },
        [addParticipant, onParticipantAdded]
      );

      // Step submit/cancel handlers
      const handleDataRoomParticipantsStepCancel = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);

      const handleDataRoomParticipantsStepSubmit = useCallback(() => {
        void handleSubmit(
          ({ participants }) => {
            onSubmit?.(participants);
          },
          (errors) => {
            if (errors.participants) {
              enqueueSnackbar(errors.participants.message);
            }
          }
        )();
      }, [enqueueSnackbar, handleSubmit, onSubmit]);

      const handleDrawerClose = useCallback(() => {
        setSelectedParticipant(null);
      }, [setSelectedParticipant]);

      // Delete participant
      const handleParticipantDelete = useCallback(
        (participant: MediaDataRoomParticipant, index: number) => {
          handleDrawerClose();
          deleteParticipant(index);
          onParticipantDelete?.(participant);
        },
        [deleteParticipant, onParticipantDelete, handleDrawerClose]
      );

      // Edit participant
      const handleParticipantEdit = useCallback(
        (participant: MediaDataRoomParticipant) => {
          onParticipantEdit?.(participant);
          updateParticipant(
            participants.findIndex(({ id }) => participant.id === id),
            participant
          );
          handleDrawerClose();
        },
        [onParticipantEdit, updateParticipant, participants, handleDrawerClose]
      );

      const otherParticipantsEmails = useMemo<string[]>(() => {
        if (!selectedParticipant)
          return participants.flatMap(({ emails }) => emails);
        return participants
          .filter(({ id }) => id !== selectedParticipant?.id)
          .flatMap(({ emails }) => emails);
      }, [participants, selectedParticipant]);

      return (
        <Fragment>
          <CreateDataRoomStepContainer>
            <CreateDataRoomStepHeader
              dataRoomType={DataRoomType.MediaInsights}
            />
            <CreateDataRoomStepContentContainer>
              <Typography level="h4">
                Add all the participants involved in this Media DCR
              </Typography>
              <Grid columnSpacing={2} container={true} rowSpacing={2} xs={12}>
                {participants.map((participant, index) => (
                  <Grid key={participant.id} sm={4} xs={12}>
                    <ParticipantCard
                      onClick={() => setSelectedParticipant(participant)}
                      onDelete={() =>
                        handleParticipantDelete(participant, index)
                      }
                      onEdit={handleParticipantEdit}
                      otherParticipantsEmails={otherParticipantsEmails}
                      participant={participant}
                    />
                  </Grid>
                ))}
                <Grid sm={4} xs={12}>
                  <AddParticipantCard
                    onStart={handleDrawerClose}
                    onSubmit={handleAddParticipant}
                    participantsEmails={otherParticipantsEmails}
                  />
                </Grid>
              </Grid>
            </CreateDataRoomStepContentContainer>
            <CreateDataRoomStepActionsContainer>
              <Button onClick={handleDataRoomParticipantsStepCancel}>
                Back
              </Button>
              <Button
                color="primary"
                onClick={handleDataRoomParticipantsStepSubmit}
                variant="solid"
              >
                Continue
              </Button>
            </CreateDataRoomStepActionsContainer>
          </CreateDataRoomStepContainer>
          <ParticipantDrawer
            onClose={handleDrawerClose}
            onParticipantEdit={handleParticipantEdit}
            otherParticipantsEmails={otherParticipantsEmails}
            participant={selectedParticipant}
          />
        </Fragment>
      );
    }
  );
MediaDataRoomCreationParticipantsStep.displayName =
  "MediaDataRoomCreationParticipantsStep";

export default MediaDataRoomCreationParticipantsStep;
