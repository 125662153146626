import { DqEmptyData, DqLoader, DqTable } from "@decentriq/components";
import { type Dataset } from "@decentriq/graphql/dist/types";
import { faArrowRight, faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Typography } from "@mui/joy";
import { isEmpty } from "lodash";
import {
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField as DatalabsSearchField,
  type MRT_RowSelectionState,
} from "material-react-table";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TimeAgoFormatted } from "components";
import {
  DataLabCreateButton,
  DataLabDrawer,
  DataLabStatusLabel,
  useDatalabsBaseUrl,
} from "features/dataLabs";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { type DataLab } from "features/dataLabs/models";
import { useDataLabsContext } from "../../contexts";

const dataLabsColumnDef: MRT_ColumnDef<DataLab>[] = [
  {
    Cell: ({ cell }) => {
      return (
        <Typography
          component="span"
          fontWeight="500"
          level="inherit"
          noWrap={true}
          textColor="inherit"
        >
          {cell.getValue<string>()}
        </Typography>
      );
    },
    accessorKey: "name",
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell }) => {
      const isValidated = cell.getValue<boolean>();
      return <DataLabStatusLabel isValidated={isValidated} />;
    },
    accessorKey: "isValidated",
    header: "Status",
    id: "status",
    size: 120,
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return createdAt ? <TimeAgoFormatted date={createdAt} /> : "—";
    },
    accessorKey: "updatedAt",
    header: "Last modified",
    id: "updatedAt",
    size: 120,
  },
  {
    Cell: ({ row }) => {
      const { matchingIdFormat, matchingIdHashingAlgorithm } = row.original;
      return matchingIdTypeAndHashingAlgorithmPresentation({
        matchingIdFormat,
        matchingIdHashingAlgorithm,
      });
    },
    header: "Matching ID",
    id: "matchingId",
    size: 120,
  },
  {
    Cell: ({ cell }) => {
      const matchingDataset = cell.getValue<Dataset | null>();
      return !isEmpty(matchingDataset) ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "usersDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Matching",
    id: "matchingDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell }) => {
      const segmentsDataset = cell.getValue<Dataset | null>();
      return segmentsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "segmentsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Segments",
    id: "segmentsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell }) => {
      const demographicsDataset = cell.getValue<Dataset | null>();
      return demographicsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "demographicsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Demographics",
    id: "demographicsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell, row }) => {
      const embeddingsDataset = cell.getValue<Dataset | null>();
      return embeddingsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "embeddingsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Embeddings",
    id: "embeddingsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ row }) => {
      const navigate = useNavigate();
      const datalabsBaseUrl = useDatalabsBaseUrl();
      return (
        <Button
          endDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
          }
          onClick={() => navigate(`${datalabsBaseUrl}/${row.original.id}`)}
        >
          Go to datalab
        </Button>
      );
    },
    enableResizing: false,
    grow: false,
    header: "Actions",
    id: "actions",
    muiTableBodyCellProps: { sx: { p: 0 } },
    size: 160,
  },
];

const DataLabsList: React.FC = () => {
  const {
    dataLabs: { data: dataLabs = [], loading },
  } = useDataLabsContext();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedDataLab] = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => dataLabs.find(({ id }) => id === key));
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="absolute"
        top="0"
        width="100%"
      >
        <DqLoader />
      </Box>
    );
  }
  return (
    <Fragment>
      <DqTable
        autoSelectFirstRow={false}
        columns={dataLabsColumnDef}
        data={dataLabs}
        enableBatchRowSelection={false}
        enableGlobalFilter={true}
        enableMultiRowSelection={false}
        enableRowActions={false}
        enableRowSelection={true}
        enableSelectAll={false}
        enableSorting={true}
        enableTopToolbar={true}
        getRowId={(row) => row.id}
        initialState={{
          showGlobalFilter: true,
          sorting: [{ desc: true, id: "updatedAt" }],
        }}
        localization={{
          noRecordsToDisplay: "You haven't created any datalabs yet",
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search datalabs",
        }}
        muiTableBodyRowProps={({
          row: { getToggleSelectedHandler, getIsSelected },
        }) => {
          return {
            onClick: getIsSelected() ? undefined : getToggleSelectedHandler(),
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": { pr: 2 },
              cursor: "pointer",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": {
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                right: "-1rem",
              },
              pr: 2,
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        onRowSelectionChange={setRowSelection}
        renderTopToolbar={({ table }) => (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ px: 1 }}
          >
            <DatalabsSearchField table={table} />
            <DataLabCreateButton />
          </Stack>
        )}
        renderTopToolbarCustomActions={() => null} // NOTE: Hack to prevent MRT from switching the toolbar to `position: absolute;` because of `stackAlertBanner`
        state={{
          columnVisibility: { "mrt-row-select": false },
          rowSelection,
        }}
      />
      <DataLabDrawer
        onClose={() => setRowSelection({})}
        open={!loading && !!selectedDataLab?.id}
        selectedDataLabId={selectedDataLab?.id}
      />
    </Fragment>
  );
};

export default DataLabsList;
