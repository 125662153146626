import { DqCard, DqCardContent, DqIcon } from "@decentriq/components";
import { Grid, Stack, Tooltip, Typography, useTheme } from "@mui/joy";
import { memo } from "react";
import { Controller } from "react-hook-form";
import {
  type MediaDataRoomCreationCollaborationTypeItem,
  type MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
} from "features/dataRoomCreation/models";

type MediaDataRoomCreationCollaborationTypeCardFormControlProps =
  MediaDataRoomCreationCollaborationTypeItem;

const MediaDataRoomCreationCollaborationTypeCardFormControl =
  memo<MediaDataRoomCreationCollaborationTypeCardFormControlProps>(
    ({
      collaborationType,
      testId,
      disabled,
      tooltip,
      description,
      icon,
      title,
    }) => {
      const { palette } = useTheme();
      return (
        <Controller<
          MediaDataRoomCreationCollaborationTypesAndPermissionsStepFormValues,
          "collaborationTypes"
        >
          name="collaborationTypes"
          render={({ field: { value, ...field } }) => {
            return (
              <Grid xs={4}>
                <Tooltip title={tooltip}>
                  <DqCard
                    data-testid={testId}
                    selectable={{
                      checked: value[collaborationType],
                      component: "checkbox",
                      disabled: disabled,
                      ...field,
                      name: `collaborationTypes.${collaborationType}`,
                      onChange: (event) =>
                        field.onChange({
                          ...value,
                          [collaborationType]: event.target.checked,
                        }),
                    }}
                  >
                    <DqCardContent
                      alignItems="flex-start"
                      gap={2}
                      justifyContent="flex-start"
                    >
                      <Stack alignItems="center" direction="row" gap={2}>
                        <DqIcon
                          icon={icon}
                          iconProps={{
                            color: palette.primary[400],
                            size: "2x",
                          }}
                        />
                        <Typography level="title-lg" textAlign="center">
                          {title}
                        </Typography>
                      </Stack>
                      <Typography level="body-sm">{description}</Typography>
                    </DqCardContent>
                  </DqCard>
                </Tooltip>
              </Grid>
            );
          }}
        />
      );
    }
  );
MediaDataRoomCreationCollaborationTypeCardFormControl.displayName =
  "MediaDataRoomCreationCollaborationTypeCardFormControl";

export default MediaDataRoomCreationCollaborationTypeCardFormControl;
