import {
  Checkbox,
  Chip,
  ChipDelete,
  type ChipDeleteProps,
  Option,
  type OptionProps,
  Select,
  type SelectProps,
} from "@mui/joy";
import { memo, useCallback, useMemo } from "react";
import { type AvailableMarket } from "features/dataRoomCreation/models";

interface SelectMarketFilterProps {
  availableMarkets: AvailableMarket[];
  onChange: (markets: string[]) => void;
}
const SELECT_ALL_OPTION_VALUE = "All";

const SelectMarketFilter = memo<SelectMarketFilterProps>(
  ({ availableMarkets, onChange }) => {
    const selectedMarkets = useMemo(
      () =>
        availableMarkets.filter(({ selected }) => selected).map(({ id }) => id),
      [availableMarkets]
    );
    const handleMarketChange = useCallback<
      NonNullable<SelectProps<string, true>["onChange"]>
    >(
      (_, values) => {
        if (values.includes(SELECT_ALL_OPTION_VALUE)) {
          return;
        }
        onChange(values);
      },
      [onChange]
    );
    const handleMarketDelete = useCallback<
      (value: string) => NonNullable<ChipDeleteProps["onDelete"]>
    >(
      (value) => (event) => {
        event.stopPropagation();
        const newSelectedMarkets = selectedMarkets.filter((v) => v !== value);
        onChange(newSelectedMarkets);
        // If no markets selected, show all publishers/data partners
        if (newSelectedMarkets.length === 0) {
          return;
        }
        onChange(newSelectedMarkets);
      },
      [onChange, selectedMarkets]
    );
    const isAllMarketsSelected = useMemo(
      () => selectedMarkets.length === availableMarkets.length,
      [selectedMarkets, availableMarkets]
    );
    const handleAllMarketsSelect = useCallback<
      NonNullable<OptionProps["onClick"]>
    >(() => {
      onChange(
        isAllMarketsSelected ? [] : availableMarkets.map(({ id }) => id)
      );
    }, [onChange, isAllMarketsSelected, availableMarkets]);
    return (
      <Select
        multiple={true}
        onChange={handleMarketChange}
        placeholder="Search by market"
        renderValue={(selectedMarketOption) =>
          selectedMarketOption.map(({ value: id }) => (
            <Chip
              endDecorator={<ChipDelete onDelete={handleMarketDelete(id)} />}
              key={id}
              sx={{ mr: 1 }}
            >
              {availableMarkets.find((market) => market.id === id)?.name}
            </Chip>
          ))
        }
        sx={{ mb: 2 }}
        value={selectedMarkets}
      >
        <Option
          onClick={handleAllMarketsSelect}
          value={SELECT_ALL_OPTION_VALUE}
        >
          <Checkbox
            checked={isAllMarketsSelected}
            indeterminate={
              selectedMarkets.length > 0 &&
              selectedMarkets.length < availableMarkets.length
            }
            label="All"
          />
        </Option>
        {availableMarkets.map(({ id, name, selected }) => (
          <Option key={id} value={id}>
            <Checkbox checked={selected} label={name} />
          </Option>
        ))}
      </Select>
    );
  }
);
SelectMarketFilter.displayName = "SelectMarketFilter";
export default SelectMarketFilter;
