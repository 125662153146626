import { z } from "zod";
import {
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRoleCardPresentation,
} from "features/dataRoomCreation/models";

export const dialogParticipantRoleTitle: Record<
  MediaDataRoomOrganizationRole,
  string
> = {
  [MediaDataRoomOrganizationRole.ADVERTISER]: `an ${mediaDataRoomOrganizationRoleCardPresentation[
    MediaDataRoomOrganizationRole.ADVERTISER
  ].title.toLowerCase()}`,
  [MediaDataRoomOrganizationRole.PUBLISHER]: `a ${mediaDataRoomOrganizationRoleCardPresentation[
    MediaDataRoomOrganizationRole.PUBLISHER
  ].title.toLowerCase()}`,
  [MediaDataRoomOrganizationRole.DATA_PARTNER]: `a ${mediaDataRoomOrganizationRoleCardPresentation[
    MediaDataRoomOrganizationRole.DATA_PARTNER
  ].title.toLowerCase()}`,
  [MediaDataRoomOrganizationRole.OBSERVER]: `an ${mediaDataRoomOrganizationRoleCardPresentation[
    MediaDataRoomOrganizationRole.OBSERVER
  ].title.toLowerCase()}`,
  [MediaDataRoomOrganizationRole.CUSTOM]: `a participant`,
  [MediaDataRoomOrganizationRole.AGENCY]: `an ${mediaDataRoomOrganizationRoleCardPresentation[
    MediaDataRoomOrganizationRole.AGENCY
  ].title.toLowerCase()}`,
};

export const getMediaDataRoomCreationParticipantDialogFormSchema = (
  otherParticipantsEmails: string[]
) =>
  z.object({
    emails: z
      .array(z.string().email())
      .min(1, { message: "Participant must have at least one email address." })
      // Check for the same domain
      .refine(
        (emails) => {
          const emailsDomains = emails.map((email) => {
            const [, domain] = email.split("@");
            return domain;
          });
          const uniqueEmailDomains = [...new Set(emailsDomains)];
          return uniqueEmailDomains.length === 1;
        },
        {
          message: "All email addresses must have the same domain.",
        }
      )
      // Check for uniqueness across all the participants
      .refine(
        (emails) => {
          const allAvailableEmails = [...otherParticipantsEmails, ...emails];
          return allAvailableEmails.length === new Set(allAvailableEmails).size;
        },
        {
          message: "Email addresses are not unique.",
        }
      ),
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MediaDataRoomCreationParticipantDialogFormSchema =
  getMediaDataRoomCreationParticipantDialogFormSchema([]);

export type MediaDataRoomCreationParticipantDialogFormValues = z.infer<
  typeof MediaDataRoomCreationParticipantDialogFormSchema
>;
