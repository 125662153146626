import {
  useAdvertiserMarketFiltersQuery,
  useAvailableDataPartnersQuery,
  useAvailablePublishersQuery,
  useUpdateAdvertiserMarketFiltersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type AvailableDataPartner,
  type AvailableDataPartnerFragment,
  type AvailablePublisher,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { useCallback, useMemo } from "react";
import { type AvailableMarket } from "features/dataRoomCreation/models";

export interface MediaDataRoomAvailableParticipantsHookResult {
  availablePublishers: AvailablePublisherFragment[];
  availableDataPartners: AvailableDataPartnerFragment[];
  isLoading: boolean;
  availableMarkets: Record<"publisher" | "dataPartner", AvailableMarket[]>;
  onSelectedMarketsChange: (selectedMarkets: string[]) => void;
}

export const useMediaDataRoomAvailableParticipants =
  (): MediaDataRoomAvailableParticipantsHookResult => {
    const { data: advertiserMarketFiltersData } =
      useAdvertiserMarketFiltersQuery();
    const [updateAdvertiserMarketFiltersMutation] =
      useUpdateAdvertiserMarketFiltersMutation();
    const updateAdvertiserMarketFilters = useCallback(
      (marketIds: string[]) => {
        void updateAdvertiserMarketFiltersMutation({
          optimisticResponse: {
            availablePublisher: {
              updateMarketFilters: marketIds,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                advertiserMarketFilters: () =>
                  data?.availablePublisher.updateMarketFilters || [],
              },
            });
          },
          variables: {
            input: marketIds,
          },
        });
      },
      [updateAdvertiserMarketFiltersMutation]
    );
    const {
      data: availableDataPartnersQueryData,
      loading: isAvailableDataPartnersLoading,
    } = useAvailableDataPartnersQuery();
    const allAvailableDataPartners = useMemo<AvailableDataPartner[]>(
      () => availableDataPartnersQueryData?.availableDataPartners?.nodes || [],
      [availableDataPartnersQueryData?.availableDataPartners?.nodes]
    );
    const {
      data: availablePublishersQueryData,
      loading: isAvailablePublishersLoading,
    } = useAvailablePublishersQuery();
    const allAvailablePublishers = useMemo<AvailablePublisher[]>(
      () => availablePublishersQueryData?.availablePublishers?.nodes || [],
      [availablePublishersQueryData?.availablePublishers?.nodes]
    );
    const advertiserMarketFilters = useMemo(() => {
      const markets =
        advertiserMarketFiltersData?.publisherMarkets?.nodes || [];
      const activeFilters =
        advertiserMarketFiltersData?.advertiserMarketFilters || [];
      return {
        dataPartner: markets.reduce((acc, market) => {
          if (
            !allAvailableDataPartners.some(({ marketIds }) =>
              marketIds.includes(market.id)
            )
          ) {
            return acc;
          }
          acc.push({
            ...market,
            selected: activeFilters.includes(market.id),
          });
          return acc;
        }, [] as AvailableMarket[]),
        publisher: markets.reduce((acc, market) => {
          if (
            !allAvailablePublishers.some(({ marketIds }) =>
              marketIds.includes(market.id)
            )
          ) {
            return acc;
          }
          acc.push({
            ...market,
            selected: activeFilters.includes(market.id),
          });
          return acc;
        }, [] as AvailableMarket[]),
      };
    }, [
      advertiserMarketFiltersData,
      allAvailableDataPartners,
      allAvailablePublishers,
    ]);
    const filteredAvailableDataPartners = useMemo<
      AvailableDataPartner[]
    >(() => {
      const selectedDataPartnersMarketFilters =
        advertiserMarketFilters.dataPartner.filter(({ selected }) => selected);
      const hasAnyDataPartnersFilterSelected =
        selectedDataPartnersMarketFilters.length > 0;
      return (
        hasAnyDataPartnersFilterSelected
          ? allAvailableDataPartners.filter(({ marketIds }) =>
              selectedDataPartnersMarketFilters.some(({ id }) =>
                marketIds.includes(id)
              )
            )
          : allAvailableDataPartners
      ).map((dataPartner) => ({
        ...dataPartner,
        dataPartnerLogo: dataPartner.dataPartnerLogo
          ? `data:image;base64,${dataPartner.dataPartnerLogo}`
          : undefined,
      }));
    }, [allAvailableDataPartners, advertiserMarketFilters]);
    const filteredAvailablePublishers = useMemo<AvailablePublisher[]>(() => {
      const selectedPublishersMarketFilters =
        advertiserMarketFilters.publisher.filter(({ selected }) => selected);
      const hasAnyPublishersFilterSelected =
        selectedPublishersMarketFilters.length > 0;
      return (
        hasAnyPublishersFilterSelected
          ? allAvailablePublishers.filter(({ marketIds }) =>
              selectedPublishersMarketFilters.some(({ id }) =>
                marketIds.includes(id)
              )
            )
          : allAvailablePublishers
      ).map((publisher) => ({
        ...publisher,
        publisherLogo: publisher.publisherLogo
          ? `data:image;base64,${publisher.publisherLogo}`
          : undefined,
      }));
    }, [allAvailablePublishers, advertiserMarketFilters]);
    return useMemo(
      () => ({
        availableDataPartners: filteredAvailableDataPartners,
        availableMarkets: advertiserMarketFilters,
        availablePublishers: filteredAvailablePublishers,
        isLoading:
          isAvailableDataPartnersLoading || isAvailablePublishersLoading,
        onSelectedMarketsChange: updateAdvertiserMarketFilters,
      }),
      [
        isAvailableDataPartnersLoading,
        isAvailablePublishersLoading,
        filteredAvailablePublishers,
        filteredAvailableDataPartners,
        advertiserMarketFilters,
        updateAdvertiserMarketFilters,
      ]
    );
  };
