import {
  type AvailableDataPartnerFragment,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { z } from "zod";
import {
  MediaDataRoomOrganizationRole,
  mediaDataRoomOrganizationRolePresentationMap,
} from "features/dataRoomCreation/models";

export const getMediaDataRoomCreationParticipantSelectionSchema = (
  allParticipantsEmails: string[],
  availableParticipants:
    | AvailableDataPartnerFragment[]
    | AvailablePublisherFragment[],
  role:
    | MediaDataRoomOrganizationRole.DATA_PARTNER
    | MediaDataRoomOrganizationRole.PUBLISHER
) =>
  z.object({
    selectedParticipantId: z
      .string({
        message: `Please select or invite a ${mediaDataRoomOrganizationRolePresentationMap[role]}.`,
      })
      .superRefine((selectedParticipantId, ctx) => {
        if (!selectedParticipantId) {
          return;
        }
        const participant = availableParticipants.find(
          (participant) => participant.id === selectedParticipantId
        );
        if (!participant) {
          return;
        }
        const isPublisher = "publisherId" in participant;
        const participantsEmails =
          (isPublisher
            ? participant.publisherParticipants
            : participant.participants) ?? [];
        if (!participantsEmails.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Participant must have at least one email address.",
          });
          return;
        }
        const allAvailableEmails = [
          ...allParticipantsEmails,
          ...participantsEmails,
        ];
        const areEmailsUnique =
          allAvailableEmails.length === new Set(allAvailableEmails).size;
        if (!areEmailsUnique) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Email addresses are not unique.",
          });
        }
      }),
  });

export const emptyMediaDataRoomCreationParticipantSelectionSchema =
  getMediaDataRoomCreationParticipantSelectionSchema(
    [],
    [],
    MediaDataRoomOrganizationRole.PUBLISHER
  );

export type MediaDataRoomCreationParticipantSelectionFormValues = z.infer<
  typeof emptyMediaDataRoomCreationParticipantSelectionSchema
>;
