import { DqCard, DqCardContent } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, type MouseEventHandler, useCallback, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DataRoomType, dataRoomTypeCardPresentation } from "models";
import {
  CreateDataRoomStepActionsContainer,
  CreateDataRoomStepContainer,
  CreateDataRoomStepContentContainer,
  CreateDataRoomStepHeader,
} from "../CreateDataRoomStep";
import {
  type DataRoomCreationDataRoomTypeStepFormValues,
  DataRoomCreationDataRoomTypeStepSchema,
  type DataRoomSelectTypeItem,
} from "./models";

export interface DataRoomCreationDataRoomTypeStepProps {
  canCreateAnalyticsDataRoom: boolean;
  canCreateMeasurementDataRoom: boolean;
  defaultDataRoomType?: DataRoomType;
  canCreateMediaDataRoom: boolean;
  onSubmit?: (dataRoomType: DataRoomType) => void;
  onCancel?: () => void;
}

const DataRoomCreationDataRoomTypeStep =
  memo<DataRoomCreationDataRoomTypeStepProps>(
    ({
      canCreateAnalyticsDataRoom,
      canCreateMeasurementDataRoom,
      canCreateMediaDataRoom,
      onSubmit,
      onCancel,
      defaultDataRoomType,
    }) => {
      const dataRoomTypeForm =
        useForm<DataRoomCreationDataRoomTypeStepFormValues>({
          defaultValues: {
            dataRoomType: defaultDataRoomType,
          },
          mode: "onSubmit",
          resolver: zodResolver(DataRoomCreationDataRoomTypeStepSchema),
        });
      const { reset: resetForm, handleSubmit: handleFormSubmit } =
        dataRoomTypeForm;

      const { palette } = useTheme();
      const { enqueueSnackbar } = useSnackbar();

      const dataRoomTypes = useMemo<DataRoomSelectTypeItem[]>(
        () =>
          [
            {
              dataRoomType: DataRoomType.MediaInsights,
              testId:
                testIds.dataRoomCreation.dataRoomTypeSelect.helper +
                DataRoomType.MediaInsights,
              visible: canCreateMediaDataRoom,
              ...dataRoomTypeCardPresentation[DataRoomType.MediaInsights],
            },
            {
              dataRoomType: DataRoomType.DataScience,
              testId:
                testIds.dataRoomCreation.dataRoomTypeSelect.helper +
                DataRoomType.DataScience,
              visible: canCreateAnalyticsDataRoom,
              ...dataRoomTypeCardPresentation[DataRoomType.DataScience],
            },
            {
              dataRoomType: DataRoomType.Measurement,
              testId:
                testIds.dataRoomCreation.dataRoomTypeSelect.helper +
                DataRoomType.Measurement,
              visible: canCreateMeasurementDataRoom,
              ...dataRoomTypeCardPresentation[DataRoomType.Measurement],
            },
          ].filter(({ visible }) => visible),
        [
          canCreateAnalyticsDataRoom,
          canCreateMeasurementDataRoom,
          canCreateMediaDataRoom,
        ]
      );

      const handleCancel = useCallback(() => {
        resetForm();
        onCancel?.();
      }, [onCancel, resetForm]);

      const handleSubmit = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
          void handleFormSubmit(
            ({ dataRoomType }) => {
              onSubmit?.(dataRoomType);
            },
            (errors) => {
              if (errors.dataRoomType) {
                enqueueSnackbar(errors.dataRoomType.message);
              }
            }
          )();
        },
        [onSubmit, enqueueSnackbar, handleFormSubmit]
      );

      return (
        <FormProvider {...dataRoomTypeForm}>
          <CreateDataRoomStepContainer>
            <CreateDataRoomStepHeader dataRoomType={null} />
            <CreateDataRoomStepContentContainer>
              <Typography level="h4">
                Which type of data clean room do you want to create?
              </Typography>
              <Controller<DataRoomCreationDataRoomTypeStepFormValues>
                name="dataRoomType"
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value ?? null}>
                    <Grid columnSpacing={2} container={true} rowSpacing={2}>
                      {dataRoomTypes.map(
                        ({
                          icon,
                          title,
                          description,
                          dataRoomType,
                          testId,
                        }) => (
                          <Grid key={dataRoomType} sm={4} xs={12}>
                            <DqCard
                              data-testid={testId}
                              selectable={{
                                component: "radio",
                                value: dataRoomType,
                              }}
                            >
                              <DqCardContent
                                alignItems="flex-start"
                                gap={2}
                                justifyContent="flex-start"
                              >
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  gap={2}
                                >
                                  <FontAwesomeIcon
                                    color={palette.primary[400]}
                                    icon={icon}
                                    size="2x"
                                  />
                                  <Typography
                                    level="title-lg"
                                    textAlign="center"
                                  >
                                    {title}
                                  </Typography>
                                </Stack>
                                <Typography level="body-sm">
                                  {description}
                                </Typography>
                              </DqCardContent>
                            </DqCard>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </RadioGroup>
                )}
              />
            </CreateDataRoomStepContentContainer>
            <CreateDataRoomStepActionsContainer>
              <Button onClick={handleCancel}>Back</Button>
              <Button color="primary" onClick={handleSubmit} variant="solid">
                Continue
              </Button>
            </CreateDataRoomStepActionsContainer>
          </CreateDataRoomStepContainer>
        </FormProvider>
      );
    }
  );

DataRoomCreationDataRoomTypeStep.displayName =
  "DataRoomCreationDataRoomTypeStep";

export default DataRoomCreationDataRoomTypeStep;
