import { type PublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/types";
import { memo } from "react";
import { MediaDataRoomMainbar } from "features/mediaDataRoom/components";
import {
  MediaDataRoomContextConsumer,
  MediaDataRoomHandlersWrapper,
  MediaDataRoomInsightsDataWrapper,
  MediaDataRoomWrapper,
} from "features/mediaDataRoom/contexts";
import {
  AudiencesDataPrefetchingWrapper,
  AudiencesWrapper,
} from "features/mediaDataRoom/wrappers";
import MediaDataRoomActiveTab from "./MediaDataRoomActiveTab";
import MediaDataRoomErrorReport from "./MediaDataRoomErrorReport";

interface MediaDataRoomProps {
  data: PublishedMediaDataRoomQuery["publishedMediaDataRoom"];
}

const MediaDataRoom = memo<MediaDataRoomProps>(({ data }) => {
  return (
    <MediaDataRoomErrorReport
      createdAt={data.createdAt}
      dataRoomId={data.id}
      driverAttestationHash={data.driverAttestationHash}
    >
      <MediaDataRoomWrapper data={data}>
        <MediaDataRoomInsightsDataWrapper>
          <MediaDataRoomHandlersWrapper
            dataRoomId={data.id}
            driverAttestationHash={data.driverAttestationHash}
          >
            <MediaDataRoomMainbar id={data.id} />
            <MediaDataRoomContextConsumer>
              {(mediaDataRoomContextValue) => {
                if (
                  !mediaDataRoomContextValue?.supportedFeatures
                    ?.canViewAudiences
                ) {
                  <MediaDataRoomActiveTab />;
                }
                return (
                  <AudiencesWrapper>
                    <AudiencesDataPrefetchingWrapper>
                      <MediaDataRoomActiveTab />
                    </AudiencesDataPrefetchingWrapper>
                  </AudiencesWrapper>
                );
              }}
            </MediaDataRoomContextConsumer>
          </MediaDataRoomHandlersWrapper>
        </MediaDataRoomInsightsDataWrapper>
      </MediaDataRoomWrapper>
    </MediaDataRoomErrorReport>
  );
});

MediaDataRoom.displayName = "MediaDataRoom";
export default MediaDataRoom;
