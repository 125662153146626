// TODO: Fix joy migration
import { exceptions } from "@decentriq/utils";
import { faCheck, faClock, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import {
  DataNodeConstructorMode,
  DataNodeUploadDataDialog,
} from "features/dataNodes";
import { DataNodeConstructorParamsWrapper } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorParamsWrapper";
import DataNodeDeprovisionButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDeprovisionButton";
import { TableNodeColumnConstructor } from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructor";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { getAdvertiserAudienceColumns } from "features/mediaDataRoom/models";
import { useReportError } from "hooks";
import { type DataRoomTableColumn, DataRoomType } from "models";
import AdvertiserDataReportLabel from "../AdvertiserDataReportLabel/AdvertiserDataReportLabel";
import DataNodeItem from "../DataNodeItem";
import AdvertiserValidationReportDialog from "./AdvertiserValidationReportDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import DeprovisionConfirmationDialog from "./DeprovisionConfirmationDialog";
import useAdvertiserDataNodeActions from "./useAdvertiserDataNodeActions";

interface AdvertiserDataNodeActionsProps {
  datasetHash: string | null;
  columns: DataRoomTableColumn[];
  openValidationReport: (open: boolean) => void;
}

const AdvertiserDataNodeActions: React.FC<AdvertiserDataNodeActionsProps> = ({
  datasetHash,
  openValidationReport,
  columns,
}) => {
  const reportError = useReportError();
  const {
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    supportedFeatures: { canProvideSeedAudience },
  } = useMediaDataRoom();
  const {
    handleIngestData,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleDataDelete,
  } = useAdvertiserDataNodeActions({
    datasetHash: datasetHash!,
    driverAttestationHash,
  });
  const columnsOrder = useMemo(() => columns.map(({ id }) => id), [columns]);
  const uniqueColumnIds = useMemo(() => [], []);
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeprovisionDialog, setOpenDeprovisionDialog] = useState(false);
  const toggleOpenConnectDialog = useCallback(
    () => setOpenConnectDialog((state) => !state),
    [setOpenConnectDialog]
  );
  const onDeprovision = useCallback(
    () => handleDataDeprovision("audience_leaf"),
    [handleDataDeprovision]
  );
  const onDelete = useCallback(async () => {
    await handleDataDelete("audience_leaf");
    setOpenDeleteDialog(false);
  }, [handleDataDelete]);
  const isProvisioned = Boolean(datasetHash);

  const canProvision = useMemo(() => {
    return !isDeactivated && !isProvisioned && canProvideSeedAudience;
  }, [canProvideSeedAudience, isDeactivated, isProvisioned]);
  const canDeprovision = useMemo(() => {
    return (isProvisioned || !isDeactivated) && canProvideSeedAudience;
  }, [isDeactivated, isProvisioned, canProvideSeedAudience]);
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      void reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: DataRoomType.LookalikeMedia,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: "audience_leaf",
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          "audience_leaf",
          payload.datasetKeychainItem!
        );
      }
    },
    [handleConnectFromKeychain, handleIngestData]
  );
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{ marginRight: "8px" }}
    >
      {canProvision ? (
        <Button
          color="primary"
          onClick={toggleOpenConnectDialog}
          startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faUpload} />}
          style={{ marginLeft: "16px", maxWidth: "260px", minWidth: "260px" }}
          variant="solid"
        >
          Provision dataset
        </Button>
      ) : canDeprovision ? (
        <DataNodeDeprovisionButton
          dataType="table"
          datasetHash={datasetHash!}
          hasValidationError={true}
          id="audiences"
          ingestionDestination="dataRoom"
          label="dataset"
          onDelete={() => setOpenDeleteDialog(true)}
          onDeprovision={async () => setOpenDeprovisionDialog(true)}
          openValidationReport={() => openValidationReport(true)}
        />
      ) : (
        <Typography
          fontWeight="bold"
          level="body-sm"
          sx={{ color: "var(--joy-palette-neutral-500)" }}
        >
          {isProvisioned ? (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCheck}
                style={{ marginRight: "4px" }}
              />
              Data provisioned
            </>
          ) : (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faClock}
                style={{ marginRight: "4px" }}
              />
              Waiting for data
            </>
          )}
        </Typography>
      )}
      {openConnectDialog ? (
        <DataNodeUploadDataDialog
          columns={columns}
          columnsOrder={columnsOrder}
          id={"audience_leaf"}
          name={"Advertiser audience"}
          onClose={toggleOpenConnectDialog}
          onError={handleError}
          onIngest={onIngest}
          open={openConnectDialog}
          uniqueColumnIds={uniqueColumnIds}
        />
      ) : null}
      <DeprovisionConfirmationDialog
        onCancel={() => setOpenDeprovisionDialog(false)}
        onDeprovision={onDeprovision}
        open={openDeprovisionDialog}
      />
      <DeleteConfirmationDialog
        onCancel={() => setOpenDeleteDialog(false)}
        onDelete={onDelete}
        open={openDeleteDialog}
      />
    </Box>
  );
};

const AdvertiserDataNode: React.FC = () => {
  const {
    matchingIdFormat,
    hashMatchingIdWith,
    supportedFeatures: { canViewSeedAudienceDetails },
  } = useMediaDataRoom();
  const {
    publishedDatasetsHashes: { advertiserDatasetHash: datasetHash },
    advertiserDataReport,
  } = useMediaDataRoomInsightsData();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isValidationReportOpen, setIsValidationReportOpen] = useState(false);
  const toggleCollapse = useCallback(
    () => setIsCollapsed((state) => !state),
    [setIsCollapsed]
  );
  const columns = useMemo<DataRoomTableColumn[]>(
    () => getAdvertiserAudienceColumns(matchingIdFormat, hashMatchingIdWith),
    [matchingIdFormat, hashMatchingIdWith]
  );
  return (
    <>
      <DataNodeItem
        actions={
          <AdvertiserDataNodeActions
            columns={columns}
            datasetHash={datasetHash}
            openValidationReport={setIsValidationReportOpen}
          />
        }
        content={
          canViewSeedAudienceDetails && (
            <DataNodeConstructorParamsWrapper
              mode={DataNodeConstructorMode.ACTION}
            >
              <Box sx={{ padding: (theme) => theme.spacing(1, 0) }}>
                <TableNodeColumnConstructor
                  columns={columns}
                  columnsOrder={columns.map(({ id }) => id)}
                  isLoading={false}
                  tableNodeId={"audience_leaf"}
                />
              </Box>
            </DataNodeConstructorParamsWrapper>
          )
        }
        description={
          datasetHash && canViewSeedAudienceDetails ? (
            <AdvertiserDataReportLabel />
          ) : null
        }
        isCollapsed={isCollapsed || !canViewSeedAudienceDetails}
        title="Advertiser audience"
        toggleCollapse={canViewSeedAudienceDetails ? toggleCollapse : undefined}
      />
      <AdvertiserValidationReportDialog
        data={advertiserDataReport.computeResults}
        loading={advertiserDataReport.loading}
        onClose={() => setIsValidationReportOpen(false)}
        open={isValidationReportOpen}
      />
    </>
  );
};

AdvertiserDataNode.displayName = "AdvertiserDataNode";

export default AdvertiserDataNode;
