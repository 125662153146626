import { DqIcon } from "@decentriq/components";
import { type AvailableDataPartnerFragment } from "@decentriq/graphql/dist/types";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { Fragment, memo } from "react";

type DataPartnerCardProps = AvailableDataPartnerFragment;

const DataPartnerCard = memo<DataPartnerCardProps>((dataPartner) => {
  const { dataPartnerLogo, dataPartnerName } = dataPartner;
  return (
    <Fragment>
      <Typography
        fontWeight={500}
        level="body-md"
        sx={{
          mb: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }}
        textAlign="center"
      >
        {dataPartnerName}
      </Typography>
      {dataPartnerLogo ? (
        <DqIcon icon={dataPartnerLogo} iconProps={{ width: "100%" }} />
      ) : (
        <FontAwesomeIcon icon={faNewspaper} size="3x" />
      )}
    </Fragment>
  );
});

DataPartnerCard.displayName = "DataPartnerCard";
export default DataPartnerCard;
