import { testIds } from "@decentriq/utils";
import { Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Datalabs } from "features/dataLabs";
import { DataPartnerPortalWrapper } from "features/dataPartnerPortal/contexts";
import { useOrganizationPreferences } from "hooks";
import DataPartnerConfigurations from "../DataPartnerConfigurations/DataPartnerConfigurations";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    "--Tabs-spacing": 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

export enum DataPartnerPortalTabs {
  CONFIGURATIONS = "configurations",
  DATALABS = "datalabs",
}

interface DataPartnerPortalProps {
  activeTab: DataPartnerPortalTabs;
  baseUrl: string;
}

const DataPartnerPortal: React.FC<DataPartnerPortalProps> = ({
  activeTab,
  baseUrl,
}) => {
  const { hasPublisherFeatures } = useOrganizationPreferences();
  return (
    <Fragment>
      <Typography level="h4" minHeight={68} p={2}>
        Data partner portal
      </Typography>
      <Tabs sx={tabsSx} value={activeTab}>
        <TabList>
          {!hasPublisherFeatures ? (
            <Tab
              component={Link}
              data-testid={testIds.dataPartnerPortal.tabList.dataLabs}
              to={`${baseUrl}/${DataPartnerPortalTabs.DATALABS}`}
              value={DataPartnerPortalTabs.DATALABS}
            >
              Datalabs
            </Tab>
          ) : null}
          <Tab
            component={Link}
            data-testid={testIds.dataPartnerPortal.tabList.configurations}
            to={`${baseUrl}/${DataPartnerPortalTabs.CONFIGURATIONS}`}
            value={DataPartnerPortalTabs.CONFIGURATIONS}
          >
            Configurations
          </Tab>
        </TabList>
        <TabPanel sx={tabPanelSx} value={DataPartnerPortalTabs.CONFIGURATIONS}>
          <DataPartnerPortalWrapper>
            <DataPartnerConfigurations />
          </DataPartnerPortalWrapper>
        </TabPanel>
        <TabPanel sx={tabPanelSx} value={DataPartnerPortalTabs.DATALABS}>
          <Datalabs />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
DataPartnerPortal.displayName = "DataPartnerPortal";

export default DataPartnerPortal;
