import { memo } from "react";
import { Link } from "react-router-dom";

interface DataRoomCreateProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DataRoomCreate = memo<DataRoomCreateProps>(({ renderTrigger }) => {
  // TODO: Remove empty callback when we get rid of LegacyDataRoomCreate.
  // For now, it's needed to avoid breaking changes and keep compatibility with the old component.
  // Inline styling is needed to reset the default anchor styles.
  return (
    <Link style={{ textDecoration: "none" }} to="/datarooms/create">
      {renderTrigger(() => {})}
    </Link>
  );
});
DataRoomCreate.displayName = "DataRoomCreate";

export default DataRoomCreate;
