import {
  faEllipsisV,
  faPenToSquare,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback, useMemo } from "react";
import { type MediaDataRoomParticipant } from "features/dataRoomCreation/models";
import { MediaDataRoomCreationParticipantEmailsDialog } from "../../";

interface ParticipantCardActionsProps {
  onDelete: () => void;
  onEdit: (updatedParticipant: MediaDataRoomParticipant) => void;
  participant: MediaDataRoomParticipant;
  otherParticipantsEmails: string[];
}

const ParticipantCardActions = memo<ParticipantCardActionsProps>(
  ({ onDelete, onEdit, participant, otherParticipantsEmails }) => {
    const [
      isEditDialogOpen,
      { setTrue: openEditDialog, setFalse: closeEditDialog },
    ] = useBoolean(false);

    const cardActions = useMemo(
      () => [
        {
          icon: faPenToSquare,
          name: "Edit participant",
          onClick: () => {
            openEditDialog();
          },
        },
        {
          icon: faTrash,
          name: "Delete participant",
          onClick: () => {
            onDelete();
          },
        },
      ],
      [onDelete, openEditDialog]
    );

    const handleParticipantEdit = useCallback(
      (updatedEmails: string[]) => {
        onEdit?.({
          ...participant,
          emails: updatedEmails,
        });
        closeEditDialog();
      },
      [closeEditDialog, onEdit, participant]
    );

    return (
      <Fragment>
        <Dropdown>
          <MenuButton
            endDecorator={null}
            slotProps={{ root: { variant: "plain" } }}
            slots={{ root: IconButton }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </MenuButton>
          <Menu>
            {cardActions.map(({ icon, name, onClick }) => (
              <MenuItem key={name} onClick={onClick}>
                <ListItemDecorator sx={{ "--ListItemDecorator-size": "24px" }}>
                  <FontAwesomeIcon fixedWidth={true} icon={icon} size="sm" />
                </ListItemDecorator>
                {name}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
        <MediaDataRoomCreationParticipantEmailsDialog
          canDeleteDefaultEmails={
            !participant.dataPartnerConfig && !participant.publisherConfig
          }
          defaultEmails={participant.emails}
          onCancel={closeEditDialog}
          onSubmit={handleParticipantEdit}
          open={isEditDialogOpen}
          otherParticipantsEmails={otherParticipantsEmails}
          participantName={participant.name}
          participantRole={participant.role}
        />
      </Fragment>
    );
  }
);

ParticipantCardActions.displayName = "ParticipantCardActions";

export default ParticipantCardActions;
