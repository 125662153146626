import { memo } from "react";
import { DataLabsList, DataLabsWrapper } from "features/dataLabs";

const Datalabs = memo(() => {
  return (
    <DataLabsWrapper>
      <DataLabsList />
    </DataLabsWrapper>
  );
});
Datalabs.displayName = "Datalabs";

export default Datalabs;
